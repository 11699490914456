/*
THIS IS AN EXAMPLE CONFIGURABLE BLOCK View.
It is intended to provide a starting point for creating configurable blocks.
The custom block will need to be registered in the theme's index.js configuration.
*/

import { Container } from '@plone/components';
import config from '@plone/volto/registry';
import { default as LinkView } from '../Link/View.jsx';

const View = (props) => {
  const { data } = props;
  const variations = config.blocks?.blocksConfig['cardSection']?.variations || [];
  let currentVariation = variations.filter((item) => item.id === props.data.variation);
  if (!currentVariation.length) {
    currentVariation = variations.filter((item) => item.isDefault);
  }
  const CurrentTemplate = currentVariation[0].template;

  return (
    <div className={`block card-section-block theme-${data.theme} mobile-margin-${data.marginOnMobile}`}>
      <Container>
        {data.image?.length > 0 && (
          <div className="image">
            <div className={data.showLineOnMobile ? 'image-border' : 'image-border mobile-hidden'}></div>
            <img
              src={`${data.image[0]['@id']}/@@images/image`}
              alt={data.imageAlt}
              style={{objectFit: data.objectFit}}
            />
          </div>
        )}
        <div className="content">
          <div className="info">
            <h3>{data.title}</h3>
            <div>{data.description}</div>
          </div>

          <h5 className="subtitle">{data.subtitle}</h5>
          {(data.link && data.link_title) && (
            <LinkView data={{
              href: data.link,
              title: data.link_title
            }} isEditMode={false} />
          )}
          <CurrentTemplate props={props} />
        </div>
      </Container>
    </div>
  );
};

export default View;