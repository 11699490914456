import React from 'react';
import moment from 'moment';
import { isToday } from '../../utils/isToday.js';
import InformationStatus from '../ui/InformationStatus.jsx';

interface Forecast {
  time: string;
}

interface FeatureProperties {
  forecast: Forecast[];
}

interface Feature {
  properties: FeatureProperties;
}

interface MarkerData {
  data: {
    features: Feature[];
  };
}

interface WeatherForecastInfoProps {
  markerdata?: MarkerData;
  language: string;
  today: string;
  status: number;
}

const WeatherForecastInfo: React.FC<WeatherForecastInfoProps> = React.memo(
  ({ today, language, status }) => {
    if (today && !isToday(today)) {
      const time = moment(today, 'YYYY-MM-DD').format('ddd DD-MM-YYYY');

      return (
        <InformationStatus time={time} language={language} outputtext="" />
      );
    } else if (!today && status != 404) {
      //  || typeof markerdata === 'undefined'
      return <InformationStatus language={language} outputtext="" />;
    } else if (!today && status == 404) {
      //  || typeof markerdata === 'undefined'
      const returnText =
        language == 'de'
          ? 'Der ausgewählte Punkt existiert nicht'
          : 'The selected point does not exist';
      return <p aria-live="polite">{returnText}</p>;
      // return <InformationStatus language={language} outputtext={returnText} />;
    }

    return null;
  },
);

export default WeatherForecastInfo;
