import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import svgRight from '@plone/volto/icons/right-key.svg';
import { Icon } from '@plone/volto/components';
import { FormattedDate } from '@plone/volto/components';
import cx from 'classnames';
const shave = require('shave').default;

import { isInternalURL } from '@plone/volto/helpers/Url/Url';

// EventItem Component
const EventItem = ({ item, isEditMode }) => {
  const dateOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  const timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
  };
  const isInternal = item.event_internal;
  const descriptionRef = useRef(null);

  const truncateContent = useCallback(() => {
    if (descriptionRef && descriptionRef.current) {
      requestAnimationFrame(() => {
        // avoid error due to content while switching to and from edit mode
        if (descriptionRef && descriptionRef.current) {
          shave(descriptionRef.current, 80, { character: " (...)" });
        }
      });
    }
  }, []);

  useEffect(() => {
    truncateContent();
    window.addEventListener('resize', truncateContent);

    return () => {
      window.removeEventListener('resize', truncateContent);
    };
  }, [truncateContent]);

  return (
    <div className="events-listing">
      <ConditionalLink item={item} condition={!isEditMode}>
        <div className={cx('event-item', 'default', { 'internalEvent': isInternal })}>
          <div className="content">
            <h2>{item.title ? item.title : item.id}</h2>
            <p className="truncate" ref={descriptionRef}>{item.description}</p>
            <div className="event-listing-info">
              <span className="date">
                <FormattedDate date={item.start} format={dateOptions} />
              </span>
              <span className="time">
                <FormattedDate date={item.start} format={timeOptions} />
              </span>
              <span className="location">{item.location}</span>
              {isInternal && (
                <span className="eventOrganizer">{item.event_organizers.join(', ')}</span>
              )}
            </div>
          </div>
          <div className="arrow-link">
            <Icon name={svgRight} size="16px" />
          </div>
        </div>
      </ConditionalLink>
    </div>
  );
};

EventItem.propTypes = {
  item: PropTypes.object.isRequired
};

const EventListingTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }

  return (
    <>
      <div className="items">
        {items.map((item, index) => (
          <EventItem
            key={item['@id']}
            item={item}
            isEditMode={isEditMode}
          />
        ))}
      </div>

      {link && <div className="footer">{link}</div>}
    </>
  );
};

EventListingTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkTitle: PropTypes.string,
  linkHref: PropTypes.arrayOf(PropTypes.object),
  isEditMode: PropTypes.bool,
};

export default EventListingTemplate;
