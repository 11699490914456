import globeSVG from '@plone/volto/icons/globe.svg';

import GeoSphereMapEdit from './components/Blocks/GeoSphereMap/GeoSphereMapEdit';
import GeoSphereMapView from './components/Blocks/GeoSphereMap/GeoSphereMapView';
import { getGeospheredataplatformdata } from './reducers';
import './theme/imc.less';

const applyConfig = (config) => {
  config.addonReducers.geospheredataplatformdata = getGeospheredataplatformdata;

  config.blocks.blocksConfig.geospheremap = {
    id: 'geospheremap',
    title: 'GeoSphere Map',
    edit: GeoSphereMapEdit,
    view: GeoSphereMapView,
    icon: globeSVG,
    group: 'text',
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
    variations: [
      {
        id: 'weatherforecast',
        isDefault: true,
        title: 'Wettervorhersage',
        template: GeoSphereMapView,
      },
      {
        id: 'warning',
        title: 'Warnungen',
        template: GeoSphereMapView,
      },
      {
        id: 'beben',
        title: 'beben',
        template: GeoSphereMapView,
      },
      {
        id: 'tawes',
        title: 'Tawes',
        template: GeoSphereMapView,
      },
    ],
  };

  return config;
};

export default applyConfig;
