// SemanticUI-free pre-@plone/components
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import { compose } from 'redux';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import { Icon } from '@plone/volto/components';
import zoomSVG from '@plone/volto/icons/zoom.svg';
import { doesNodeContainClick } from 'semantic-ui-react/dist/commonjs/lib';
import { isUrl, toPublicURL } from '@plone/volto/helpers';

const messages = defineMessages({
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  searchSite: {
    id: 'Search Site',
    defaultMessage: 'Search Site',
  },
});

/**
 * SearchWidget component class.
 * @class SearchWidget
 * @extends Component
 */
class SearchWidget extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.onChangeText = this.onChangeText.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      text: '',
      active: false,
      transitioning: false,
    };
    this.locale = props.intl.locale;
  }

  handleTransitionEnd = () => {
    this.setState({ transitioning: false });
  };

  toggleSearchBar = () => {
    this.setState((prevState) => ({
      active: !prevState.active,
      transitioning: true,
    }));
  };

  /**
   * On change text
   * @method onChangeText
   * @param {object} event Event object.
   * @param {string} value Text value.
   * @returns {undefined}
   */
  onChangeText(event) {
    this.setState({
      text: event.target.value,
    });
  }

  /**
   * Submit handler
   * @method onSubmit
   * @param {event} event Event object.
   * @returns {undefined}
   */
  onSubmit(event) {
    const path =
      this.props.pathname?.length > 0
        ? `&path=${encodeURIComponent(this.props.pathname)}`
        : '';
    const searchPath =
      isUrl(toPublicURL(`/de/suche`))
	? `/de/suche?q=${encodeURIComponent(this.state.text)}&l=list&order=desc&sort=bestmatch`
	: `/search?SearchableText=${encodeURIComponent(this.state.text)}${path}`;
    if (isUrl(toPublicURL(`/de/suche`)) && (this.props.location.pathname).startsWith(`/de/suche`)) {
	//refresh search results page
        this.props.history.replace(`/de`);
	setTimeout(() => {this.props.history.replace(searchPath);}, 10);
    } else {
	this.props.history.push(searchPath);
    } 
    event.preventDefault();
    this.setState({ active: false });
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.active && this.state.active) {
      this.refInput.select();
    }
  }

  handleClickOutside = (e) => {
    if (
      this.searchbar.current &&
      doesNodeContainClick(this.searchbar.current, e)
    )
      return;
    this.setState({ active: false });
  };

  handleEscapeKeyDown = (e) => {
    if (e.key === 'Escape') {
      this.setState({ active: false });
    }
  };

  searchbar = React.createRef();

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { active, transitioning } = this.state;

    // const locale = this.intl.locale;

    return (
      <>
        <div
          ref={this.searchbar}
          className={cx('a container search-bar layout', {
            active,
            transitioning,
          })}
          onTransitionEnd={this.handleTransitionEnd}
        >
          <form action="/search" onSubmit={this.onSubmit}>
            <div className="searchbox">
              <input
                aria-label={this.props.intl.formatMessage(messages.search)}
                onChange={(e) => this.onChangeText(e)}
                onKeyDown={(e) => this.handleEscapeKeyDown(e)}
                name="SearchableText"
                value={this.state.text}
                autoComplete="off"
                placeholder={this.props.intl.formatMessage(messages.searchSite)}
                title={this.props.intl.formatMessage(messages.search)}
                tabIndex={this.state.active ? '0' : '-1'} // Conditional tabIndex
                ref={(input) => {
                  this.refInput = input;
                }}
              />
              <button
                aria-label={this.props.intl.formatMessage(messages.search)}
                tabIndex={this.state.active ? '0' : '-1'}
              >
                <span>{this.props.intl.formatMessage(messages.search)}</span>
                <Icon name={zoomSVG} size="36px" />
              </button>
              {this.locale === 'de' && (
                <div
                  className="search-button"
                  role="button"
                  aria-label={this.props.intl.formatMessage(messages.search)}
                  onClick={this.toggleSearchBar}
                  // onKeyPress is required for accessibility - need to figure out what to use instead, since it seems deprecated
                  // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/cca288b73a39fa0932a57c02a7a88de68fc971fc/docs/rules/no-static-element-interactions.md
                  onKeyPress={this.toggleSearchBar}
                  tabIndex={this.state.active ? '-1' : '0'}
                >
                  <span>{this.props.intl.formatMessage(messages.search)}</span>
                  <Icon name={zoomSVG} size="36px" />
                </div>
              )}
            </div>
          </form>
        </div>
      </>
    );
  }
}

//export default compose(withRouter, injectIntl)(SearchWidget);
export default SearchWidget;
