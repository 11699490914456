import React from 'react';
import Ortssuche from './Ortssuche';
import Dropdown from '../ui/DropDown';

interface WeatherForecastDropdownProps {
  data: {
    variation: string;
  };
  chartmode?: boolean;
  selectedDropdownElement: string;
  handleDropdown: (element: number) => void;
  language: string;
}

const WeatherForecastDropdown: React.FC<WeatherForecastDropdownProps> =
  React.memo(
    ({
      data,
      chartmode,
      // selectedDropdownElement,
      // handleDropdown,
      language,
    }) => {
      if (data.variation === 'weatherforecast' && chartmode) {
        return (
          <div className="navbarDropdown">
            <Ortssuche data={data} />
            <Dropdown
              // selectedDropdownElement={selectedDropdownElement}
              // onSelect={handleDropdown}
              language={language}
            />
          </div>
        );
      }

      return null;
    },
  );

export default WeatherForecastDropdown;
