// SemanticUI-free pre-@plone/components
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import { compose } from 'redux';
import { PropTypes } from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import { Icon } from '@plone/volto/components';
import zoomSVG from '@plone/volto/icons/zoom.svg';
import { doesNodeContainClick } from 'semantic-ui-react/dist/commonjs/lib';

import SearchWidget from "../../../../../components/SearchWidget/SearchWidget";

//export default SearchWidget;
export default compose(withRouter, injectIntl)(SearchWidget);
