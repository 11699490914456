import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { find, map } from 'lodash';
import { useState, useEffect, useRef } from 'react';
import { Icon } from '@plone/volto/components';
import svgChevron from '../../../../../icons/Arrow_Down_Lime_16px.svg';

import {
  Helmet,
  langmap,
  flattenToAppURL,
  toReactIntlLang,
} from '@plone/volto/helpers';

import config from '@plone/volto/registry';

import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  switchLanguageTo: {
    id: 'Switch to',
    defaultMessage: 'Switch to',
  },
});

const LanguageSelector = (props) => {
  const intl = useIntl();
  const currentLang = useSelector((state) => state.intl.locale);
  const translations = useSelector(
    (state) => state.content.data?.['@components']?.translations?.items,
  );
  const currentTranslation = find(translations, { language: currentLang });
  const { settings } = config;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    // Component mounts
    document.addEventListener('mousedown', handleClickOutside, false);
    // Component will unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  }, []);

  return settings.isMultilingual ? (
    <div className="language-selector" ref={dropdownRef}>
      <div className="active-language">
        <div
          aria-label={`${intl.formatMessage(messages.switchLanguageTo)} ${langmap[
            currentLang
          ].nativeName.toLowerCase()}`}
          title={langmap[currentLang].nativeName}
          onClick={() => {
            toggleDropdown();
          }}
        >
          {langmap[currentLang].nativeName.slice(0, 2)}
          <Icon name={svgChevron} size="10px" />
        </div>
        <div className={`dropdown ${dropdownOpen ? 'open' : ''}`}>
          {map(settings.supportedLanguages, (lang) => {
            if (lang !== currentLang) {
              const translation = find(translations, { language: lang });
              return (
                <Link
                  aria-label={`${intl.formatMessage(
                    messages.switchLanguageTo,
                  )} ${langmap[lang].nativeName.toLowerCase()}`}
                  className={cx({
                    selected: toReactIntlLang(lang) === currentLang,
                  })}
                  to={
                    translation
                      ? flattenToAppURL(translation['@id'])
                      : `/${lang}`
                  }
                  title={langmap[lang].nativeName}
                  onClick={() => {
                    props.onClickAction();
                  }}
                  key={`language-selector-${lang}`}
                >
                  {langmap[lang].nativeName}
                </Link>
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  ) : (
    <Helmet>
      <html lang={toReactIntlLang(settings.defaultLanguage)} />
    </Helmet>
  );
};

LanguageSelector.propTypes = {
  onClickAction: PropTypes.func,
};

LanguageSelector.defaultProps = {
  onClickAction: () => {},
};

export default LanguageSelector;
