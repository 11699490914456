import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Ortssuche.module.css';
import { Icon } from '@plone/volto/components';
import searchIcon from '../../../icons/austria/Lupe_Midnight_32px.svg';
import { setHash } from '../../utils/hashingHelpers';

const Ortssuche = ({ data }) => {
  // console.log('Ortssuche');
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const language = useSelector(
    (state) => state.geospheredataplatformdata['SET_LANGUAGE'],
  );

  const [state, setState] = useState({
    searchText: '',
    filterPoints: [],
    activeSuggestion: 0,
    open: false,
  });

  const forecastPoints = useSelector((state) =>
    state.geospheredataplatformdata
      ? state.geospheredataplatformdata[data.forecastPoints]
      : {},
  );

  const handleChangeInput = useCallback((event) => {
    setState((prevState) => ({
      ...prevState,
      searchText: event.target.value,
    }));
  }, []);
  const filteredPoints = useMemo(() => {
    // if (!(forecastPoints && forecastPoints.data)) return [];
    if (state.searchText.length > 2) {
      return forecastPoints.data
        .filter((i) =>
          i.name.toLowerCase().includes(state.searchText.toLowerCase()),
        )
        .sort((a, b) => {
          if (a.is_capital && !b.is_capital) {
            return -1;
          } else if (!a.is_capital && b.is_capital) {
            return 1;
          } else {
            return 0;
          }
        });
    }
    return [];
  }, [state.searchText, forecastPoints]);

  useEffect(() => {
    if (state.searchText.length > 2) {
      setState((prevState) => ({
        ...prevState,
        filterPoints: filteredPoints,
        open: filteredPoints.length > 0,
      }));
    }
  }, [filteredPoints]);

  const callbackfunction = useCallback(
    (poi) => {
      setState((prevState) => ({
        ...prevState,
        open: false,
      }));
      dispatch({ type: 'SET_CLICK_X_AXIS', payload: false });
      // window.location.hash = `tab=chart${poi.point_id}`;
      setHash('tab', 'chart');
      setHash('point_id', poi.point_id);
    },
    [dispatch],
  );

  const handleDivClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setState((prevState) => {
      // Only update if the new state is different
      if (prevState.open !== true) {
        return { ...prevState, open: true };
      }
      return prevState;
    });
  }, []);

  const handleKeyDown = useCallback(
    (result, e) => {
      if (e.key === 'Enter') {
        if (state.filterPoints) {
          callbackfunction(result);
        }
      } else if (e.key === 'Escape') {
        setState((prevState) => ({
          ...prevState,
          open: false,
        }));
      }
    },
    [state.filterPoints, callbackfunction],
  );

  const dropdownRef = useRef(null);

  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setState((prevState) => ({
        ...prevState,
        open: false,
      }));
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className={styles.parentSearchContainer}>
      <div
        className={styles.searchContainer}
        onClick={handleDivClick}
        role="Button"
      >
        <div className={styles.searchContainer_bar_gps}>
          <div className={styles.searchInput}>
            <Icon name={searchIcon} size="20px" title="Lupe" />
            <input
              ref={inputRef}
              type="text"
              value={state.searchText}
              onChange={handleChangeInput}
              onFocus={(e) => e.target.select()}
              placeholder={
                language === 'de' ? 'Ort eingeben' : 'Search Location'
              }
              aria-label="Ortssuche"
              aria-autocomplete="list"
              aria-controls="Bezirk vorschlag"
              aria-expanded={state.filterPoints.length > 0}
            />
          </div>
        </div>
        {state.filterPoints.length > 0 && state.open && (
          <div className={styles.searchResults} ref={dropdownRef}>
            <ul role="listbox">
              {state.filterPoints.map((result, index) => (
                <li
                  role="option"
                  tabIndex={0}
                  key={result.point_id}
                  onMouseDown={() => callbackfunction(result)}
                  aria-selected={state.activeSuggestion === index}
                  onKeyDown={(e) => handleKeyDown(result, e)}
                >
                  <p>{result.name}</p>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Ortssuche;
