const WeatherForecastHelperSvgDay = {};

// Dynamically import all weatherforecastImages from the specified folder
const imageContext = require.context(
  '../icons/weatherforecast/Tag',
  false,
  /\.(png|jpe?g|svg)$/,
);
imageContext.keys().forEach((imagePath, index) => {
  const imageName = imagePath.split('/').pop().split('.')[0];
  const importedImage = imageContext(imagePath);
  WeatherForecastHelperSvgDay[imageName] = importedImage;
});

// console.log('All images:', WeatherForecastHelperSvgDay); // Debugging statement

export default WeatherForecastHelperSvgDay;
