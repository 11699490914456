import React from 'react';

const Skeleton = ({
  // width = '100%',
  height = '700px',
  borderRadius = '4px',
  className = '',
}) => {
  const skeletonStyle = {
    height,
    borderRadius,
    background: '#f0f0f0',
    animation: 'skeleton-loading 1.5s infinite',
  };

  return (
    <>
      <style>{`
        @keyframes skeleton-loading {
          0% { background-color: #f0f0f0; }
          50% { background-color: #e0e0e0; }
          100% { background-color: #f0f0f0; }
        }
      `}</style>
      <div style={skeletonStyle} className={`skeleton-loader ${className}`} />
    </>
  );
};

export default Skeleton;
