import React, { useState, useEffect, FC, useRef, useCallback } from 'react';
import styles from './Dropdown.module.css'; // Import CSS module for custom styles
// import exclamation from '@plone/volto/icons/exclamation.svg';
import dropdown from '../../../icons/austria/Arrow_Down_Midnight_16px.svg';
import { Icon } from '@plone/volto/components';
import {
  forecastTextMapping,
  forecastTextMappingEnglisch,
} from '../../../constants/WeatherforecastHelper';
import { setHash, getHash } from '../../utils/hashingHelpers';
interface DropdownProps {
  // onSelect: (el: number) => void;
  // selectedDropdownElement: () => string;
  language: string;
  // Add any props if needed in the future
}

const Dropdown: FC<DropdownProps> = ({
  // selectedDropdownElement,
  // onSelect,
  language,
}) => {
  const [state, setState] = useState({
    isOpen: false,
    highlightedIndex: -1,
  });
  const cut = getHash('cut') || 0;
  useEffect(() => {
    if (cut) {
      const numbCut = parseInt(cut);
      if (numbCut && numbCut >= 0 && numbCut <= 9) {
        setState((prevState) => ({
          ...prevState,
          highlightedIndex: numbCut,
        }));
        // onSelect(numbCut);
      }
    }
  }, [cut]);
  const bundeslander =
    language === 'de'
      ? Object.keys(forecastTextMapping)
      : Object.keys(forecastTextMappingEnglisch);

  const handleItemClick = useCallback((option: number): void => {
    // onSelect(option);
    setState((prevState) => ({ ...prevState, isOpen: false }));
    setHash('cut', option);
  }, []);

  const toggleDropdown = useCallback((): void => {
    setState((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
      highlightedIndex: -1,
    }));
  }, []);

  const toggleKeydownDropdown = useCallback(
    (event: KeyboardEvent): void => {
      if (event.key === 'Enter' || event.key === 'Backspace') {
        event.preventDefault();
        handleItemClick(state.highlightedIndex);
      }
      if (event.key === 'ArrowDown' || event.key === 'Tab') {
        event.preventDefault();
        if (!state.isOpen) {
          toggleDropdown();
          return;
        }
        setState((prevState) => ({
          ...prevState,
          highlightedIndex:
            (prevState.highlightedIndex + 1) % bundeslander.length,
        }));
      } else if (event.key === 'ArrowUp') {
        setState((prevState) => ({
          ...prevState,
          isOpen: !prevState.isOpen,
          highlightedIndex: -1,
        }));
      } else if (event.key === 'Escape') {
        setState((prevState) => ({ ...prevState, isOpen: false }));
        dropdownRefParent.current?.blur();
      }
    },
    [
      state.isOpen,
      state.highlightedIndex,
      bundeslander.length,
      handleItemClick,
      toggleDropdown,
    ],
  );

  const toggleKeydownDropdownEl = (option: number) => {
    handleItemClick(option);
  };

  const dropdownRefParent = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  // Handle clicks outside the dropdown
  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setState((prevState) => ({ ...prevState, isOpen: false }));
    }
  }, []);

  useEffect(() => {
    if (state.isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [state.isOpen, handleClickOutside]);

  return (
    <div className={styles.customDropdown}>
      <div className={styles.mainTitle}>
        {/* onMouseLeave={leaveDropdown} */}
        <div
          className={styles.dropdownHeader}
          onClick={toggleDropdown}
          role="button"
          aria-haspopup="true"
          tabIndex="0"
          ref={dropdownRefParent}
          onKeyDown={toggleKeydownDropdown}
        >
          {bundeslander[parseInt(cut)] || 'Wählen Sie ein Bundesland'}
          <div className={styles.marginRight}>
            <Icon name={dropdown} size="8px" />
          </div>
        </div>
        {state.isOpen && (
          <div
            className={styles.dropdownList}
            role="listbox"
            ref={dropdownRef}
            aria-labelledby="Bundesländer"
          >
            {bundeslander.map((option, index) => (
              <div
                role="option"
                aria-selected={index === state.highlightedIndex}
                tabIndex="0"
                key={option}
                className={styles.dropdownItem}
                onClick={() => handleItemClick(index)}
                onKeyDown={() => toggleKeydownDropdownEl(index)}
                // onMouseEnter={() => setSelectedOption(option)}
                style={{
                  backgroundColor:
                    state.highlightedIndex === index ? 'white' : '',
                }}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
