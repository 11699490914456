/**
 * NewsItemView view component.
 * @module components/theme/View/NewsItemView
 */

import React from 'react';
import PropTypes from 'prop-types';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import config from '@plone/volto/registry';
import { Container as SemanticContainer } from 'semantic-ui-react';
import { Button } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';
import { FormattedDate } from '@plone/volto/components';
import { newsItemViewMessages } from '../../../../../i18nl10n';

/**
 * NewsItemView view component class.
 * @function NewsItemView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const NewsItemView = ({ content }) => {
  const intl = useIntl();
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;
  const dateOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  return (
    <Container id="page-document" className="view-wrapper newsitem-view">
      <div className="top-overview-button">
        <div className="news-info">
          {content?.effective && (
            <FormattedDate date={content.effective} format={dateOptions} />
          )}
          {content?.effective && (
            <span>{content.news_category.map((n) => n.title).join(', ')}</span>
          )}
        </div>
        <div className="block __button">
          <div className="button container news-overview-link">
            <div className="align right">
              <UniversalLink
                href={content?.parent['@id']}
                openLinkInNewTab={false}
              >
                <Button className="ui button">
                  {intl.formatMessage(newsItemViewMessages.newsOverview)}
                </Button>
              </UniversalLink>
            </div>
          </div>
        </div>
      </div>
      <RenderBlocks content={content} />
      <div className="block __button">
        <div className="button container news-overview-link">
          <div className="align right">
            <UniversalLink
              href={content?.parent['@id']}
              openLinkInNewTab={false}
            >
              <Button className="ui button">
                {intl.formatMessage(newsItemViewMessages.newsOverview)}
              </Button>
            </UniversalLink>
          </div>
        </div>
      </div>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
NewsItemView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default NewsItemView;
