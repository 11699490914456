import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGeospheredataplatformdata } from '../../../actions';

import styles from './WeatherforcastChart.module.css';
import { Icon } from '@plone/volto/components';
import WeatherforecastChartWidget from './WeatherforecastChartWidget';
import WeatherforecastChartWarnWidget from './WeatherforecastChartWarnWidget';
import Ortssuche from './Ortssuche';
import WeatherforcastChartHC from './WeatherforcastChartHighcharts';
import Button from '../ui/Button';
// import autIcon from '../../icons/austria/austria_s.svg';
import autIcon from '../../../icons/austria/aut.svg';
import WeatherforecastChartTable from './WeatherforecastChartTable';
import { resetObject } from '../../../actions';
import moment from 'moment';
import Skeleton from '../Shared/Skeleton';
moment.locale('de');

const WeatherforcastChart = memo(({ data }) => {
  const dispatch = useDispatch();
  const forecastdata = useSelector((state) =>
    state.geospheredataplatformdata
      ? state.geospheredataplatformdata[data.highcharts_grid]
      : {},
  );
  const forecastPoints = useSelector((state) =>
    state.geospheredataplatformdata
      ? state.geospheredataplatformdata[data.forecastPoints]
      : {},
  );

  const point_id = useSelector((state) =>
    state.geospheredataplatformdata
      ? state.geospheredataplatformdata[data.variation + 'CHART'].point_id
      : null,
  );

  const language = useSelector(
    (state) => state.geospheredataplatformdata['SET_LANGUAGE'],
  );
  const daily = useSelector((state) =>
    state.geospheredataplatformdata
      ? state.geospheredataplatformdata[data.servicename]
      : {},
  );
  React.useEffect(() => {
    if (point_id) {
      dispatch(
        getGeospheredataplatformdata(
          data.serviceaddress,
          data.highcharts_grid,
          {
            id: point_id,
          },
          ['id'],
        ),
      );
    }
  }, [point_id]);
  React.useEffect(() => {
    if (forecastdata || !forecastdata?.data) return;
    // forecastdata.data.newtimestamps = forecastdata.data.newtimestamps
    //   ? forecastdata.data.newtimestamps
    //   : forecastdata.data.timestamps.map(
    //       (item, id) => new Date(item).getTime(), // Convert timestamp to milliseconds
    //     );
  }, [forecastdata]);

  const handleResetFromChart = (tab) => {
    // dispatch({ type: 'SET_CLICK_X_AXIS', payload: false });
    // dispatch(resetObject(data.highcharts_grid)); // warum brauch ich das alles?
    window.location.hash = `tab=${tab}`;
    if (daily?.data?.features?.length == 1) {
      dispatch(resetObject(data.servicename));
      dispatch(
        getGeospheredataplatformdata(data.serviceaddress, data.servicename),
      );
    }
    //
  };
  return (
    <div className={styles.pareantChart}>
      <div className={styles.nominatimPosition}>
        <Ortssuche data={data} />
        <Button
          onClick={() => handleResetFromChart('map')}
          aria-label="Zurück zur Kartendarstellung"
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Icon
              name={autIcon}
              size="14px"
              className={styles.svg_icon}
              title="Zur Karten Ansicht"
            />
            {language == 'de' ? 'Zurück' : 'Go back'}
          </div>
        </Button>
      </div>
      {forecastdata?.data && daily ? (
        <div className={styles.widgetsOrder}>
          <div
            className={`${styles.weatherWidget} ${styles.weatherWidgetFlex5}`}
          >
            <WeatherforecastChartWidget
              forecastdata={forecastdata}
              dailyMetainformation={forecastPoints?.data?.find(
                (i) => i.point_id == point_id,
              )}
              language={language}
              // minmaxTemps={minmaxTemps.current}
            />
          </div>

          {daily?.data &&
            daily.data.features.find((i) => i.properties.point_id == point_id)
              ?.properties.forecast[0].warning > 0 && (
              <div
                className={`${styles.weatherWidget} ${styles.weatherWidgetFlex3}`}
              >
                <WeatherforecastChartWarnWidget
                  warning={
                    daily.data.features.find(
                      (i) => i.properties.point_id == point_id,
                    )?.properties.forecast[0].warning
                  }
                  title={forecastdata.data.features[0].properties.name}
                  language={language}
                />
              </div>
            )}
        </div>
      ) : (
        <>
          {forecastdata?.data == false ? (
            <p>Der gewählte Punkt existiert nicht!! </p>
          ) : (
            <Skeleton />
          )}
        </>
      )}
      {forecastdata?.data != false ? (
        <div className={styles.highchartsParent}>
          {forecastdata?.data && daily && (
            <WeatherforecastChartTable
              data={data}
              point_id={point_id}
              checkedDaily={daily.data.features.find(
                (i) =>
                  i.properties.point_id == point_id ||
                  i.properties.name ==
                    forecastdata.data.features[0].properties.name,
              )}
              language={language}
            />
          )}
          <p>{language == 'de' ? 'Detailprognose' : 'Detailed forecast'}</p>
          <div className={styles.highchartsChild1}>
            <div id="container" className={styles.highchartsChild}>
              {forecastdata?.data && daily?.data ? (
                <WeatherforcastChartHC
                  forecastdata={forecastdata}
                  language={language}
                />
              ) : (
                <Skeleton />
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
});

export default WeatherforcastChart;
