import React, { useEffect, useState } from 'react';
import style from './InformationStatus.module.css';
import useHashChange from '../../../hooks/useHashChange';

const InformationStatus = ({ time = null, language, outputtext }) => {
  const [showText, setShowText] = useState(false);
  // const [mapClick, setmapClick] = useHashChange(memoizedData, location);
  // console.log(mapClick);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText(true);
    }, 5000);

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  let text = ' ';
  if (language == 'de') {
    // weise text, wenn es time gibt, den jeweiligen text zu
    text = time
      ? `Achtung! Aufgrund von Problemen werden aktuell die Vorhersagedaten von ${time} dargestellt`
      : `Achtung! Aufgrund von Problemen werden derzeit keine ${outputtext ? outputtext : 'Daten'} dargestellt`;
  } else {
    text = time
      ? `Attention! Due to problems, the forecast data from ${time} is currently being displayed`
      : `Attention! Due to problems, no ${outputtext ? 'warnings' : 'data'} is currently being displayed`;
  }
  return (
    <>
      {!showText && <p>Loading..</p>}
      {showText && (
        <p aria-live="polite" className={style.alert}>
          {text}
        </p>
      )}
    </>
  );
};

export default InformationStatus;
