import { Icon } from '@plone/volto/components';
import style from './WeatherforecastMarkers.module.css';
import warningSvg from '../../../icons/austria/Weather_Warning_White_20px.svg';
import { warnstufeToText } from '../../utils/warnstufeToText';
const wlevelColor = (wlevel) => {
  const backgroundColor =
    wlevel === 1
      ? style.yellow
      : wlevel === 2
        ? style.orange
        : wlevel === 3
          ? style.red
          : style.bar; // Fallback to the default 'bar' class
  return backgroundColor;
};
const WeatherforecastMarkersDiv = ({ forecast, day }) => {
  return (
    <>
      <div
        className={style.label_weatherforecast_5days_headerTitleWithWarningFlex}
      >
        <div className={style.label_weatherforecast_5days_headerTempHeader}>
          {' '}
          <div
            className={style.label_weatherforecast_5days_headerTempHeaderTemp}
          >
            {' '}
            {typeof forecast[day]?.low === 'number'
              ? Math.round(forecast[day]?.low) + '°'
              : null}{' '}
          </div>{' '}
          <div
            className={style.label_weatherforecast_5days_headerTempHeaderTemp}
          >
            {' '}
            {typeof forecast[day]?.high === 'number'
              ? Math.round(forecast[day]?.high) + '°'
              : null}{' '}
          </div>{' '}
        </div>
        {forecast[day].warning ? (
          <div
            className={`
              ${wlevelColor(forecast[day].warning)}
              ${style.label_weatherforecast_5days_headerTempHeader}
              ${style.label_weatherforecast_5days_headerTempHeaderWarning}
            `}
          >
            <Icon
              name={warningSvg}
              size="20px"
              // className="svg_icon"
              title={`Achtung: Warnstufe ${warnstufeToText(forecast[day].warning)}`}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default WeatherforecastMarkersDiv;
