import { SidebarPortal } from '@plone/volto/components';

import View from './GeoSphereMapView';
import SimpleMapSidebar from './SimpleMapSidebar';

const Edit = ({ data, onChangeBlock, block, selected }) => {
  return (
    <>
      <View data={data} />
      <SidebarPortal selected={selected}>
        <SimpleMapSidebar
          data={data}
          block={block}
          onChangeBlock={onChangeBlock}
        />
      </SidebarPortal>
    </>
  );
};

export default Edit;
