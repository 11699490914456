const weatherforecastImages = {};

// Dynamically import all weatherforecastImages from the specified folder
// const imageContext = require.context(
//   '../icons/weatherforecast/',
//   false,
//   /\.(png|jpe?g|svg)$/,
// );
// imageContext.keys().forEach((imagePath, index) => {
//   const imageName = imagePath.split('/').pop().split('.')[0];
//   const importedImage = imageContext(imagePath);
//   weatherforecastImages[imageName] = importedImage;
// });

// console.log('All images:', weatherforecastImages); // Debugging statement

export default weatherforecastImages;

export const forecastTextMapping = {
  Österreich: 8009000,
  Wien: 8009600,
  Burgenland: 8009700,
  Niederösterreich: 8009500,
  Oberösterreich: 8009400,
  Steiermark: 8009800,
  Salzburg: 8009300,
  Kärnten: 8009900,
  Tirol: 8009200,
  Vorarlberg: 8009100,
};
export const forecastTextMappingEnglisch = {
  Austria: 8009000,
  Vienna: 8009600,
  Burgenland: 8009700,
  'Lower-Austria': 8009500,
  'Upper-Austria': 8009400,
  Styria: 8009800,
  Salzburg: 8009300,
  Carinthia: 8009900,
  Tyrol: 8009200,
  Vorarlberg: 8009100,
};

export const mappingSymbToText = {
  1: 'Wolkenlos',
  2: 'Heiter',
  3: 'Wolkig',
  4: 'Stark bewölkt',
  5: 'Bedeckt',
  6: 'Bodennebel',
  7: 'Hochnebel',
  8: 'Leichter Regen',
  9: 'Mäßiger Regen',
  10: 'Starker Regen',
  11: 'Schneeregen',
  12: 'Schneeregen',
  13: 'Schneeregen',
  14: 'Leichter Schneefall',
  15: 'Mäßiger Schneefall',
  16: 'Starker Schneefall',
  17: 'Regenschauer',
  18: 'Regenschauer',
  19: 'Starker Regenschauer',
  20: 'Schneeregenschauer',
  21: 'Schneeregenschauer',
  22: 'Schneeregenschauer',
  23: 'Schneeschauer',
  24: 'Schneeschauer',
  25: 'Starker Schneeschauer',
  26: 'Gewitter',
  27: 'Gewitter',
  28: 'Starkes Gewitter',
  29: 'Gewitter mit Schneeregen',
  30: 'Starkes Gewitter mit Schneeregen',
  31: 'Gewitter mit Schneefall',
  32: 'Starkes Gewitter mit Schneefall',
};

export const mappingSymbToTextEnglisch = {
  1: 'Clear',
  2: 'Mostly clear',
  3: 'Partly cloudy',
  4: 'Mostly cloudy',
  5: 'Cloudy',
  6: 'Groundfog',
  7: 'High fog',
  8: 'Light Rain',
  9: 'Moderate Rain',
  10: 'Heavy Rain',
  11: 'Sleet',
  12: 'Sleet',
  13: 'Sleet',
  14: 'Light Snowfall',
  15: 'Moderate Snowfall',
  16: 'Heavy Snowfall',
  17: 'Rain Shower',
  18: 'Rain Shower',
  19: 'Heavy Rain Shower',
  20: 'Sleet Shower',
  21: 'Sleet Shower',
  22: 'Sleet Shower',
  23: 'Snow Shower',
  24: 'Snow Shower',
  25: 'Heavy Snow Shower',
  26: 'Thunderstorm',
  27: 'Thunderstorm',
  28: 'Heavy Thunderstorm',
  29: 'Thunderstorm with Sleet',
  30: 'Heavy Thunderstorm with Sleet',
  31: 'Thunderstorm with Snowfall',
  32: 'Heavy Thunderstorm with Snowfall',
};
