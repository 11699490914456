import React, { FC } from 'react';
import AccordionArrow from './AccordionArrow';

interface AccordionDivTextProps {
  adjustedIndex: () => number;
  isOpen: () => boolean;
  toggleItem: () => void;
  index: number;
  formatDate: () => string;
  item: any;
}
const AccordionDivText = ({
  adjustedIndex,
  isOpen,
  toggleItem,
  index,
  formatDate,
  item,
}: FC<AccordionDivTextProps>) => {
  return (
    <div
      key={adjustedIndex}
      className={`accordion-weatherforecast-item ${isOpen ? 'open' : ''}`}
    >
      <div
        className="accordion-weatherforecast-header"
        onClick={() => toggleItem(adjustedIndex)}
        role="button"
        aria-expanded={isOpen}
        aria-controls={`accordion-panel-${index}`}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            toggleItem(adjustedIndex);
          }
        }}
      >
        {formatDate(item.validity_range, 1)}
        <AccordionArrow isOpen={isOpen} key={'k' + index} />
      </div>
      {
        <div
          className={`accordion-weatherforecast-content ${
            isOpen ? 'active' : ''
          } `}
        >
          {item.text}
        </div>
      }
    </div>
  );
};
export default AccordionDivText;
