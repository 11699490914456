import React from 'react';
import ButtonNoHtmlatt from '../ui/ButtonNoHtmlatt';

// Define types for navItems and props
interface NavItem {
  name: string;
  shortName: string;
  shortShort: string;
  api: string;
}

interface NavMenuProps {
  navItems: NavItem[];
  clickItem: number;
  debouncedHandleClick: (api: string, index: number) => void;
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  styles: { [key: string]: string }; // Type for CSS module classes
}

const NavMenu: React.FC<NavMenuProps> = ({
  navItems,
  clickItem,
  debouncedHandleClick,
  styles,
}) => {
  return (
    <>
      <ul className={styles.tawesnavbarList}>
        {navItems.map((item, index) => (
          <li className={styles.tawesnavbarItem} key={index}>
            <ButtonNoHtmlatt
              active={clickItem === index}
              label={item.name}
              onClick={() => debouncedHandleClick(item.api, index)}
            />
          </li>
        ))}
      </ul>
      {/* and for smaller screen: */}
      <ul
        className={`${styles.tawesnavbarList} ${styles.tawesnavbarListSmall}`}
      >
        {navItems.map((item, index) => (
          <li className={styles.tawesnavbarItem} key={index}>
            <ButtonNoHtmlatt
              active={clickItem === index}
              label={item.shortName}
              onClick={() => debouncedHandleClick(item.api, index)}
            />
          </li>
        ))}
      </ul>
      <ul
        className={`${styles.tawesnavbarList} ${styles.tawesnavbarListSuperSmall}`}
      >
        {navItems.map((item, index) => (
          <li className={styles.tawesnavbarItem} key={index}>
            <ButtonNoHtmlatt
              active={clickItem === index}
              label={item.shortShort}
              onClick={() => debouncedHandleClick(item.api, index)}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

export default NavMenu;
