export const listingPaginationSchemaEnhancer = ({ schema, formData, intl }) => {
    const { fieldsets, properties} = schema;

    return {
      ...schema,
      fieldsets: [
        ...fieldsets,
        {
          id: 'pagination',
          title: 'Pagination',
          fields: ['hide_pagination'],
        },
      ],
      required: [],
      properties: {
        ...properties,
        hide_pagination: {
            title: 'Hide Pagination',
            type: 'boolean'
        },
      },
    };
};
