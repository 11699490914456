import React, { createRef, useRef, useMemo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import { Pagination, Dimmer, Loader } from 'semantic-ui-react';
import Slugger from 'github-slugger';
import { renderLinkElement } from '@plone/volto-slate/editor/render';
import config from '@plone/volto/registry';
import withQuerystringResults from '@plone/volto/components/manage/Blocks/Listing/withQuerystringResults';
import svgRight from '@plone/volto/icons/right-key.svg';
import svgLeft from '@plone/volto/icons/left-key.svg';
import { Icon } from '@plone/volto/components';
import { default as LinkView } from '../Link/View.jsx';

const Headline = ({
  headlineTag,
  id,
  data = {},
  listingItems,
  isEditMode,
  style,
}) => {
  let attr = { id };
  const slug = Slugger.slug(data.headline);
  attr.id = slug || id;
  const LinkedHeadline = useMemo(
    () => renderLinkElement(headlineTag),
    [headlineTag],
  );
  return (
    <LinkedHeadline
      mode={!isEditMode && 'view'}
      children={data.headline}
      attributes={attr}
      className={cx('headline', {
        emptyListing: !listingItems?.length > 0,
      })}
      style={style}
    />
  );
};

const ListingBody = withQuerystringResults((props) => {
  const {
    data = {},
    isEditMode,
    listingItems,
    totalPages,
    onPaginationChange,
    variation,
    currentPage,
    batch_size,
    prevBatch,
    nextBatch,
    isFolderContentsListing,
    hasLoaded,
    id,
    total,
    properties,
  } = props;

  // XXX: content type search results (filter?)
  let contentType = <FormattedMessage id="results" defaultMessage="Results" />;

  let ListingBodyTemplate;
  // Legacy support if template is present
  const variations = config.blocks?.blocksConfig['listing']?.variations || [];
  const defaultVariation = variations.filter((item) => item.isDefault)?.[0];

  if (data.template && !data.variation) {
    const legacyTemplateConfig = variations.find(
      (item) => item.id === data.template,
    );
    ListingBodyTemplate = legacyTemplateConfig.template;
  } else {
    ListingBodyTemplate =
      variation?.template ?? defaultVariation?.template ?? null;
  }
  let galleryRef;

  if (data.variation === 'imageGallery') {
    galleryRef = useRef();
  }
  const listingRef = createRef();
  const NoResults = variation?.noResultsComponent
    ? variation.noResultsComponent
    : config.blocks?.blocksConfig['listing'].noResultsComponent;

  const HeadlineTag = data.headlineTag || 'h2';
  return (
    <>
      <div className="pagination-wrapper-top">
        {(totalPages > 1 && !data.hide_pagination) && (
          <>
            <Pagination
              className="desktop-pagination"
              activePage={currentPage}
              totalPages={totalPages}
              onPageChange={(e, { activePage }) => {
                onPaginationChange(e, { activePage });
              }}
              firstItem={null}
              lastItem={null}
              boundaryRange="1"
              prevItem={{
                content: (
                  <Icon name={svgLeft} size="16px" />
                ),
                icon: false,
                'aria-disabled': !prevBatch,
                className: !prevBatch ? 'disabled' : null,
              }}
              nextItem={{
                content: (
                  <Icon name={svgRight} size="16px" />
                ),
                icon: false,
                'aria-disabled': !nextBatch,
                className: !nextBatch ? 'disabled' : null,
              }}
            />
            <Pagination
              className="mobile-pagination"
              activePage={currentPage}
              totalPages={totalPages}
              boundaryRange={1}
              siblingRange={0}
              onPageChange={(e, { activePage }) => {
                onPaginationChange(e, { activePage });
              }}
              firstItem={null}
              lastItem={null}
              prevItem={{
                content: (
                  <Icon name={svgLeft} size="16px" />
                ),
                icon: false,
                'aria-disabled': !prevBatch,
                className: !prevBatch ? 'disabled' : null,
              }}
              nextItem={{
                content: (
                  <Icon name={svgRight} size="16px" />
                ),
                icon: false,
                'aria-disabled': !nextBatch,
                className: !nextBatch ? 'disabled' : null,
              }}
            />
          </>
        )}
        {!data.hide_pagination && (
          <div className="total">
            {total}{' '}
            {contentType}{' '}
            <FormattedMessage id="found" defaultMessage="found" />{' '}
          </div>
        )}

      </div>
      {data.headline && (
        <Headline
          headlineTag={HeadlineTag}
          id={id}
          listingItems={listingItems}
          data={data}
          isEditMode={isEditMode}
        />
      )}
      {listingItems?.length > 0 ? (
        <div ref={listingRef}>
          <ListingBodyTemplate
            items={listingItems}
            columns={data.columns}
            isEditMode={isEditMode}
            ref={galleryRef}
            {...data}
            {...variation}
          />
          {(totalPages > 1 && !data.hide_pagination) && (
            <div className="pagination-wrapper-bottom">
              <>
                <Pagination
                  className="desktop-pagination"
                  activePage={currentPage}
                  totalPages={totalPages}
                  onPageChange={(e, { activePage }) => {
                    onPaginationChange(e, { activePage });
                  }}
                  firstItem={null}
                  lastItem={null}
                  boundaryRange="1"
                  prevItem={{
                    content: (
                      <Icon name={svgLeft} size="16px" />
                    ),
                    icon: false,
                    'aria-disabled': !prevBatch,
                    className: !prevBatch ? 'disabled' : null,
                  }}
                  nextItem={{
                    content: (
                      <Icon name={svgRight} size="16px" />
                    ),
                    icon: false,
                    'aria-disabled': !nextBatch,
                    className: !nextBatch ? 'disabled' : null,
                  }}
                />
                <Pagination
                  className="mobile-pagination"
                  activePage={currentPage}
                  totalPages={totalPages}
                  boundaryRange={1}
                  siblingRange={0}
                  onPageChange={(e, { activePage }) => {
                    onPaginationChange(e, { activePage });
                  }}
                  firstItem={null}
                  lastItem={null}
                  prevItem={{
                    content: (
                      <Icon name={svgLeft} size="16px" />
                    ),
                    icon: false,
                    'aria-disabled': !prevBatch,
                    className: !prevBatch ? 'disabled' : null,
                  }}
                  nextItem={{
                    content: (
                      <Icon name={svgRight} size="16px" />
                    ),
                    icon: false,
                    'aria-disabled': !nextBatch,
                    className: !nextBatch ? 'disabled' : null,
                  }}
                />
              </>

              <div className="total">
                {total}{' '}
                {contentType}{' '}
                <FormattedMessage id="found" defaultMessage="found" />{' '}
              </div>
            </div>
          )}
        </div>
      ) : isEditMode ? (
        <div className="listing message" ref={listingRef}>
          {isFolderContentsListing && (
            <FormattedMessage
              id="No items found in this container."
              defaultMessage="No items found in this container."
            />
          )}
          {hasLoaded && NoResults && (
            <NoResults isEditMode={isEditMode} {...data} />
          )}
          <Dimmer active={!hasLoaded} inverted>
            <Loader indeterminate size="small">
              <FormattedMessage id="loading" defaultMessage="Loading" />
            </Loader>
          </Dimmer>
        </div>
      ) : (
        <div className="emptyListing">
          {hasLoaded && NoResults && (
            <NoResults isEditMode={isEditMode} {...data} />
          )}
          <Dimmer active={!hasLoaded} inverted>
            <Loader indeterminate size="small">
              <FormattedMessage id="loading" defaultMessage="Loading" />
            </Loader>
          </Dimmer>
        </div>
      )}
      {data.link_href && data.link_title && (
        <div className="listing-preview-link">
            <LinkView data={{
              href: data.link_href,
              title: data.link_title
            }} isEditMode={false} />
        </div>
      )}
    </>
  );
});

export default injectIntl(ListingBody);