import moment from 'moment';
import { isNighttime } from '../../utils/isNighttime';
import {
  createSvgElement,
  generateSVGString,
} from '../../utils/createSvgElement';
import isMobileDevice from '../HelperFunctions/isMobile';

function findIndices(bigArray, smallArray) {
  const indices = [];
  for (let i = 0; i < bigArray.length; i++) {
    if (smallArray.includes(bigArray[i])) {
      indices.push(i);
    }
  }
  return indices;
}
function setWindSymbol(angle) {
  if (!angle) angle = 0;
  angle = angle % 360;
  if (angle < 0) {
    angle += 360;
  }
  // Define wind direction categories
  const directions = {
    arrowN: {
      nicename: { de: 'Süden', en: 'South' },
      svgname: 'arrowN',
    },
    arrowNE: {
      nicename: { de: 'Südwest', en: 'Southwest' },
      svgname: 'arrowNE',
    },
    arrowE: {
      nicename: { de: 'Westen', en: 'West' },
      svgname: 'arrowE',
    },
    arrowSE: {
      nicename: { de: 'Nordwesten', en: 'Northwest' },
      svgname: 'arrowSE',
    },
    arrowS: {
      nicename: { de: 'Norden', en: 'North' },
      svgname: 'arrowS',
    },
    arrowSW: {
      nicename: { de: 'Nordosten', en: 'Northeast' },
      svgname: 'arrowSW',
    },
    arrowW: {
      nicename: { de: 'Osten', en: 'East' },
      svgname: 'arrowW',
    },
    arrowNW: {
      nicename: { de: 'Südosten', en: 'Southeast' },
      svgname: 'arrowNW',
    },
  };

  // Determine the wind direction category based on the angle
  const index = Math.round(angle / 45) % 8; // Divide the circle into 8 sectors (45 degrees each)
  return directions[Object.keys(directions)[index]];
}

function calculateMaxAxisValue(maxDataValue) {
  const MAX_AXIS_VALUE_LOW = 15;
  const MAX_AXIS_VALUE_MEDIUM = 25;
  const MAX_AXIS_VALUE_HIGH = 50;

  let maxAxisValue = MAX_AXIS_VALUE_LOW;
  if (maxDataValue >= 5 && maxDataValue < 13) {
    maxAxisValue = MAX_AXIS_VALUE_LOW;
  } else if (maxDataValue >= 13 && maxDataValue < 15) {
    maxAxisValue = MAX_AXIS_VALUE_MEDIUM;
  } else if (maxDataValue >= 15) {
    maxAxisValue = MAX_AXIS_VALUE_HIGH;
  }
  return maxAxisValue;
}

const highchartsOptionForecast = (
  dataloaded,
  yAxisTitleReain,
  yAxisTitleTemp,
  forecastLang,
  sy,
  windData,
  t2m,
  rr,
  midnightTimestamps,
  midnightTimestampIndex,
  plotBands,
  tooltipFormatter,
  maxAxisValue,
  customPositioner,
  setClickInChartTimeFunction,
) => ({
  chart: {
    height: 600,
    // width: 700,
    // spacingRight: 100, // Adjust spacing for the right side
    responsive: false,
    backgroundColor: '#f4f4f4', // Set chart background color
    scrollbar: true,
    pinchType: 'x',
    panning: true,
    // marginmarginLeft: 100,
    // events: {
    //   load: function () {
    //     setTimeout(() => {
    //       // console.log('tiimeout');
    //       if (isMobileDevice()) {
    //         // Assume mobile if width is <= 768px
    //         this.update({
    //           plotOptions: {
    //             series: {
    //               enableMouseTracking: true, // Disable mouse tracking
    //               stickyTracking: false, // Disable sticky tracking
    //               allowPointSelect: true, // Disable point selection
    //             },
    //           },
    //           // tooltip: {
    //           //   enabled: false, // Disable tooltips
    //           // },
    //         });
    //       }
    //     }, 500); // Adjust timeout as needed
    //   },
    // },
    scrollablePlotArea: {
      minWidth: 4000,
      // scrollPositionX: HOUR_OF_DAY / 100,
    },
  },
  title: {
    text: '',
    align: 'left',
    // x: 170,
    style: {
      color: '#333', // Set title text color
    },
  },
  accessibility: {
    enabled: true,
    description:
      'Dieses Diagramm zeigt Wettervorhersagedaten eines Ortes über die Zeit.',
  },
  xAxis: [
    {
      // events: {
      //   setExtremes: function () {
      //     console.log(this.min, this.max);
      //   },
      // },
      offset: 0,
      gridLineWidth: 1,
      crosshair: {
        color: ' #FFFFFF',
        zIndex: 1,
      },
      tickInterval: 1,
      tickWidth: 1,
      tickLength: 5,
      // tickmarkPlacement: 'on',
      // tickPosition: 'inside',
      // minPadding: 0.5,
      categories: dataloaded.newtimestamps,
      // tickWidth: 5,
      labels: {
        // offset: -100,
        rotation: 0,
        y: 15, // y-position of label
        reserveSpace: true,
        useHTML: true,
        // format: '{value:%H:%M}', // Display only hours and minutes
        align: 'right',
        formatter: function () {
          const hoursMinutes = moment(this.value).local().format('HH:mm');

          return `<div style="white-space: nowrap;">${hoursMinutes}</div>`;
        },
        style: {
          width: '200px', // Adjust this width based on your requirement
          textOverflow: 'hidden',
          overflow: 'hidden',
          color: '#333',
          // padding: 10, // Remove any padding around the labels
          // width: 'au',
          // width: '500px', // Adjust the width as needed
          whiteSpace: 'nowrap', // Prevent line breaks
        },

        tickmarkPlacement: 'on', // Place the tick directly below the label
      },
      // max: 5,
      plotLines: midnightTimestamps.map((midnightTimestamp, index) => ({
        color: 'gray',
        width: 2,
        zIndex: 2,
        id: '',
        // value mit offset
        // value:
        //   index < 4
        //     ? dataloaded.newtimestamps.indexOf(midnightTimestamp) - 0.505
        //     : dataloaded.newtimestamps.indexOf(midnightTimestamp) - 1, //+ 0.505, // - 0.505, // Use category index
        value: dataloaded.newtimestamps.indexOf(midnightTimestamp) - 0.505,
        dashStyle: 'solid',

        ...(index === midnightTimestamps.length - 1 && {
          label: {
            text: forecastLang,
            verticalAlign: 'middle',
            textAlign: 'right',
            style: {
              color: '#052E37',
              fontWeight: 'bold',
              fontSize: '19px',
              fontFamily: 'WeissenhofGrotesk',
            },
          },
        }),
        // ...(index === midnightTimestamps.length - 1 && {
        //   label: {
        //     text: 'anaylse',
        //     verticalAlign: 'middle',
        //     textAlign: 'left',
        //     align: 'left',
        //     style: {
        //       color: '#052E37',
        //       fontWeight: 'bold',
        //       fontSize: '19px',
        //       fontFamily: 'WeissenhofGrotesk',
        //     },
        //   },
        // }),
      })),
      plotBands: plotBands,
    },
    {
      // Secondary x-axis
      linkedTo: 0,
      rotation: 0,

      tickPositions: midnightTimestampIndex,
      tickWidth: 0,
      lineWidth: 0,
      labels: {
        rotation: 0,
        // align: 'right', // Align the labels
        y: -5, // Offset the tick labels up
        useHTML: true,
        formatter: function () {
          // Display label only for specific positions

          return ` <div style="font-weight:600;margin: 0px 25%;width: 110px;">  ${moment(
            dataloaded.newtimestamps[this.pos],
          ).format('dddd DD.MMM')} </div> `;
        },
      },
    },
  ],

  yAxis: [
    {
      // offset: 0,
      // top: '50%',
      height: '10%',
      min: 0,
      max: 0,
      gridLineWidth: 0,
      title: {
        text: 'Wettersymbol',
      },
      visible: false,
      lineWidth: 0,
    },
    {
      className: 'y-axis3-title-background',
      // offset: 0,
      zIndex: 10,
      top: '10%',
      height: '15%',
      min: 0,
      max: 0,
      labels: {
        enabled: false,
      },
      gridLineWidth: 0,
      title: {
        text: 'Wind',
        useHTML: true,
        y: 20,
        x: -25,
        style: {
          color: '#fff',
          'background-color': '#052E37',
        },
      },
      visible: true,
      lineWidth: 0,
    },

    {
      className: 'y-axis-title-background',
      height: '70%',
      top: '30%',
      offset: 0,
      // min: 0,
      tickInterval: 5,
      lineWidth: 0,
      showLastLabel: true,
      gridLineWidth: 2,
      title: {
        text: yAxisTitleTemp,
        useHTML: true,
        // align: 'low',

        y: 0,
        rotation: 270,
        style: {
          color: '#fff',
          'background-color': '#A92A4E',
        },
      },
    },
    {
      className: 'y-axis2-title-background',
      title: {
        text: yAxisTitleReain,
        useHTML: true,

        style: {
          color: '#fff',
          'background-color': '#2a47a9',
        },
      },

      opposite: true,
      max: maxAxisValue, // Set the maximum value dynamically

      height: '70%',
      top: '30%',
      showLastLabel: true,
      gridLineWidth: 0,
      // offset: 0,
    },
  ],
  legend: {
    enabled: false,
  },

  tooltip: {
    enabled: isMobileDevice() ? false : true, // Disable the tooltip by default
    shared: false,
    followPointer: true,
    positioner: customPositioner, // Use the custom positioner function
    backgroundColor: '#052E37', // Dark grey background color
    shadow: false,
    // padding: 5,
    // borderColor: '#052E37', // Border color
    // borderWidth: 0, // Border width
    // borderRadius: 0, // Border radius for a rounded look
    style: {
      textOutline: 'none',
      color: '#ffffff', // Text color
      // fontSize: '16px', // Font size
      font: 'normal normal normal 19px/30px Source Sans Pro',
      fontSize: '18px',
      fontFamily: 'WeissenhofGrotesk',
      textAlign: 'center', // Center-align text
      borderRadius: 0, // Border radius for a rounded look
    },
    formatter: tooltipFormatter,
  },
  series: [
    {
      name: 'wetter',
      type: 'scatter',
      data: sy.data.map(function (value, index) {
        if (value === null) return null;

        // dataloaded.newtimestamps[index] dataloaded.newtimestamps[index+1]
        const timestamp1 = moment(dataloaded.newtimestamps[index]);
        const timestamp2 = moment(dataloaded.newtimestamps[index + 1]);
        const differenceInHours = timestamp2.diff(timestamp1, 'hours');
        let yesOrno = isNighttime(dataloaded.newtimestamps[index]);
        function isBetween6And18(timestamp) {
          const hour = timestamp.hour(); // Get the hour component (0-23)
          return hour >= 6 && hour <= 18;
        }
        if (differenceInHours === 6) {
          if (isBetween6And18(timestamp1) && isBetween6And18(timestamp2)) {
            yesOrno = true;
          }
        }

        return {
          x: index,
          y: 0,
          z: value,
          // newAttribute: value,
          marker: {
            // symbol: setWeatherSymbol(value), // Set marker symbol based on y-value
            symbol: createSvgElement(value, yesOrno),
            width: 35,
            height: 35,
            // symbol: `url(images/icons/weatherforecast/1.svg)`,
          },
        }; // Set x to 0 to display all points on the same vertical line
      }),

      yAxis: 0,
    },
    {
      name: 'symbol wind',
      type: 'scatter',
      pointPlacement: -0.5,
      zIndex: 13,

      data: windData.windDirection.map(function (value, index) {
        value = value + 180 > 360 ? value - 180 : value + 180;
        const svgString = generateSVGString(value);
        const svgDataUri = 'data:image/svg+xml;base64,' + btoa(svgString);

        return {
          x: index,
          y: 0,
          z: value,
          newAttribute: windData.windSpeed[index],
          zIndex: 13,
          marker: {
            symbol: `url(${svgDataUri})`,
            width: 20, // Match the SVG's width
            height: 20, // Match the SVG's height
            padding: 5,
            // radius: 50,
            zIndex: 13,
            lineWidth: 1, // Border width, if needed
          },
        }; // Set x to 0 to display all points on the same vertical line
      }),
      yAxis: 1,
    },

    {
      name: 'Temperatur',
      type: 'spline',

      data: t2m.data,
      // color: '#007BFF', // Set line color
      color: '#A92A4E', // Set the color of the chart line to red
      lineWidth: 2, // Set line width
      zIndex: 2,
      yAxis: 2,
      pointPlacement: -0.5, // sets offset to the left  -  so that value is inbetween
      marker: {
        symbol: 'circle',
        enabled: false, // Hide markers by default
        states: {
          hover: {
            enabled: true, // Show markers on hover
            fillColor: '#a92a4e', // color of the circle on hover
            lineWidth: 1, // border width of the circle
            lineColor: '#a92a4e', // border color of the circle
            halo: {
              size: 0,
            },
          },
        },
      },
      point: {
        events: {
          mouseOver: function () {
            // Access x value directly from the point
            const xValue = this.x;
            const xAxis = this.series.chart.xAxis[0];
            xAxis.addPlotLine({
              value: xValue - 0.505,
              color: '#052e37',
              width: 1,
              y: -10,
              zIndex: 2,
              id: 'HoveringPlotLine',
              label: {
                // text: xValue,
                align: 'center',
                verticalAlign: 'top',
                backgroundColor: '#052e37',
                y: -10,
                zIndex: 3,
              },
            });
          },
          mouseOut: function () {
            if (
              this.series.chart &&
              this.series.chart.xAxis &&
              this.series.chart.xAxis[0]
            ) {
              this.series.chart.xAxis[0].removePlotLine('HoveringPlotLine');
            }
          },
        },
      },
    },
    {
      name: 'rainfall',
      type: 'column',
      data: rr.data,
      yAxis: 3,
      pointPlacement: 0,
      color: '#2A47A9',
      pointWidth: 25, // breite der balken
      dataLabels: {
        enabled: true,
        formatter: function () {
          return this.y === null ? 'Missing' : ''; // Show 'Missing' where the value is null
        },
        style: {
          color: '#FF0000', // Red text for missing data
          fontWeight: 'bold',
        },
      },
    },
  ],
  plotOptions: {
    series: {
      stickyTracking: false,
      allowPointSelect: false,

      point: {
        events: {
          // plotline on click
          click: function (event) {
            event.preventDefault();
            this.series.xAxis.removePlotLine('newPlotLine');
            let offset =
              this.series.name == 'wetter' || this.series.name == 'rainfall'
                ? 0
                : 0.505;
            this.series.xAxis.addPlotLine({
              value: this.x - offset,
              color: 'blue',
              width: 1,
              id: 'newPlotLine',
              zIndex: 2,
            });
            // clickXAxis.current = this.x; // xVar globalVariable, this.x is just the value which is clicked in chart --> for the widget
            setClickInChartTimeFunction(this.x);
          },
        },
      },
      scroll: function (event) {
        const chart = this.chart;
        const yAxis = chart.yAxis[0];
        const extremes = yAxis.getExtremes();

        // Calculate offset based on scroll position and chart width
        const scrollOffset =
          event.target.scrolledX / (chart.chartWidth - chart.plotWidth);

        // Adjust minimum and maximum values considering offset
        yAxis.setExtremes(
          extremes.dataMin +
            scrollOffset * (extremes.dataMax - extremes.dataMin),
          extremes.dataMax +
            scrollOffset * (extremes.dataMax - extremes.dataMin),
        );
      },
    },
    // its jused for wind values
    scatter: {
      // Zeig Windgeschwindigkeit an
      dataLabels: {
        enabled: true, // Enable data labels
        y: 41, // Adjust the vertical position of the label
        align: 'center', // Align the label to the center of the marker
        backgroundColor: '#F3F5F5', // Background color for data labels
        // borderColor: '#000000', // Border color for data labels
        borderRadius: 3, // Border radius for data labels
        borderWidth: 1, // Border width for data labels
        padding: 3, // Padding inside the data label box
        formatter: function () {
          if (!this.point.newAttribute) return;
          return (
            '<div style="text-align:center;">' +
            Math.round(this.point.newAttribute) +
            '</div>'
          ); // Return the value you want to display:newAttribute - wind speedl
        },
        style: {
          // margin: '10px',
          fontSize: '19px',
          'font-weight': 'normal',
          color: 'black', // Set the color of the data labels
        },
      },
    },
  },
  credits: {
    enabled: false,
  },
});

export {
  highchartsOptionForecast,
  findIndices,
  calculateMaxAxisValue,
  setWindSymbol,
};
