import React from 'react';
import WeatherForecastInfo from './Forecast/WeatherForecastInfo';
import InfoComponent from './ui/InfoComponent';

interface WeatherForecastInfoBlockProps {
  datavariation: string;
  datainformationText: string;
  datainformationLink: string;
  language: string;
  isToday: string; // null wenn keine daten da sind
  markerdataExists: boolean;
  markerdataError: Errorobject;
  styleHash: string; // CSS-Hash für den Block
}
interface Errorobject {
  status?: number;
}

const WeatherForecastInfoBlock: React.FC<WeatherForecastInfoBlockProps> =
  React.memo(
    ({
      language,
      isToday,
      markerdataExists,
      datavariation,
      datainformationText,
      datainformationLink,
      markerdataError,
      styleHash,
    }) => {
      return (
        <>
          <div
            className="block geosphere_interactive_map"
            style={{
              display: styleHash,
            }}
          >
            {(datavariation === 'weatherforecast' || markerdataExists) && (
              <WeatherForecastInfo
                today={
                  datavariation === 'weatherforecast' ? isToday : undefined
                }
                language={language}
                status={markerdataError?.status ?? 200}
                // status --- > like that in case of undefined set default value
              />
            )}
            {/* EMD error information! */}
            {datavariation !== 'warning' &&
            !markerdataExists &&
            datainformationText ? (
              <InfoComponent
                informationText={datainformationText}
                informationLink={datainformationLink}
                language={language}
              />
            ) : null}
          </div>
        </>
      );
    },
  );

export default WeatherForecastInfoBlock;
