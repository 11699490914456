import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import svgRight from '@plone/volto/icons/right-key.svg';
import { Icon } from '@plone/volto/components';
import { FormattedDate } from '@plone/volto/components';
import React, { useRef, useEffect, useCallback } from 'react';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import cx from 'classnames';
import { useIntl } from 'react-intl';
const shave = require('shave').default;
import { newsItemViewMessages } from '../../../i18nl10n';

const SummaryTemplate = ({ items, linkTitle, linkHref, isEditMode, columns }) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';
  const intl = useIntl();

  const Image = config.getComponent('Image').component;

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }

  const contentRefs = useRef([]);

  const calculateMaxHeights = useCallback(() => {
    contentRefs.current.forEach((ref) => {
      if (ref.current) {
        const contentElement = ref.current.querySelector('.content');
        const descriptionElement = ref.current.querySelector('.truncate');
        const h2Element = ref.current.querySelector('h2');

        if (h2Element && contentElement && descriptionElement) {
          const containerHeight = contentElement.clientHeight;
          const h2Height = h2Element.clientHeight;
          const maxHeight = containerHeight - h2Height - 120; // padding

          requestAnimationFrame(() => {
            shave(descriptionElement, maxHeight, { character: ' (...)' });
          });
        }
      }
    });
  }, [items]);

  const columnsClass = columns === 'two' ? 'two-colums' : 'one-column';

  useEffect(() => {
    calculateMaxHeights();
    window.addEventListener('resize', calculateMaxHeights);

    return () => {
      window.removeEventListener('resize', calculateMaxHeights);
    };
  }, [calculateMaxHeights]);

  if (contentRefs.current.length !== items.length) {
    contentRefs.current = Array(items.length)
      .fill()
      .map((_, i) => contentRefs.current[i] || React.createRef());
  }

  return (
    <>
      <div className={cx("items", columnsClass)}>
        {items.map((item, index) => {
          const dateOptions = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          };

          const translated_categories = ![null, undefined].includes(item.news_category) ? item.news_category.map(cat => {
            cat = cat.split(' ').join('');
            return intl.formatMessage(newsItemViewMessages[cat])
          }).join(', ') : '';

          return (
            <div
              className="teaser-listing-item"
              key={item['@id']}
              ref={contentRefs.current[index]}
            >
              <ConditionalLink item={item} condition={!isEditMode}>
                <div className="teaser-item default">
                  <div className="image-wrapper">
                    <Image
                      item={item}
                      imageField={item.image_field}
                      alt=""
                      loading="lazy"
                      responsive={true}
                      srcSet={`
                        ${flattenToAppURL(item['@id'] + '/' + item.image_scales?.image?.[0]?.scales['preview']?.['download'])} 400w,
                        ${flattenToAppURL(item['@id'] + '/' + item.image_scales?.image?.[0]?.scales['large']?.['download'])} 800w,
                      `}
                      sizes="(max-width: 770px) 750px, 280px"
                    />
                  </div>
                  <div className="content">
                    <h2>{item.title ? item.title : item.id}</h2>
                    <p className="truncate">{item.description}</p>
                    <div className="teaser-listing-info">
                      <span className="date">
                        <FormattedDate
                          date={item['Date']}
                          format={dateOptions}
                        />
                      </span>
                      {translated_categories && (
                        <span>
                          {translated_categories}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="arrow-link">
                    <Icon name={svgRight} size="16px" />
                  </div>
                </div>
              </ConditionalLink>
            </div>
          );
        })}
      </div>

      {link && <div className="footer">{link}</div>}
    </>
  );
};

SummaryTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default SummaryTemplate;
