import React from 'react';
import { Button } from 'semantic-ui-react';
import cx from 'classnames';
import { ConditionalLink } from '@plone/volto/components';
import { defineMessages, useIntl } from 'react-intl';
import { Icon } from '@plone/volto/components';
import internalLink from '@plone/volto/icons/right-key.svg';
import externalLink from '../../../icons/Link_Extern_White_16px.svg';
import downloadLink from '../../../icons/Link_Download_White_16px.svg';

const messages = defineMessages({
  LinkText: {
    id: 'Link text',
    defaultMessage: 'Link text',
  },
});

const View = ({ data, isEditMode, className }) => {
  const [hasLink, setHasLink] = React.useState(false);
  const intl = useIntl();

  React.useEffect(() => {
    if (data.href) {
      if (data.href && data.href.length > 0) {
        setHasLink(true);
      }
      if (data.href.length === 0) {
        setHasLink(false);
      }
    }
  }, [data.href]);

  const icons = {
    'internal': internalLink,
    'external': externalLink,
    'download': downloadLink,
  }

  let linktype;
  if (hasLink && data.href.length > 0) {
    if (!data.href[0].portal_type) {
        linktype ='external';
    } else if (
        data.href[0].portal_type === 'Image' || data.href[0].portal_type === 'File'
    ) {
        linktype = 'download';
    } else {
        linktype = 'internal';
    }
  }
  const href = (linktype === 'download' && data.href[0].portal_type === 'Image') ?
    data.href[0]['@id'] + '/@@download/' + data.href[0].portal_type?.toLowerCase()
    : data.href?.[0]?.['@id'];

  return (
    hasLink && data.href.length > 0 && (
    <div className={cx('block __link', className)}>
      <div className={cx('link container', linktype)}>
        <ConditionalLink
          href={href ?? null}
          condition={!isEditMode && hasLink}
          openLinkInNewTab={data.openLinkInNewTab}
        >
          <Button className={'link'}>
            <span>{data.title || intl.formatMessage(messages.LinkText)}</span>
            <div>
              <Icon name={icons[linktype]} size="16px" />
            </div>
          </Button>
        </ConditionalLink>
      </div>
    </div>
    )
  );
};

export default View;
