import React, { useState } from 'react';
import styles from './WeatherforecastTable.module.css';
import warningSvgY from '../../../icons/austria/Warnung_Gelb.svg';
import warningSvgO from '../../../icons/austria/Warnung_Orange.svg';
import warningSvgR from '../../../icons/austria/Warnung_Rot.svg';
import { Icon } from '@plone/volto/components';
import { warnstufeToText } from '../../utils/warnstufeToText';
const WeatherforecastTableWarnIcon = ({ warning }) => {
  return (
    <>
      {warning == 1 && (
        <div className={styles.styleIconDiv}>
          <Icon
            name={warningSvgY}
            size="32px"
            title={`Achtung: Warnstufe ${warnstufeToText(warning)}`}
          />
        </div>
      )}
      {warning == 2 && (
        <div className={styles.styleIconDiv}>
          <Icon
            name={warningSvgO}
            size="32px"
            title={`Achtung: Warnstufe ${warnstufeToText(warning)}`}
          />
        </div>
      )}
      {warning == 3 && (
        <div className={styles.styleIconDiv}>
          <Icon
            name={warningSvgR}
            size="32px"
            title={`Achtung: Warnstufe ${warnstufeToText(warning)}`}
          />
        </div>
      )}
    </>
  );
};

export default WeatherforecastTableWarnIcon;
