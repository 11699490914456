import React, { memo } from 'react';
import WeatherForecastText from './WeatherForecastText';

interface WeatherForecastTextType {
  forecasttext: string;
  selectedDropdownElement: number; // index of selected dropdown element in the language array, e.g., 0 for Austria, 1 for Europe, 2 for World etc.
}

const WeatherForecastTexts: React.FC<WeatherForecastTextType> = memo(
  ({ forecasttext, selectedDropdownElement }) => {
    return (
      <div className="WeatherForecastTexts">
        <WeatherForecastText
          forecasttext={forecasttext}
          // serviceaddress={serviceaddress}
          selName={selectedDropdownElement}
        />
      </div>
    );
  },
);

export default WeatherForecastTexts;
