import React from 'react';
import { Source, Layer } from 'react-map-gl/maplibre';
interface ShakeMapData {
  map: string | object;
}

const shakemapStyle = {
  id: 'shakemapStyle',
  type: 'line',
  paint: {
    'line-color': ['get', 'color'],
  },
};

interface ShakeMapLayerProps {
  shakeMap: { data: ShakeMapData };
  // shakemapStyle: object;
}

const BebenShakeMapLayer: React.FC<ShakeMapLayerProps> = ({
  shakeMap,
  // shakemapStyle,
}) => {
  if (!shakeMap?.data) {
    return null;
  }
  return (
    <>
      {shakeMap?.data && typeof shakeMap.data.map === 'string' && (
        <Source
          id="shakeMap"
          type="geojson"
          data={JSON.parse(shakeMap.data.map)}
        >
          <Layer {...shakemapStyle} />
        </Source>
      )}
      {shakeMap?.data && typeof shakeMap.data.map === 'object' && (
        <Source id="shakeMap" type="geojson" data={shakeMap.data.map}>
          <Layer {...shakemapStyle} />
        </Source>
      )}
    </>
  );
};
export default BebenShakeMapLayer;
