export const warnstufeToText = (warnstufe) => {
  switch (warnstufe) {
    case 1:
      return 'gelb';
    case 2:
      return 'orange';
    case 3:
      return 'rot';
    default:
      break;
  }
};
export const warnstufeToTextEnglish = (warnstufe) => {
  switch (warnstufe) {
    case 1:
      return 'yellow';
    case 2:
      return 'orange';
    case 3:
      return 'red';
    default:
      break;
  }
};
