/**
 * ProjectView view component.
 * @module components/theme/View/ProjectView
 */

import { Button } from 'semantic-ui-react';
import { Container } from '@plone/components';
import { injectIntl, useIntl } from 'react-intl';
import { hasBlocksData, flattenHTMLToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedDate } from '@plone/volto/components';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { UniversalLink } from '@plone/volto/components';
import { projectViewMessages } from '../../i18nl10n';

const ProjectTextfieldView = ({ content }) => {
  const Image = config.getComponent({ name: 'Image' }).component;
  return (
    <React.Fragment>
      {content.title && (
        <h1 className="documentFirstHeading">{content.title}</h1>
      )}
      {content.description && (
        <p className="documentDescription">{content.description}</p>
      )}
      {content.preview_image && (
        <Image
          className="document-image ui right floated image"
          item={content}
          imageField="preview_image"
          alt=""
        />
      )}
      {content.text && (
        <div
          dangerouslySetInnerHTML={{
            __html: flattenHTMLToAppURL(content.text.data),
          }}
        />
      )}
    </React.Fragment>
  );
};

const ProjectOverviewButton = ({ content }) => {
  const intl = useIntl();

  return (
    <div className="block __button">
      <div className="button container project-overview-link">
        <div className="align right">
          <UniversalLink href={content?.parent['@id']} openLinkInNewTab={false}>
            <Button className="ui button">
              {intl.formatMessage(projectViewMessages.projectOverview)}
            </Button>
          </UniversalLink>
        </div>
      </div>
    </div>
  );
};

const ProjectSummary = ({ content }) => {
    const {
      description,
      end,
      project_funding_agencies,
      project_funding_programs,
      project_lead,
      project_shorttitle,
      project_topics,
      start,
      title,
      project_logos,
    } = content;

    const topics = project_topics.map(project => project.title);
    
    const intl = useIntl();

    const dateOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };

    return (
      <div className="block __project">
        <Container>
          <div className="project-info-table">
            <div className="row-header">{intl.formatMessage(projectViewMessages.projectTableAcronym)}</div>
            <div className="row-value">{project_shorttitle}</div>

            <div className="row-header">{intl.formatMessage(projectViewMessages.projectTableTitle)}</div>
            <div className="row-value">{title}</div>

            <div className="row-header">{intl.formatMessage(projectViewMessages.projectTableDescription)}</div>
            <div className="row-value">{description}</div>

            <div className="row-header">{intl.formatMessage(projectViewMessages.projectTableLead)}</div>
            <div className="row-value">{project_lead}</div>

            <div className="row-header">{intl.formatMessage(projectViewMessages.projectTableStart)}</div>
            <div className="row-value">
              {
                <FormattedDate date={start} format={dateOptions} />
              }
            </div>

            <div className="row-header">{intl.formatMessage(projectViewMessages.projectTableEnd)}</div>
            <div className="row-value">
              {
                <FormattedDate date={end} format={dateOptions} />
              }
            </div>

            <div className="row-header">{intl.formatMessage(projectViewMessages.projectTableTags)}</div>
            <div className="row-value">{topics.join(', ')}</div>

            <div className="row-header">{intl.formatMessage(projectViewMessages.projectTableFundingAgency)}</div>
            <div className="row-value">{project_funding_agencies}</div>

            <div className="row-header">{intl.formatMessage(projectViewMessages.projectTableFundingProgramme)}</div>
            <div className="row-value">{project_funding_programs.join(', ')}</div>
          </div>

          <div className="external-partners">
              {
                project_logos.map((logo) => {
                  const url = logo['@id'] + '/' + logo.image_scales.image[0].scales.mini.download;
                  return (
                    <div className="logo" key={url}>
                      <img src={url}></img>
                    </div>
                  );
                })
              }
          </div>
        </Container>
      </div>
    );
}

/**
 * ProjectView view component class.
 * @function ProjectView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const ProjectView = (props) => {
  const { content } = props;
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;

  return (
    <Container id="page-document" className="view-wrapper project-view">
      <div className="top-overview-button">
        <ProjectOverviewButton {...props} />
      </div>
      {hasBlocksData(content) ? (
        <>
          <RenderBlocks {...props} />
        </>
      ) : (
        <ProjectTextfieldView {...props} />
      )}
      <ProjectSummary {...props} />
      <ProjectOverviewButton {...props} />
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ProjectView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    // end: PropTypes.string.isRequired,
    // start: PropTypes.string.isRequired,
  }).isRequired,
};
export default injectIntl(ProjectView);
