import isMobileDevice from './isMobile';

const highchartsOptionWeatherstation = (
  parameterNumber,
  language,
  apiParameter,
  unit,
  arrayKey,
  formatDates,
  filteredData,
  formatTooltip,
  parameter,
  emitedApiendpointIndex,
  navItemTawes,
  data,
) => ({
  chart: {
    height: 600,

    type: navItemTawes[Number(parameterNumber)].type,
    backgroundColor: '#F3F5F5',
    // events: {
    // },
    // scrollablePlotArea: {
    //   minWidth: 1600,
    //   // scrollPositionX: HOUR_OF_DAY / 100,
    // },
  },
  title: {
    text: '',
    accessibility: {
      text: 'Detaillierte Wettervorhersagekarte',
    },
  },
  accessibility: {
    enabled: true,
    announceNewData: {
      enabled: true,
    },
    description:
      'Diese Diagramme zeigen Wetterinformation von Wetterstationen mit unterschiederlicher zeitlich Auflösungen und Parameter.',
    point: {
      valueDescriptionFormat: '{index}. {xDescription}, {value}.',
    },
  },
  xAxis: {
    categories: formatDates(filteredData.map((point) => point.date)),
    title: {
      text: '',
    },
    gridLineWidth: 1,
    gridLineDashStyle: 'longdash',
    crosshair: {
      color: ' #FFFFFF',
    },
    // tickmarkPlacement: [1, 2, 3].includes(parameterNumber) ? 'between' : 'on', //
    tickmarkPlacement: 'on',
    tickInterval: 1,
    tickWidth: 1,
    tickLength: 20,
    // labels: {
    //     align: 'left'
    // }
    // grid: {
    //   borderColor: '#3a5d96', // Grid line color
    //   alternateGridColor: '#F5F5F5', // Background color for alternate rows
    // },
  },
  legend: {
    useHTML: true,
    symbolHeight: 0,
    symbolWidth: 0,
    symbolRadius: 0,
    align: 'low',
    x: 20, // Adjust this value to move the title to the left
    y: 0, // Adjust this value to move the title to the bottom
    labelFormatter: function () {
      return (
        '<span style="color:' +
        this.color +
        ';font-size: 16px;">■' +
        '<span style="color:#052E37 ">' +
        (this.name === 'Wind'
          ? language == 'de'
            ? 'Mittelwind'
            : 'Wind'
          : '') +
        '' +
        // für die legende unter dass windspitze monatsmaximum zum bsp steht
        ((this.name === 'Windspitze' || this.name === 'Gust') &&
        emitedApiendpointIndex === 0
          ? language == 'de'
            ? this.name
            : 'Gust'
          : '') +
        '' +
        // für die legende unter dass windspitze monatsmaximum zum bsp steht
        ((this.name === 'Windspitze' || this.name === 'Gust') &&
        emitedApiendpointIndex > 0
          ? this.name + ' ' + arrayKey[emitedApiendpointIndex]
          : '') +
        '' +
        (this.name != 'Windspitze' &&
        this.name != 'Wind' &&
        this.name != 'Gust' &&
        this.name != 'Wind'
          ? this.name
          : '')
      );
    },
  },
  yAxis: {
    className: 'y-axis-title-background-Tawes',
    gridLineDashStyle: 'longdash',
    title: {
      text:
        (language == 'de' ? apiParameter.name : apiParameter.nameEn) +
        ' ' +
        unit,
      useHTML: true,
      style: {
        display: 'flex',
        color: '#fff',
        'background-color': '#052E37',
      },
    },
    legend: {
      enabled: false,
    },
  },

  tooltip: {
    followPointer: true,
    outside: false, // macht ein div mit highcharts-tooltip-container
    enabled: isMobileDevice() ? false : true, // Disable the tooltip by default

    // useHTML: true,
    // shared: false,
    borderRadius: 0,
    backgroundColor: '#052E37',
    borderColor: '#052E37',
    formatter: formatTooltip(data, language, unit),

    style: {
      // minHeight: '92px',
      width: '300px',
      color: '#ffffff', // Text color
      textAlign: 'left', // Center-align text
      fontSize: '18px',
      fontFamily: 'WeissenhofGrotesk',
      font: ' normal normal normal 19px/30px Source Sans Pro',
    },
    positioner: function (labelWidth, labelHeight, point) {
      const chart = this.chart;

      // Calculate desired position (center above point)
      let tooltipX = point.plotX + chart.plotLeft - labelWidth / 2;
      const tooltipY = point.plotY + chart.plotTop - labelHeight - 10;

      // Prevent overflow on the left side
      if (tooltipX < chart.plotLeft) {
        tooltipX = chart.plotLeft + 10; // Adjust to stay inside
      }

      // Prevent overflow on the right side
      if (tooltipX + labelWidth > chart.plotLeft + chart.plotWidth) {
        tooltipX = chart.plotLeft + chart.plotWidth - labelWidth - 10; // Adjust to stay inside
      }
      // if (isMobileDevice() === true) {
      //   return { x: 15, y: 0 };
      // } else {
      //   return {
      //     x: tooltipX,
      //     y: tooltipY,
      //   };
      // }
      return {
        x: tooltipX,
        y: tooltipY,
      };
    },
    // useHTML: true,
  },

  series: [
    {
      name:
        language == 'de'
          ? navItemTawes[Number(parameterNumber)].name
          : navItemTawes[Number(parameterNumber)].nameEn,
      data: filteredData.map((point) =>
        point.value !== null
          ? Math.round(point.value * apiParameter.multiplicator * 100) / 100
          : null,
      ),
      color: navItemTawes[Number(parameterNumber)].color,
      connectNulls: false,
      lineWidth: 3,
      pointPlacement: [1, 2, 3].includes(parameterNumber) ? -0.5 : 0,
      // data: [1, 2, 3, 4, 5],
    },
    ...(filteredData[0].value2
      ? [
          {
            name:
              parameter == 'TL'
                ? 'Min Temperatur'
                : language == 'de'
                  ? 'Windspitze'
                  : 'Gust',
            data: filteredData.map((point) =>
              point.value2 !== null
                ? Math.round(point.value2 * apiParameter.multiplicator * 100) /
                  100
                : null,
            ),
            pointPlacement: [1, 2, 3].includes(parameterNumber) ? -0.5 : 0,
            color: navItemTawes[Number(parameterNumber)].color2,
          },
        ]
      : []),
    ...(filteredData[0].value3
      ? [
          {
            name: 'Max Temperatur',
            data: filteredData.map((point) =>
              point.value3 !== null
                ? point.value3 * apiParameter.multiplicator
                : null,
            ),
            pointPlacement: [1, 2, 3].includes(parameterNumber) ? -0.5 : 0,
            color: navItemTawes[Number(parameterNumber)].color3,
          },
        ]
      : []),
  ],
  plotOptions: {
    series: { marker: { enabled: false } },
  },
  credits: {
    enabled: false,
  },
});

export { highchartsOptionWeatherstation };
