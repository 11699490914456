import React from 'react';
import moment from 'moment';
import { pointOrComma } from '../../utils/pointOrComma';
import styles from './TawesMarker.module.css';
import { navItemTawes, mapping } from '../../../constants/TawesHelper';
const TawesMarkerTooltipDiv = ({
  language,
  tawesSubmenu,
  parameterNumber,
  pointInformation,
  popupRef,
}) => {
  const secToMin = (mapping, sec) => {
    const min = (parseFloat(sec) / 60).toFixed(1);
    return ` ${mapping.find((i) => i.par === 'SO').nameDe}: ${min} min`;
  };

  return (
    <div className={styles.backgroundTooltop}>
      <p className={styles.pPopupFirstChild}>
        Station:{' '}
        {pointInformation.data != false
          ? pointInformation.data.mapping[popupRef.current.properties.station]
              .name
          : null}
      </p>
      <p className={styles.pPopupOtherChild}>
        {language === 'de' ? 'Seehöhe:' : 'Altitude:'}{' '}
        {pointInformation.data != false
          ? pointInformation?.data?.mapping[
              popupRef.current.properties.station
            ]['elevation']
          : null}
        m
      </p>
      <div className={styles.pPopupOtherChild}>
        {tawesSubmenu === 'SO' ? (
          <p className={styles.pPopupOtherChild}>
            {secToMin(
              mapping,
              popupRef.current.properties.parameters[tawesSubmenu].data,
            )}
          </p>
        ) : (
          <div>
            {language === 'de'
              ? mapping.find((i) => i.par === tawesSubmenu).nameDe
              : mapping.find((i) => i.par === tawesSubmenu).nameEn}
            :{' '}
            {tawesSubmenu === 'FFAM'
              ? pointOrComma(
                  Number(
                    popupRef.current.properties.parameters[tawesSubmenu].data *
                      3.6,
                  ).toFixed(2),
                  language,
                )
              : pointOrComma(
                  popupRef.current.properties.parameters[tawesSubmenu].data,
                  language,
                )}{' '}
            {navItemTawes[Number(parameterNumber)].unit}
          </div>
        )}
      </div>
      {tawesSubmenu === 'FFAM' && (
        <p className={styles.pPopupOtherChild}>
          {language === 'de' ? 'Windspitze:' : 'Gust:'}{' '}
          {pointOrComma(
            Number(
              popupRef.current.properties.parameters['FFX'].data * 3.6,
            ).toFixed(2),
            language,
          )}{' '}
          {navItemTawes[Number(parameterNumber)].unit}
        </p>
      )}
      <p>
        {language === 'de' ? 'Gemessen:' : 'Measured:'}{' '}
        {moment(popupRef.current.properties['timestep']).format(
          'DD-MM-YYYY, HH:mm',
        )}
      </p>
    </div>
  );
};

export default TawesMarkerTooltipDiv;
