import React from 'react';
import {
  accordionBlockHasValue,
  Icon
} from '@eeacms/volto-accordion-block/components/manage/Blocks/Accordion/util.js';
import { defineMessages, useIntl } from 'react-intl';
import { Accordion } from 'semantic-ui-react';
import { useLocation, useHistory } from 'react-router-dom';
import cx from 'classnames';
import AnimateHeight from 'react-animate-height';
import config from '@plone/volto/registry';
import '@eeacms/volto-accordion-block/components/manage/Blocks/Accordion/editor.less';

const messages = defineMessages({
  mobile: {
    id: 'mobile',
    defaultMessage: 'Mobile',
  },
  phone: {
    id: 'phone',
    defaultMessage: 'Phone',
  },
  externalLinks: {
    id: 'externalLinks',
    defaultMessage: 'External links'
  }
});

const useQuery = (location) => {
  const { search } = location;
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const View = (props) => {
  const { items, isEditMode } = props;
  const data = { collapsed: true };
  const location = useLocation();
  const history = useHistory();

  const sortedItems = Array.isArray(items)
    ? items.sort((a, b) => a.title.localeCompare(b.title))
    : [];

  const groupedPanels = sortedItems.reduce((acc, item) => {
    const firstLetter = item.title.charAt(0).toUpperCase();

    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }

    acc[firstLetter].push([item['@id'], {
      "@type": "accordionPanel",
      "blocks": {
        "block-id": {
          "@type": "slate",
          "plaintext": item.description,
          "value": [
            {
              "children": [
                { "text": item.description }
              ],
              "type": "p"
            }
          ]
        }
      },
      "blocks_layout": { "items": ["block-id"] },
      "@id": item['@id'],
      "employee_email": item.employee_email || false,
      "employee_links": item.employee_links || [],
      "employee_phone": item.employee_phone || false,
      "employee_mobile": item.employee_mobile || false,
      "employee_orgunit": item.employee_orgunit || [],
      "employee_posttitles": item.employee_posttitles || [],
      "employee_pretitles": item.employee_pretitles || [],
      "employee_surname": item.employee_surname || [],
      "employee_name": item.employee_name || [],
      "hasPreviewImage": item.hasPreviewImage || false,
      "image_field": item.image_field || "",
      "image_scales": item.image_scales || null,
      "title": item.title,
    }]);

    return acc;
  }, {});

  const panels = Object.keys(groupedPanels).map(letter => ({
    id: letter,
    items: groupedPanels[letter]
  }));

  const firstPanel = panels.length > 0 ? panels[0] : { id: null, items: [] };
  const firstIdFromPanels = firstPanel.id;

  const Image = config.getComponent('Image').component;

  const diffView = location?.pathname.slice(
    location?.pathname.lastIndexOf('/'),
    location?.pathname.length,
  ) === '/diff';

  const [activeIndex, setActiveIndex] = React.useState([]);
  const [activePanel, setActivePanel] = React.useState([]);
  const [activeNestedPanel, setActiveNestedPanel] = React.useState({});
  const [itemToScroll, setItemToScroll] = React.useState('');
  const accordionConfig = config.blocks.blocksConfig.accordion;
  const { titleIcons } = accordionConfig;
  const iconPosition = 'rightPosition';

  const query = useQuery(location);
  const activePanels = query.get('activeAccordion')?.split(',');

  const activePanelsRef = React.useRef(activePanels);
  const firstIdFromPanelsRef = React.useRef(firstIdFromPanels);

  const addQueryParam = (key, value) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(key, value);

    history.push({
      hash: location.hash,
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const handleTopLevelClick = (e, itemProps) => {
    const { index, id } = itemProps;
    const newIndex =
      activeIndex.indexOf(index) === -1
        ? [index]
        : activeIndex.filter((item) => item !== index);

    const newPanel =
      activePanel.indexOf(id) === -1
        ? [id]
        : activePanel.filter((item) => item !== id);

    handleActiveIndex(newIndex, newPanel);
  };

  const handleNestedClick = (e, itemProps, letter) => {
    const { index, id } = itemProps;
    setActiveNestedPanel(prev => ({
      ...prev,
      [letter]: prev[letter]?.includes(id)
        ? prev[letter].filter(panelId => panelId !== id)
        : [...(prev[letter] || []), id]
    }));
  };

  const handleActiveIndex = (index, id) => {
    setActiveIndex(index);
    setActivePanel(id);
    addQueryParam('activeAccordion', id);
  };

  const scrollToElement = () => {
    if (!!activePanels && !!activePanels[0].length) {
      let element = document.getElementById(
        activePanels[activePanels.length - 1],
      );
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const intl = useIntl();

  React.useEffect(() => {
    !!activePanelsRef.current &&
      setItemToScroll(
        activePanelsRef.current[activePanelsRef.current?.length - 1],
      );
  }, []);

  React.useEffect(() => {
    if (data.collapsed) {
      setActivePanel(activePanelsRef.current || []);
    } else {
      if (!!activePanelsRef.current && !!activePanelsRef.current[0].length) {
        setActivePanel(activePanelsRef.current || []);
      } else {
        setActivePanel([
          firstIdFromPanelsRef.current,
          ...(activePanelsRef.current || []),
        ]);
      }
    }
  }, [data.collapsed]);

  return (
    <div className={cx('accordion-block', 'employees')}>
      {panels.map((panel, index) => (
        <Accordion
          key={panel.id}
          id={panel.id}
          exclusive={!data.exclusive}
          className={
            data.styles
              ? data.styles.theme
              : accordionConfig?.defaults?.theme
          }
          {...accordionConfig.options}
        >
          <React.Fragment>
            <Accordion.Title
              as={data.title_size}
              active={activePanel.includes(panel.id)}
              aria-expanded={activePanel.includes(panel.id)}
              className={cx('accordion-title', 'align-arrow-right')}
              index={index}
              onClick={(e) => handleTopLevelClick(e, { index, id: panel.id })}
              onKeyDown={(e) => {
                if (e.keyCode === 13 || e.keyCode === 32) {
                  e.preventDefault();
                  handleTopLevelClick(e, { index, id: panel.id });
                }
              }}
              role="button"
              tabIndex={0}
            >
              <Icon
                options={titleIcons}
                name={
                  activePanel.includes(panel.id)
                    ? titleIcons.opened[iconPosition]
                    : titleIcons.closed[iconPosition]
                }
              />
              <span>{panel.id}</span>
            </Accordion.Title>
            <AnimateHeight
              animateOpacity
              duration={500}
              height={activePanel.includes(panel.id) || diffView ? 'auto' : 0}
              onTransitionEnd={() => {
                if (!!activePanels && panel.id === itemToScroll) {
                  scrollToElement();
                  setItemToScroll('');
                }
              }}
            >
              {panel.items.map(([itemId, itemData]) => {
                return accordionBlockHasValue(itemData) ? (
                  <Accordion
                    key={itemId}
                    id={itemId}
                    exclusive={!data.exclusive}
                    className={
                      data.styles
                        ? data.styles.theme
                        : accordionConfig?.defaults?.theme
                    }
                    {...accordionConfig.options}
                  >
                    <React.Fragment>
                      <Accordion.Title
                        as={data.title_size}
                        active={activeNestedPanel[panel.id]?.includes(itemId)}
                        aria-expanded={activeNestedPanel[panel.id]?.includes(itemId)}
                        className={cx('accordion-title', 'align-arrow-right')}
                        index={index}
                        onClick={(e) => handleNestedClick(e, { index, id: itemId }, panel.id)}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13 || e.keyCode === 32) {
                            e.preventDefault();
                            handleNestedClick(e, { index, id: itemId }, panel.id);
                          }
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        <Icon
                          options={titleIcons}
                          name={
                            activeNestedPanel[panel.id]?.includes(itemId)
                              ? titleIcons.opened[iconPosition]
                              : titleIcons.closed[iconPosition]
                          }
                        />
                        <span>
                          {itemData.employee_pretitles && !itemData.employee_pretitles.includes('no-value') && (
                            <span className="title_pretitle">{itemData.employee_pretitles.join(' ') + ' '}</span>
                          )}
                          <span className="title_name">{`${itemData.employee_surname} ${itemData.employee_name}`} </span>
                          {itemData.employee_posttitles && !itemData.employee_posttitles.includes('no-value') && (
                            <span className="title_posttitle">{itemData.employee_posttitles.join(' ')}</span>
                          )}
                        </span>
                      </Accordion.Title>
                      <AnimateHeight
                        animateOpacity
                        duration={500}
                        height={activeNestedPanel[panel.id]?.includes(itemId) || diffView ? 'auto' : 0}
                        onTransitionEnd={() => {
                          if (!!activePanels && itemId === itemToScroll) {
                            scrollToElement();
                            setItemToScroll('');
                          }
                        }}
                      >
                        <Accordion.Content active={diffView ? true : activeNestedPanel[panel.id]?.includes(itemId)}>
                          <div className="employee">
                            <div className="image-wrapper">
                                {itemData.hasPreviewImage && (
                                  <Image
                                    src={itemData['@id'] + '/' + itemData.image_scales?.preview_image?.[0]?.scales?.large?.download}
                                    imageField={itemData.image_field}
                                    alt=""
                                    loading="lazy"
                                    responsive={true}
                                  />
                                )}
                            </div>
                            <div className="employee-data">
                              <h5>
                                {itemData.employee_pretitles && !itemData.employee_pretitles.includes('no-value') && (
                                  <span className="pretitle">{itemData.employee_pretitles.join(' ') + ' '}</span>
                                )}
                                <span className="Name">{`${itemData.employee_surname} ${itemData.employee_name}`} </span>
                                {itemData.employee_posttitles && !itemData.employee_posttitles.includes('no-value') && (
                                  <span className="posttitle">{itemData.employee_posttitles.join(' ')}</span>
                                )}
                              </h5>
                              <span className="role">{
                                itemData.employee_orgunit && !itemData.employee_orgunit.includes('no-value') && (
                                  itemData.employee_orgunit.join(', ')
                                )}
                              </span>
                              <ul className="contact">
                                {itemData.employee_email && (
                                  <li>
                                    <strong>E-Mail:</strong>
                                    <span>{itemData.employee_email}</span>
                                  </li>
                                )}
                                {itemData.employee_phone && (
                                  <li>
                                    <strong>{intl.formatMessage(messages.phone)}:</strong>
                                    <span>{itemData.employee_phone}</span>
                                  </li>
                                )}
                                {itemData.employee_mobile && (
                                  <li>
                                    <strong>{intl.formatMessage(messages.mobile)}:</strong>
                                    <span>{itemData.employee_mobile}</span>
                                  </li>
                                )}
                                {
                                  itemData.employee_links && !itemData.employee_links.includes('no-value') && (
                                    <li className="links">
                                      <strong>{intl.formatMessage(messages.externalLinks)}:</strong>
                                      <span>
                                        {
                                          itemData.employee_links.map((address, index) => {
                                            const [title, url] = address.split(/:(.+)/);
                                            const isLast = index === itemData.employee_links.length - 1;
                                            return (
                                              <React.Fragment key={url}>
                                                <a href={url}>{title}</a>
                                                {!isLast && ", "}
                                              </React.Fragment>
                                            );
                                          })
                                        }
                                      </span>
                                    </li>
                                  )
                                }

                              </ul>
                            </div>
                          </div>
                        </Accordion.Content>
                      </AnimateHeight>
                    </React.Fragment>
                  </Accordion>
                ) : null;
              })}
            </AnimateHeight>
          </React.Fragment>
        </Accordion>
      ))}
    </div>
  );
};

export default View;
