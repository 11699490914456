import React from 'react';
import config from '@plone/volto/registry';

const DefaultImage = (props) => <img {...props} alt={props.alt || ''} />;

const ImageCarousel = ({data}) => {
  const image = data.preview_image?.[0];
  const Image = config.getComponent('Image').component || DefaultImage;

  return (
    <div className="image-carousel-item">
        {image && (
          <div className="highlight-image-wrapper gradient">
            <Image
              item={image || href}
              imageField={image.image_field}
              alt=""
              loading="lazy"
              responsive={true}
            />
          </div>
        )}
        <p>{data?.description}</p>
    </div>
  );
};

export default ImageCarousel;
