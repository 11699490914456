// SemanticUI-free pre-@plone/components
import React from 'react';

import { injectIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { Container } from '@plone/components';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import config from '@plone/volto/registry';
import { Icon } from '@plone/volto/components';
import { Image } from 'semantic-ui-react';
// Images
import LogoImage from '../../../../../images/GSA_LogoMitZusatz_NegativAufMidnightGreen_RGB_XXS.svg';
import svgInstagram from '../../../../../icons/Instagram.svg';
import svgX from '../../../../../icons/Twitter-X.svg';
import svgYoutube from '../../../../../icons/Youtube.svg';
import svgFacebook from '../../../../../icons/Facebook.svg';
import svgLinkedIn from '../../../../../icons/LinkedIn.svg';
import svgFlickr from '../../../../../icons/Flickr.svg';
import svgExternalLink from '../../../../../icons/Link_Extern_Lime_16px.svg';
import svgAccordionOpen from '../../../../../icons/Accordion_open_16px.svg';
import svgAccordionClose from '../../../../../icons/Accordion_close_16px.svg';
import { footerMessages } from '../../../../../i18nl10n';

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  const { settings } = config;
  const site = useSelector((state) => state.site.data);
  const { lang, siteActions = [] } = useSelector(
    (state) => ({
      lang: state.intl.locale,
      siteActions: state.actions?.actions?.site_actions,
    }),
    shallowEqual,
  );

  const items = useSelector((state) => state.navigation.items, shallowEqual);
  // track the open state of each dropdown in Array
  const [dropdownStates, setDropdownStates] = useState(
    Array(items.length).fill(false),
  );

  const toggleDropdown = (index) => {
    setDropdownStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };
  // close all dropdowns except the one at the given index
  const closeOtherDropdowns = (index) => {
    setDropdownStates((prevStates) =>
      prevStates.map((state, i) => (i === index ? true : false)),
    );
  };
  const participate_items = [
    {
      title: intl.formatMessage(footerMessages.reportWeather),
      url: intl.formatMessage(footerMessages.reportWeatherLink),
      id: 'weather',
    },
    {
      title: intl.formatMessage(footerMessages.reportEarthquake),
      url: intl.formatMessage(footerMessages.reportEarthquakeLink),
      id: 'earthquake',
    },
    {
      title: 'Phenowatch.at',
      url: 'https://www.phenowatch.at/',
      id: 'phenowatch',
    },
  ];
  const footer_impressum_items = [
    {
      title: intl.formatMessage(footerMessages.impress),
      url: intl.formatMessage(footerMessages.impressLink),
      id: 'impressum',
    },
    {
      title: intl.formatMessage(footerMessages.dataProtection),
      url: intl.formatMessage(footerMessages.dataProtectionLink),
      id: 'security',
    },
    {
      title: intl.formatMessage(footerMessages.legal),
      url: intl.formatMessage(footerMessages.legalLink),
      id: 'legal',
    },
    {
      title: intl.formatMessage(footerMessages.accessibility),
      url: intl.formatMessage(footerMessages.accessibilityLink),
      id: 'accessibility',
    },
  ];
  const footer_contact_items = [
    {
      title: intl.formatMessage(footerMessages.detailledContactPage),
      url: intl.formatMessage(footerMessages.detailledContactPageLink),
      id: 'detailed_contact',
    },
    {
      title: intl.formatMessage(footerMessages.pressContacts),
      url: intl.formatMessage(footerMessages.pressContactsLink),
      id: 'press',
    },
  ];

  return (
    <footer id="footer">
      <Container className="footer-prev">
        <Container layout className="footer-listing footer-menu">
          {items.map((item, index) => (
            <div key={item.url} className="footer-item-wrapper">
              {
                <>
                  <div>
                    <NavLink
                      to={item.url === '' ? '/' : item.url}
                      className="footer-menu-header"
                    >
                      <h2>{item.nav_title ?? item.title}</h2>
                    </NavLink>
                    <Icon
                      className="accordion-open"
                      name={svgAccordionOpen}
                      size="16px"
                      onClick={() => {
                        toggleDropdown(index);
                        closeOtherDropdowns(index);
                      }}
                    />
                    <Icon
                      className="accordion-close"
                      name={svgAccordionClose}
                      size="16px"
                      onClick={() => {}}
                    />
                  </div>
                  <ul
                    className={`footer-submenu ${dropdownStates[index] ? 'open' : ''}`}
                  >
                    {item.items &&
                      item.items.length > 0 &&
                      item.items.map((subitem) => (
                        <li
                          className="footer-subitem-wrapper"
                          key={subitem.url}
                        >
                          <NavLink to={subitem.url}>
                            <span>{subitem.nav_title || subitem.title}</span>
                          </NavLink>
                        </li>
                      ))}
                  </ul>
                </>
              }
            </div>
          ))}
          <div className="footer-item-wrapper participate" key="participate">
            <>
              <div>
                <h2 className="footer-menu-header">
                  {intl.formatMessage(footerMessages.participate)}
                </h2>
                <Icon
                  className="accordion-open"
                  name={svgAccordionOpen}
                  size="16px"
                  onClick={() => {
                    toggleDropdown(items.length); // index of this menu will be length of previous menues
                    closeOtherDropdowns(items.length);
                  }}
                />
                <Icon
                  className="accordion-close"
                  name={svgAccordionClose}
                  size="16px"
                  onClick={() => {}}
                />
              </div>

              <ul
                className={`footer-submenu ${dropdownStates[items.length] ? 'open' : ''}`}
              >
                {participate_items.map((item) => (
                  <li className="footer-subitem-wrapper" key={item.id}>
                    <a href={item.url} target="_blank" rel="noreferrer">
                      <Icon name={svgExternalLink} size="16px" />
                      <span>{item.title}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </>
          </div>
        </Container>
      </Container>
      <Container className="footer">
        <Container layout>
          <div className="footer-logo">
            <Image src={LogoImage} />
          </div>
          <div className="contact">
            <h2>{intl.formatMessage(footerMessages.contact)}</h2>
            {/* wrap in div for a11y reasons: listitem role cannot be on the <a> element directly */}
            {/* {siteActions?.length
              ? siteActions.map((item) => (
                  <li className="item" key={item.id}>
                    <UniversalLink
                      className="item"
                      href={
                        settings.isMultilingual
                          ? `/${lang}/${
                              item.url
                                ? flattenToAppURL(item.url)
                                : addAppURL(item.id)
                            }`
                          : item.url
                            ? flattenToAppURL(item.url)
                            : addAppURL(item.id)
                      }
                    >
                      {item?.title}
                    </UniversalLink>
                  </li>
                ))
              : null} */}
            GeoSphere Austria
            <br />
            1190 Wien, Hohe Warte 38
            <br />
            <ul className="footer-links">
              {footer_contact_items.map((item) => (
                <li key={item.id}>
                  <a href={item.url} target="_blank" rel="noreferrer">
                    <span>{item.title}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="social-media">
            <h2>{intl.formatMessage(footerMessages.socialmedia)}</h2>
            <div className="social-media-actions">
              <div className="social-media-action">
                <a
                  target="_blank"
                  href="https://www.instagram.com/GeoSphere_AT"
                  rel="noreferrer"
                >
                  <Icon name={svgInstagram} size="32px" />
                  <span>Instagram</span>
                </a>
              </div>
              <div className="social-media-action">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://x.com/GeoSphere_AT"
                >
                  <Icon name={svgX} size="32px" />
                  <span>X</span>
                </a>
              </div>
              <div className="social-media-action">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.youtube.com/@geosphereaustria2023"
                >
                  <Icon name={svgYoutube} size="32px" />
                  <span>YouTube</span>
                </a>
              </div>
              <div className="social-media-action">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/GeoSphere.at"
                >
                  <Icon name={svgFacebook} size="32px" />
                  <span>Facebook</span>
                </a>
              </div>
              <div className="social-media-action">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://at.linkedin.com/company/geosphereaustria"
                >
                  <Icon name={svgLinkedIn} size="32px" />
                  <span>LinkedIn</span>
                </a>
              </div>
              <div className="social-media-action">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.flickr.com/photos/geosphere_austria"
                >
                  <Icon name={svgFlickr} size="32px" />
                  <span>Flickr</span>
                </a>
              </div>
            </div>

            <ul className="footer-links">
              {footer_impressum_items.map((item) => (
                <li key={item.id}>
                  <a href={item.url} target="_blank" rel="noreferrer">
                    <span>{item.title}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </Container>
      </Container>
    </footer>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
