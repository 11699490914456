//TawesHighcharts.js

import React, { useRef, useEffect, useState } from 'react';
import Highcharts, { isArray, time } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import styles from './TawesHighcharts.module.css';
import './TawesHighcharts.css';

import moment from 'moment';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import TawesHighchartsNavbar from './TawesHighchartsNavbar';
import { navItemTawes } from '../../../constants/TawesHelper';
import { pointOrComma } from '../../utils/pointOrComma';
import TawesHighchartsHeader from './TawesHighchartsHeader';
import { highchartsOptionWeatherstation } from '../HelperFunctions/highchartsOptionWeatherstation';
import InformationStatus from '../ui/InformationStatus';
moment.locale('de');
if (typeof Highcharts === 'object') {
  highchartsAccessibility(Highcharts);
}

const getApiIndex = (apiEndpoint) => {
  switch (apiEndpoint) {
    case 'tawes-v1-10min':
      return 0;
    case 'tawes-v1-1h':
      return 1;
    case 'tawes-v1-6h':
      return 2;
    case 'tawes-v1-12h':
      return 3;
    default:
      return 0;
  }
};
const determineStartTimeAndIndex = (apiEndpoint, formattedCurrentTime) => {
  switch (apiEndpoint) {
    case 'tawes-v1-10min':
    case 'klima-v2-10min':
      return moment(formattedCurrentTime, 'YYYY-MM-DDTHH:mm ')
        .subtract(6, 'hours')
        .format('YYYY-MM-DDTHH:mm')
        .replace(':', '%3A');
    case 'klima-v2-1h':
      return moment(formattedCurrentTime, 'YYYY-MM-DDTHH:mm ')
        .subtract(31, 'hours')
        .format('YYYY-MM-DDTHH:mm')
        .replace(':', '%3A');
    case 'klima-v2-1d':
      return moment(formattedCurrentTime, 'YYYY-MM-DDTHH:mm ')
        .subtract(31, 'days')
        .format('YYYY-MM-DDTHH:mm')
        .replace(':', '%3A');
    case 'klima-v2-1m':
      return moment(formattedCurrentTime, 'YYYY-MM-DDTHH:mm ')
        .subtract(31, 'months')
        .format('YYYY-MM-DDTHH:mm')
        .replace(':', '%3A');
    default:
      return null;
  }
};

const TawesHighcharts = ({
  station,
  parameterNumber,
  language,
  pointsState,
}) => {
  const chartContainerRef = useRef(null);
  const refScrollto = useRef(null);

  const [emitedApiendpoint, setEmitedApiendpoint] = useState('tawes-v1-10min');

  const pointInformation = useSelector(
    // (state) => state.geospheredataplatformdata?[data.servicename] ? state.geospheredataplatformdata?[data.servicename].data : {}
    (state) =>
      state.geospheredataplatformdata
        ? state.geospheredataplatformdata[pointsState]
        : {},
  );

  const handleApiEmit = (api) => {
    setEmitedApiendpoint(api);

    // Handle the API value here (e.g., make a request to the API)
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  let mappedPoint =
    pointInformation.data == false
      ? null
      : pointInformation.data.mapping[station.properties.station];
  const parameter = navItemTawes[parameterNumber].par[0].par;
  useEffect(() => {
    if (!station || pointInformation.data == false) return;
    setLoading(true); // für nix weil state change nicht so schnell geht
    let apiParameter = navItemTawes[Number(parameterNumber)];

    const controller = new AbortController(); // Create a new AbortController instance
    const signal = controller.signal; // Extract the signal from the controller

    const fetchData = async () => {
      if (data.features) setData(false);
      try {
        if (station.properties.parameters[parameter] == null) {
          const errMsg =
            language == 'de'
              ? 'Derzeit sind keine detaillierten Daten für diese Station verfügbar'
              : 'Currently no detailed data for that station is available';
          throw new Error(errMsg);
        }
        let stationTimeStamp = station.properties.timestep;

        const formattedCurrentTime = stationTimeStamp
          .split('+')[0]
          .replace(':', '%3A');

        let startTime = determineStartTimeAndIndex(
          emitedApiendpoint,
          formattedCurrentTime,
        );
        if (!startTime) return;

        let station_id = station.properties.station;
        if (emitedApiendpoint.includes('klima'))
          station_id = mappedPoint.klima_id;

        let parameterTawesKlima = apiParameter.apis.find((obj) =>
          obj.hasOwnProperty(emitedApiendpoint),
        )[emitedApiendpoint];

        const sanitizedEndpoint = encodeURIComponent(emitedApiendpoint);
        const sanitizedParameters = encodeURIComponent(parameterTawesKlima);
        const sanitizedStationId = encodeURIComponent(station_id);

        const response = await axios.get(
          `https://dataset.api.hub.geosphere.at/v1/station/historical/${sanitizedEndpoint}?parameters=${sanitizedParameters}&start=${startTime}&end=${formattedCurrentTime}&station_ids=${sanitizedStationId}`,
          {
            signal,
          },
        );
        // wenn nichts daher kommt schreib error
        parameterTawesKlima =
          typeof parameterTawesKlima == 'object'
            ? parameterTawesKlima[0]?.split(',')[0]
            : parameterTawesKlima; //"FFAM,FFX"
        if (
          typeof parameterTawesKlima === 'string' &&
          response.data.features[0].properties.parameters[
            parameterTawesKlima
          ].data.every((i) => i === null)
        ) {
          setError(true);
          setLoading(false);
          return;
        }
        setData(response.data);
        setLoading(false);
        setError(false);
      } catch (err) {
        if (axios.isCancel(err)) {
          // console.log('Request canceled', err.message); // Request was aborted
        } else {
          setError(err.message); // Handle other errors
        }
        console.log(err);
        setError(err);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    if (refScrollto.current) {
      refScrollto.current.scrollIntoView({ behavior: 'smooth' });
    }
    // Cleanup function to abort the request on number change
    return () => {
      controller.abort(); // Cancel the request
    };
  }, [station, parameter, emitedApiendpoint]);

  const [options, setOptions] = useState(false);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!data?.features) return;
    // let propParamter = navItemTawes[Number(parameterNumber)].par[0].par;

    let apiParameter = navItemTawes[Number(parameterNumber)];

    let parameterTawesKlima = apiParameter.apis.find((obj) =>
      obj.hasOwnProperty(emitedApiendpoint),
    )[emitedApiendpoint];
    // Combine timestamps and data into a single array of objects
    let combinedData;

    // wenn mir als ein parameter wird es zu array daher die abfrage

    let checker = false;
    const oKey = Object.keys(data.features[0].properties.parameters);
    if (isArray(parameterTawesKlima)) {
      const splitArr = parameterTawesKlima[0]; // "tlmin,.."
      if (!splitArr.includes(oKey[0])) checker = true;
    } else {
      if (oKey[0] != parameterTawesKlima) checker = true;
    }
    if (checker) {
      return;
    }
    if (typeof parameterTawesKlima != 'string') {
      let splitParmameterArray = parameterTawesKlima[0].split(',');
      combinedData = data.timestamps.map((timestamp, index) => ({
        date: timestamp,
        value:
          data.features[0].properties.parameters[splitParmameterArray[0]].data[
            index
          ],
        value2:
          data.features[0].properties.parameters[splitParmameterArray[1]].data[
            index
          ],
        value3:
          data.features[0].properties.parameters[splitParmameterArray[2]]?.data[
            index
          ],
      }));
    } else if (data.features[0].properties.parameters[parameterTawesKlima]) {
      combinedData = data.timestamps.map((timestamp, index) => ({
        date: timestamp,
        value:
          data.features[0].properties.parameters[parameterTawesKlima].data[
            index
          ],
      }));
    }

    // Niederschlag - Tageswerte - beihnahltet werte -1 - rausfiltern
    if (parameter === 'RR') {
      combinedData = combinedData.map((obj) => ({
        ...obj,
        value: obj.value === -1 ? null : obj.value,
      }));
    }

    const filteredData = combinedData;

    // Simulate async data fetching
    setTimeout(() => {
      setOptions(makeConfig(filteredData, parameter, apiParameter));
      setLoaded(true);
    }, 300);
  }, [data]);

  // outsourciung functions

  const makeConfig = (filteredData, parameter, apiParameter) => {
    // Function to format date strings to 'HH:mm' format

    const arrayKey = ['Stundenmaximum', 'Tagesmaximum', 'Monatsmaximum'];

    const oKey = Object.keys(data.features[0].properties.parameters);
    let unit =
      parameter == 'FFAM'
        ? 'km/h'
        : data.features[0].properties.parameters[oKey[0]].unit;
    const formatDates = (dates) => {
      if (
        emitedApiendpoint.includes('10min') ||
        emitedApiendpoint.includes('1h')
      ) {
        // if ([1, 2, 3].includes(parameterNumber)) {
        //   return emitedApiendpoint.includes('10min')
        //     ? dates.map((date) =>
        //         moment(date).subtract(10, 'minute').format('HH:mm'),
        //       )
        //     : dates.map((date) =>
        //         moment(date).subtract(1, 'hour').format('HH:mm'),
        //       );
        // } else
        return dates.map((date) => moment(date).format('HH:mm'));
      } else if (emitedApiendpoint.includes('-1d'))
        return dates.map((date) => moment(date).format('DD.MM '));
      else if (emitedApiendpoint.includes('-1m'))
        return dates.map((date) => moment(date).format('MMM'));
    };

    function formatTooltip(data, language, unit) {
      return function () {
        let timestamp = data.timestamps[this.point.x];
        let output = '';
        if (emitedApiendpoint === 'klima-v2-1d') {
          output = moment(timestamp).format('dddd DD.MM.YYYY');
        } else if (emitedApiendpoint === 'klima-v2-1m') {
          output = moment(timestamp).format('MM.YYYY');
        } else if ([1, 2, 3].includes(parameterNumber)) {
          let lastTick =
            emitedApiendpoint === 'tawes-v1-10min'
              ? moment(timestamp).subtract(10, 'minute').format('HH:mm')
              : moment(timestamp).subtract(1, 'hour').format('HH:mm');
          //parameterNumber=== 1, 2 ,3 rr, ff, so
          output =
            moment(timestamp).format('DD.MM.YYYY') +
            ' | ' +
            lastTick +
            ' - ' +
            moment(timestamp).format('HH:mm');
        } else {
          //parameterNumber=== 1, 2 ,3 rr, ff, so
          output =
            moment(timestamp).format('DD.MM.YYYY') +
            ' | ' +
            moment(timestamp).format('HH:mm');
        }
        // let timestamp =
        //   moment(timestamp).format('dddd DD.MM.YYYY') +
        //   '|' +
        //   moment(timestamp).format('HH:mm');
        return `${output}<br>${this.series.name}: ${pointOrComma(this.y, language)} ${unit}<br>`;
      };
    }

    const chartOptions = highchartsOptionWeatherstation(
      parameterNumber,
      language,
      apiParameter,
      unit,
      arrayKey,
      formatDates,
      filteredData,
      formatTooltip,
      parameter,
      getApiIndex(emitedApiendpoint),
      navItemTawes,
      data,
    );
    return chartOptions;
  };

  return (
    <>
      {station && pointInformation?.data ? (
        <div className={styles.marginSettings} ref={refScrollto}>
          <div className={styles.headerMeassurement}>
            <h2>
              {language == 'de' ? 'Messstation:' : 'Observation point:'}{' '}
              {mappedPoint.name}
            </h2>
          </div>
          <TawesHighchartsHeader
            language={language}
            parameter={parameter}
            parameterNumber={parameterNumber}
            station={station}
            mappedPoint={mappedPoint}
          />

          {(data.features && data.features.length > 0) || !error ? (
            <div>
              {loaded ? (
                <HighchartsReact
                  ref={chartContainerRef}
                  highcharts={Highcharts}
                  options={options}
                />
              ) : (
                <p>Loading</p>
              )}
            </div>
          ) : (
            <div>
              {loading && <p>Loading</p>}
              {error && (
                <p>
                  {language == 'de'
                    ? 'Derzeit sind keine detaillierten Daten für diese Station verfügbar'
                    : 'Currently no detailed data for that station is available'}
                </p>
              )}
            </div>
          )}
          <TawesHighchartsNavbar
            onApiEmit={handleApiEmit}
            emitedApiendpoint={emitedApiendpoint}
            loading={loading}
          />
        </div>
      ) : (
        <InformationStatus language={language} outputtext="" />
      )}
    </>
  );
};

export default TawesHighcharts;
