import React, { useRef, useState, memo } from 'react';

import cx from 'classnames';
import debounce from 'lodash/debounce';
import { navItemTawes } from '../../../constants/TawesHelper';

const ControlPanelSubTawes = memo(
  ({ day = 0, onButtonClick, tableActive = false, language }) => {
    const redRadioRef = useRef(null);

    const focusRedRadio = () => {
      if (redRadioRef.current) {
        redRadioRef.current.focus();
      }
    };

    const [state, setState] = useState({
      showParameters: false,
      selectedDay: day,
    });

    const changeSelectedDay = (e, s) => {
      onButtonClick(s);
      setState((prevState) => ({
        ...prevState,
        selectedDay: Number(s),
        showParameters: false,
      }));
    };

    const debouncedHandleClick = debounce((e, index) => {
      changeSelectedDay(e, index);
    }, 300);

    const eventKeyDown = (event, value) => {
      if (event.key === 'Escape') {
        toggleShowParameters();
      } else if (event.key === 'Enter' || event.key === ' ') {
        debouncedHandleClick(event, value);
      }
    };

    const toggleShowParameters = () => {
      setState((prevState) => ({
        ...prevState,
        showParameters: !prevState.showParameters,
      }));
    };

    return (
      <>
        <div
          className={cx('tawes-container', {
            'tawes-container-row': tableActive == 'table',
          })}
        >
          <div
            // role="button"
            className="selected-parameter"
            // onClick={toggleShowParameters}
            // tabIndex={0}
          >
            <span>
              {language == 'de'
                ? navItemTawes.find((i) => i.key === state.selectedDay).name
                : navItemTawes.find((i) => i.key === state.selectedDay).nameEn}
            </span>

            <button
              className="hamburger-button"
              onClick={toggleShowParameters}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  toggleShowParameters();
                }
              }}
              aria-expanded={state.showParameters}
              aria-controls="navbar"
              aria-label={
                state.showParameters
                  ? 'Schließt Navigation Menü'
                  : 'Öffnet Navigation Menü'
              }
            >
              &#9776; {/* Hamburger icon */}
            </button>
          </div>

          <div
            className={cx(
              'parameter-list',
              { show: state.showParameters == true },
              { 'parameter-list-row': tableActive == 'table' },
            )}
          >
            {navItemTawes.map((selectableDay, index) => (
              <label
                role="radio"
                tabIndex={0}
                aria-checked={index === state.selectedDay}
                key={selectableDay.name}
                onKeyDown={(e) => eventKeyDown(e, index)}
                className={cx({
                  'tawesparameter-row': tableActive == 'table',
                  tawesparameter: tableActive == 'map',
                })}
              >
                <input
                  tabIndex={-1}
                  type="radio"
                  name="parameter"
                  value={selectableDay.name}
                  checked={index === state.selectedDay}
                  onChange={(e) => debouncedHandleClick(e, index)}
                />
                {language == 'de' ? selectableDay.name : selectableDay.nameEn}
              </label>
            ))}
          </div>
        </div>
      </>
    );
  },
);

export default ControlPanelSubTawes;
