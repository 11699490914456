import moment from 'moment';
import 'moment/locale/de'; // Import the German locale
export const isToday = (dateString) => {
  // const dateString = "2024-07-31";
  const inputDate = moment(dateString, 'YYYY-MM-DD');
  const todayDate = moment().startOf('day'); // Get today's date at midnight

  const isSameDate = inputDate.isSame(todayDate, 'day');
  return isSameDate;
};
