import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { getGeospheredataplatformdata, setLanguage } from '../../../actions';

const isDataStale = (data) => {
  if (!data?.data) return true;
  const isOlderThan5Minutes = moment
    .utc(data.timereference)
    .isBefore(moment.utc().subtract(5, 'minutes'));
  return isOlderThan5Minutes;
};

const useGeoSphereMapViewData = (memoizedData, warnstatus, markerdata) => {
  const dispatch = useDispatch();
  const fetchData = (serviceaddress, servicename) => {
    dispatch(getGeospheredataplatformdata(serviceaddress, servicename));
  };
  useEffect(() => {
    let intervalId = null;
    const setIntervalFkt = () => {
      intervalId = setInterval(() => {
        if (memoizedData?.servicename) {
          fetchData(memoizedData.serviceaddress, memoizedData.servicename);
        }

        if (memoizedData?.warnstatus) {
          fetchData(memoizedData.warnaddress, memoizedData.warnstatus);
          fetchData(memoizedData.warnaddress, 'getGewitterAuto');
        }
        if (memoizedData?.textsforecast)
          dispatch(
            getGeospheredataplatformdata(
              memoizedData.serviceaddress,
              memoizedData.textsforecast,
            ),
          );
      }, 300000); // 5 minutes in milliseconds
    };

    const path = window.location.pathname;
    if (path.includes('/de')) {
      dispatch(setLanguage('de'));
    } else if (path.includes('/en')) {
      dispatch(setLanguage('en'));
    }

    if (
      memoizedData?.warnstatus &&
      (!warnstatus?.data || isDataStale(warnstatus))
    ) {
      fetchData(memoizedData.warnaddress, memoizedData.warnstatus);
      fetchData(memoizedData.warnaddress, 'getGewitterAuto');
    }

    if (
      memoizedData?.servicename &&
      (!markerdata?.data || isDataStale(markerdata))
    ) {
      dispatch({
        type: 'SET_CLICK_MARKER_TABLE_INTERACTION',
        payload: null,
        owner: null,
      });
      fetchData(memoizedData.serviceaddress, memoizedData.servicename);

      if (memoizedData?.forecastPoints) {
        fetchData(memoizedData.serviceaddress, memoizedData.forecastPoints);
      }
      if (memoizedData?.textsforecast)
        dispatch(
          getGeospheredataplatformdata(
            memoizedData.serviceaddress,
            memoizedData.textsforecast,
          ),
        );

      dispatch({ type: 'SET_CLICK_X_AXIS', payload: false });

      if (memoizedData.variation === 'beben' && memoizedData.shakemap) {
        dispatch({ type: 'RESET', serviceName: memoizedData.shakemap });
      }
    }
    if (!intervalId) {
      setIntervalFkt();
    }

    const h1Title = document.getElementsByClassName('documentFirstHeading');
    if (h1Title.length > 0) {
      const takeTheHigheIndex = h1Title.length - 1;
      h1Title[takeTheHigheIndex].classList.add('documentFirstHeadingRed');
    }

    return () => {
      dispatch({
        type: 'CHART_COMPONENT',
        serviceName: memoizedData.variation + 'CHART',
        flag: false,
      });
      clearInterval(intervalId);
    };
  }, [memoizedData.variation]);
};

export default useGeoSphereMapViewData;
