import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  content: {
    id: 'Content',
    defaultMessage: 'Content',
  },
  boxColor: {
    id: 'boxColor',
    defaultMessage: 'Box Color',
  },
});

export const InfoBoxSchema = (config) => {
  const { intl } = config;

  const colors = [
    { name: 'lime', label: 'Lime' },
    { name: 'red', label: 'Red' },
  ];

  return {
    title: 'infoBox',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'content', 'boxColor'],
      },
    ],
    properties: {
      title: {
        title: intl.formatMessage(messages.title),
        type: 'string',
      },
      content: {
        title: intl.formatMessage(messages.content),
        widget: 'slate_richtext',
      },
      boxColor: {
        widget: 'color_picker',
        title: intl.formatMessage(messages.boxColor),
        colors,
      },
    },
    required: ['content'],
  }
};
