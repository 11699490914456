const WeatherForecastHelperSvgNight = {};

// Dynamically import all weatherforecastImages from the specified folder
const imageContextNight = require.context(
  '../icons/weatherforecast/Nacht',
  false,
  /\.(png|jpe?g|svg)$/,
);
imageContextNight.keys().forEach((imagePath, index) => {
  const imageName = imagePath.split('/').pop().split('.')[0];
  const importedImage = imageContextNight(imagePath);
  WeatherForecastHelperSvgNight[imageName] = importedImage;
});

// console.log('All images:', WeatherForecastHelperSvgNight); // Debugging statement

export default WeatherForecastHelperSvgNight;
