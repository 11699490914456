import React, { useState, useCallback } from 'react';
import styles from './TawesHighchartsNavbar.module.css';
// import ButtonNoHtmlatt from '../ui/ButtonNoHtmlatt';
import NavMenu from './NavMenu';
import { useSelector } from 'react-redux';
function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}
const navItems = [
  {
    name: '10 Minuten Werte',
    api: 'tawes-v1-10min',
    shortName: '10 Minuten',
    shortShort: '10 Min',
  },
  // { name: '10Min', api: 'klima-v2-10min' },
  {
    name: 'Stundenwerte',
    api: 'klima-v2-1h',
    shortName: 'Stunden',
    shortShort: 'Stunden',
  },
  {
    name: 'Tageswerte',
    api: 'klima-v2-1d',
    shortName: 'Tage',
    shortShort: 'Tage',
  },
  {
    name: 'Monatswerte',
    api: 'klima-v2-1m',
    shortName: 'Monate',
    shortShort: 'Monat',
  },
];
const navItemsEn = [
  {
    name: '10-minute values',
    api: 'tawes-v1-10min',
    shortName: '10-minute ',
    shortShort: '10 Min',
  },
  // { name: '10Min', api: 'klima-v2-10min' },
  {
    name: 'Hourly values',
    api: 'klima-v2-1h',
    shortName: 'Hourly',
    shortShort: 'Hourly',
  },
  {
    name: 'Daily values',
    api: 'klima-v2-1d',
    shortName: 'Daily',
    shortShort: 'Daily',
  },
  {
    name: 'Monthly values',
    api: 'klima-v2-1m',
    shortName: 'Monthly',
    shortShort: 'Mon',
  },
];

const TawesHighchartsNavbar = ({ onApiEmit, emitedApiendpoint, loading }) => {
  const language = useSelector(
    (state) => state.geospheredataplatformdata['SET_LANGUAGE'],
  );
  const [clickItem, setClickItem] = useState(0);

  const clickEvent = (item, index) => {
    onApiEmit(item);

    setClickItem(index);
  };
  const debouncedHandleClick = useCallback(
    debounce((e, index) => {
      // console.log(index);
      clickEvent(e, index);
    }, 100),
    [clickEvent],
  );

  return (
    <nav className={styles.tawesnavbar}>
      {/* Hamburger menu button (placeholder) */}
      <NavMenu
        navItems={language == 'de' ? navItems : navItemsEn}
        clickItem={clickItem}
        debouncedHandleClick={debouncedHandleClick}
        styles={styles}
      />
    </nav>
  );
};

export default TawesHighchartsNavbar;
