import { defineMessages } from 'react-intl';

const messages = defineMessages({
  LinkBlock: {
    id: 'Link Block',
    defaultMessage: 'Link',
  },
  LinkTitle: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  LinkLink: {
    id: 'Link',
    defaultMessage: 'Link',
  },
  openLinkInNewTab: {
    id: 'Open in a new tab',
    defaultMessage: 'Open in a new tab',
  },
});

export const LinkSchema = (props) => {
  // https://6.docs.plone.org/volto/blocks/editcomponent.html  // -> ObjectBrowserWidget
  // https://training.plone.org/voltohandson/blocksdownload.html // --> Download Example
  // http://localhost:8080/Plone/portal_types/manage_main // View Portal types
  const { intl } = props;

  return {
    title: intl.formatMessage(messages.LinkBlock),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'href', 'openLinkInNewTab'],
      },
    ],
    properties: {
      title: {
        title: props.intl.formatMessage(messages.LinkTitle),
      },
      href: {
        title: props.intl.formatMessage(messages.LinkLink),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description', 'path', 'portal_type'],
        allowExternals: true,
        // selectableTypes: ["News Item"]
      },
      openLinkInNewTab: {
        title: intl.formatMessage(messages.openLinkInNewTab),
        type: 'boolean',
      },
    },
    required: ['title', 'href'],
  };
};
