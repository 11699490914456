// TextWithBorder.tsx
import React from 'react';
import styles from './ExternalLink.module.scss';
import { Icon } from '@plone/volto/components';
import Link_Extern_White_16px from '../../../icons/austria/Link_Extern_White_16px.svg';

interface TextWithBorderProps {
  text: string;
  url: string;
  //   imageUrl: string; // or React.ReactNode if you prefer to pass a React element
}

const ExternalLink: React.FC<TextWithBorderProps> = ({ url, text }) => {
  const onClick = () => {
    window.open(url, '_blank');
  };
  return (
    <a
      href={url}
      target="_blank" // Optional: Opens in a new tab
      rel="noopener noreferrer" // Recommended for security when using target="_blank"
      className={styles.textWithBorder}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onClick();
        }
      }}
      // role="link"
      tabIndex={0}
      aria-label={text}
    >
      <span>{text}</span>
      <div className={styles.svgIconBackground}>
        <Icon
          name={Link_Extern_White_16px}
          // name={exclamation}
          size="16px"
          className={styles.svg_icon}
          title={text}
          color="black"
        />
      </div>
    </a>
  );
};

export default ExternalLink;
