import React, { useState, useEffect, useCallback, useMemo } from 'react';

import cx from 'classnames';
import moment from 'moment';
import 'moment/locale/de'; // Import the German locale

import VariationNavButtons from '../Components/Shared/VariationNavButtons';
import ControlPanels from '../Components/ControlPanels';

import Skeleton from '../Components/Shared/Skeleton';

import SimpleMap from '../Components/Shared/SimpleMap';

import TawesLegend from '../Components/Messstation/TawesLegend';
import TawesMarker from '../Components/Messstation/TawesMarker';
import TawesTabelle from '../Components/Messstation/TawesTabelle';
import TawesHighcharts from '../Components/Messstation/TawesHighcharts';
import ExternalLinkButtons from '../Components/Shared/ExternalLinkButtons';
import Hitliste from '../Components/Messstation/Hitliste';

moment.locale('de');

const TawesPage = ({
  markerdata,
  chartmode,
  // selectedDropdownElement,
  mapClick,
  FsetTableMode,
  language,
  stylesHashInfo,
  memoizedData,
  combinedData,
  handleClick,
  forecastTime,
  dayClick,
  selectedDropdownElement,
  clickElement,
  handleResetFromChart,
  autIcon,
}) => {
  return (
    <div className="block weatherforecastMain g" id="geosphereMaps">
      {/* Karte */}

      {!(chartmode && chartmode.active) && markerdata && markerdata.data && (
        <div
          className={cx('block', 'geosphere_interactive_map mt-bottom')}
          id="geosphereMapsMap"
          style={{
            display: stylesHashInfo !== 'geosphereTexts' ? 'grid' : 'none',
          }}
        >
          {/* vorhersageButton karte / tabelle */}
          {/* Navbuttons */}

          <VariationNavButtons
            data={memoizedData}
            language={language}
            markerdata={markerdata}
            mapClick={mapClick}
            FsetTableMode={FsetTableMode}
          />
          {mapClick != 'hitliste' && (
            <ControlPanels
              markerdata={markerdata}
              data={memoizedData}
              onButtonClick={handleClick}
              isToday={forecastTime}
              numberOfDays={memoizedData.forcastdays}
              parameter={dayClick}
              tableActive={mapClick}
              language={language}
            />
          )}

          {/* tawesLegend */}
          {memoizedData.variation === 'tawes' && mapClick === 'map' && (
            <TawesLegend
              parameterValue={dayClick}
              type="tawes"
              language={language}
              markerdata={markerdata.data}
            />
          )}

          {mapClick === 'map' &&
            memoizedData &&
            memoizedData.variation != 'weatherforecast' &&
            markerdata?.data && (
              <SimpleMap
                data={memoizedData}
                day={dayClick}
                stateAut={selectedDropdownElement}
                markerdata={combinedData} //added combinedData here
                // tawesSubmenu={tawesSubmenuClick}
              >
                {memoizedData.variation === 'tawes' &&
                  memoizedData &&
                  markerdata?.data?.features && (
                    <TawesMarker
                      language={language}
                      data={markerdata.data.features}
                      parameterNumber={dayClick}
                      pointsState={memoizedData.forecastPoints}
                      // tawesSubmenu={tawesSubmenu}
                    />
                  )}
              </SimpleMap>
            )}

          {/* Tawes tabelle */}
          {memoizedData.variation === 'tawes' &&
            markerdata?.data &&
            mapClick === 'table' && (
              <TawesTabelle
                markerdata={markerdata.data}
                data={memoizedData}
                pointsState={memoizedData.forecastPoints}
                filteredElement={dayClick} // dayclick == 0:Tempteratur, 1:Niederschlag
              />
            )}
          {/* Tawes Hitliste */}
          {memoizedData.variation === 'tawes' &&
            markerdata?.data &&
            mapClick === 'hitliste' && <Hitliste data={memoizedData} />}
        </div>
      )}

      {markerdata == null && memoizedData.variation !== 'warning' ? (
        <Skeleton />
      ) : null}
      {/* taweshighcharts */}
      {memoizedData.variation === 'tawes' &&
        markerdata &&
        markerdata?.data &&
        clickElement?.owner == 'tawes' && (
          <TawesHighcharts
            station={clickElement.data}
            language={language}
            pointsState={memoizedData.forecastPoints}
            parameterNumber={dayClick}
          />
        )}
      {/* external link information */}
      {memoizedData && language && markerdata && markerdata?.data && (
        <ExternalLinkButtons
          // chartmode={chartModeNot ? true : false}
          variation={memoizedData.variation}
          language={language}
          handleResetFromChart={handleResetFromChart}
          autIcon={autIcon}
        />
      )}
    </div>
  );
};
export default TawesPage;
