/**
 * EventView view component override.
 * @module components/theme/View/EventView
 */

import { Button } from 'semantic-ui-react';
import { Container as SemanticContainer } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import { injectIntl } from 'react-intl';
import { hasBlocksData, flattenHTMLToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import PropTypes from 'prop-types';
import React from 'react';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { UniversalLink } from '@plone/volto/components';
import { eventViewMessages } from '../../../../../i18nl10n';

const EventTextfieldView = ({ content }) => {
  const Image = config.getComponent({ name: 'Image' }).component;
  return (
    <React.Fragment>
      {content.title && (
        <h1 className="documentFirstHeading">{content.title}</h1>
      )}
      {content.description && (
        <p className="documentDescription">{content.description}</p>
      )}
      {content.preview_image && (
        <Image
          className="document-image ui right floated image"
          item={content}
          imageField="preview_image"
          alt=""
        />
      )}
      {content.text && (
        <div
          dangerouslySetInnerHTML={{
            __html: flattenHTMLToAppURL(content.text.data),
          }}
        />
      )}
    </React.Fragment>
  );
};

const EventOverviewButton = ({ content }) => {
  const intl = useIntl();

  return (
    <div className="block __button">
      <div className="button container event-overview-link">
        <div className="align right">
          <UniversalLink href={content?.parent['@id']} openLinkInNewTab={false}>
            <Button className="ui button">
              {intl.formatMessage(eventViewMessages.eventOverview)}
            </Button>
          </UniversalLink>
        </div>
      </div>
    </div>
  );
};

/**
 * EventView view component class.
 * @function EventView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const EventView = (props) => {
  const { content } = props;
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;

  return (
    <Container id="page-document" className="view-wrapper event-view">
      <div className="top-overview-button">
        <EventOverviewButton {...props} />
      </div>
      {hasBlocksData(content) ? (
        <>
          <RenderBlocks {...props} />
        </>
      ) : (
        <EventTextfieldView {...props} />
      )}
      <EventOverviewButton {...props} />
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
EventView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
    contact_email: PropTypes.string,
    contact_name: PropTypes.string,
    contact_phone: PropTypes.string,
    end: PropTypes.string.isRequired,
    event_url: PropTypes.string,
    location: PropTypes.string,
    open_end: PropTypes.bool,
    start: PropTypes.string.isRequired,
    subjects: PropTypes.arrayOf(PropTypes.string).isRequired,
    whole_day: PropTypes.bool,
  }).isRequired,
};
export default injectIntl(EventView);
