import { Icon } from '@plone/volto/components';

import React from 'react';
import style from './WeatherforecastChartWarnWidget.module.css';
import Map_Warning_White from '../../../icons/austria/Map_Warning_White_16px.svg';
import Link_Extern_White_16px from '../../../icons/austria/Link_Extern_White_16px.svg';

import ButtonWithSVG from '../ui/ButtonWithSVG';
import moment from 'moment';
import { warnstufeToText } from '../../utils/warnstufeToText';
moment.locale('de');
const WeatherforecastChartWarnWidget = ({ warning, language }) => {
  const handleClick = () => {
    window.open('https://warnungen.zamg.at/wsapp/de/alle/', '_blank');
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Space') {
      handleClick();
      event.preventDefault();
    }
  };

  const getLightBackgroundColor = () => {
    if (warning) {
      if (warning === 1) {
        return 'yellow_light';
      } else if (warning === 2) {
        return 'orange_light';
      } else if (warning === 3) {
        return 'red_light';
      }
    }
    return 'lightgray'; // Default background color
  };
  const getBackgroundColor = () => {
    if (warning) {
      const warnstufeid = warning;
      if (warnstufeid === 1) {
        return 'yellow';
      } else if (warnstufeid === 2) {
        return 'orange';
      } else if (warnstufeid === 3) {
        return 'red';
      }
      // return 'warn';
    }
    return 'lightgray'; // Default background color
  };
  const getIconBackgroundColor = () => {
    if (warning) {
      const warnstufeid = warning;
      if (warnstufeid === 1) {
        return '#ffdb23';
      } else if (warnstufeid === 2) {
        return '#ff9500';
      } else if (warnstufeid === 3) {
        return '#ff0100';
      }
      // return 'warn';
    }
    // return 'lightgray'; // Default background color
  };

  const getWarnstufe = getLightBackgroundColor();
  const getWarnstufeIcon = getBackgroundColor();
  const getWarnIconBackgroundColor = getIconBackgroundColor();
  return (
    warning && (
      <div className={`${style.widgetContainer} ${style[getWarnstufe]}`}>
        <div className={`${style.icon} ${style[getWarnstufeIcon]}`}>
          <Icon
            name={Map_Warning_White}
            // name={exclamation}
            size="80px"
            className="svg_icon"
            // title={getBackgroundColor() + ' Warnung'}
            title={`Achtung: Warnstufe ${warnstufeToText(warning)}`}
          />
        </div>
        <div className={style.content}>
          <div className={style.contentWarntext}>
            <p
              className={style.header}
              title={`Achtung: Warnstufe ${warnstufeToText(warning)}`}
            >
              Wetterwarnung
            </p>
            {/* <p>{warn[0].properties.auswirkungen}</p> */}
            <div className={style.parentWarnwidgetStuff}>
              {warning ? (
                <div>
                  {warning && (
                    <p
                      className={style.pTagText}
                      title={`Achtung: Warnstufe ${warnstufeToText(warning)}`}
                    >
                      {language === 'de'
                        ? 'Heute ist mindestens eine Warnung aktiv'
                        : 'At least one warning is active today'}
                    </p>
                  )}
                </div>
              ) : (
                <p className={style.pTagText}>
                  Derzeit gibt es für diesen Ort keine Wetterwarnung
                </p>
              )}
              <p className={` ${style.pTagMarginTop} ${style.pTagText}`}>
                {language == 'de'
                  ? 'Mehr zu dieser Warnung erfahren sie'
                  : 'More about this warning'}{' '}
                <span
                  role="button"
                  tabIndex="0"
                  onClick={handleClick}
                  // className={`${style[getWarnstufeIcon]}`}
                  onKeyDown={handleKeyDown}
                >
                  {' '}
                  {language === 'de' ? 'hier' : 'here'}
                </span>
              </p>
            </div>
          </div>
          <div role="button" className={`${style['divButton']}`}>
            <ButtonWithSVG
              // onClick={handleClick}
              onKeyDown={handleKeyDown}
              onClick={handleClick}
              label=""
              width="60px"
              height="60px"
              // backgroundColor="#a92a4e"
              backgroundColor={getWarnIconBackgroundColor}
              // className={`${style[getIconBackgroundColor]}`}
              color="white"
            >
              <Icon
                name={Link_Extern_White_16px}
                // name={exclamation}
                size="16px"
                className="svg_icon"
                title={getBackgroundColor() + ' Warnung'}
              />
            </ButtonWithSVG>
          </div>
        </div>
      </div>
    )
  );
};

export default WeatherforecastChartWarnWidget;
