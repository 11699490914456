export const pointOrComma = (num, language = 'de', beben = false) => {
  if (num == null) return;
  if (language == 'en') return num;
  if (Number.isInteger(num) && !beben) {
    return num.toString(); // No formatting needed for whole numbers
  } else if (Number.isInteger(num) && beben) {
    return num.toFixed(1).toString().replace('.', ',');
  } else {
    return num.toString().replace('.', ','); // Replace dot with comma for decimal numbers
  }
};
