import { useIntl } from "react-intl";
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Column: {
    id: 'Column',
    defaultMessage: 'Column',
  },
  LinkTitle: {
    id: 'LinkTitle',
    defaultMessage: 'Link Title',
  },
  LinkTo: {
    id: 'Link to',
    defaultMessage: 'Link to',
  },
});

const LinkListingColumnSchema = (props) =>  {
    const intl = useIntl();
    return {
        title: intl.formatMessage(messages.Column),
        fieldsets: [
          {
            id: 'default',
            title: 'Default',
            fields: ['link_title', 'link'],
          },
        ],
        properties: {
          link_title: {
            title: intl.formatMessage(messages.LinkTitle),
          },
          link: {
            title: intl.formatMessage(messages.LinkTo),
            widget: 'object_browser',
            mode: 'link',
            selectedItemAttrs: ['Title', 'Description', 'path', 'portal_type'],
            allowExternals: true,
          }
        },
        required: ['link_title', 'link'],
    }
}

export default LinkListingColumnSchema;