import React from 'react';
import { Icon } from '@plone/volto/components';
import cx from 'classnames';
import WeatherforecastTableWarnIcon from './WeatherforecastTableWarnIcon';
import styles from './WeatherforecastTable.module.css';
import WeatherForecastHelperSvgDay from '../../../constants/WeatherForecastHelperSvgDay';
import {
  mappingSymbToText,
  mappingSymbToTextEnglisch,
} from '../../../constants/WeatherforecastHelper';

const getDays = () => {
  const days = ['Heute', 'Morgen'];

  for (let i = 2; i < 8; i++) {
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + i);
    days.push(
      nextDay.toLocaleDateString('de-DE', {
        weekday: 'long',
        locales: 'de-DE',
      }),
    ); // en-US
  }
  return days;
};
const WeatherTableRow = ({
  station,
  handleRowClick,
  handelKeyDown,
  language,
}) => {
  const days = getDays();
  return (
    <tr
      role="button"
      tabIndex={0}
      onClick={() => handleRowClick(station)}
      onKeyDown={(e) => handelKeyDown(station)}
      aria-label={`Link zur Detailprognose für ${station.properties.name} `}
    >
      <td aria-label={`Vorhersageort:${station.properties.name}`}>
        {station.properties.name}
      </td>
      {station.properties.forecast.map((temperature, temperatureIndex) => (
        <td
          key={temperatureIndex}
          aria-label={`${days[temperatureIndex]}, Temperatur: ${Math.round(temperature.high)} bis  ${Math.round(temperature.low)} Grad. Warnstufe:${temperature.warning}.Wetter: ${mappingSymbToText[temperature.symbol]}`}
          className={cx({
            [styles.paddingSmall]: temperature.warning,
          })}
        >
          <div className={styles.iconTemperatur}>
            <WeatherforecastTableWarnIcon warning={temperature.warning} />
            {temperature.symbol ? (
              <Icon
                name={WeatherForecastHelperSvgDay[temperature.symbol]}
                size="32px"
                className={styles.svg_icon}
                title={
                  language === 'de'
                    ? mappingSymbToText[temperature.symbol]
                    : mappingSymbToTextEnglisch[temperature.symbol]
                }
              />
            ) : null}
            <div className={styles.weatherforecastTableTemp}>
              {Math.round(temperature.high)}°{' '}
              <div className={styles.weatherforecastTableBorderColor}>|</div>{' '}
              {Math.round(temperature.low)}°
            </div>
          </div>
        </td>
      ))}
    </tr>
  );
};

export default WeatherTableRow;
