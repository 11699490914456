import React, { useEffect, useState } from 'react';
import { Icon } from '@plone/volto/components';
import closeIcon from '../../../icons/Close_circle_white.svg';
import { toPublicURL } from '@plone/volto/helpers';
import { useIntl } from 'react-intl';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { hasBlocksData, flattenHTMLToAppURL } from '@plone/volto/helpers';
import { Container } from '@plone/components';
import config from '@plone/volto/registry';

const WarningBlock = () => {
  const [isClosed, setIsClosed] = useState(undefined);

  // content blocks
  const [data, setData] = useState([]); // State to hold blocks
  const [loading, setLoading] = useState(true); // State for loading indicator
  const [error, setError] = useState(null); // State for error handling
  const intl = useIntl();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(toPublicURL(`/++api++/${intl.locale}/warning`), {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        setData(data || {});
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [intl.locale]);

  // end content blocks

  useEffect(() => {
    if (data.review_state === undefined) {
        // initial before data fetching
        return;
    } else if (data.review_state === 'published') {
      // Check localStorage for the closed state
      const blockClosed = localStorage.getItem('warningBlock') === 'true';
      setIsClosed(blockClosed);
    } else {
      // If unpublished, reset the isClosed state and clear localStorage
      setIsClosed(false);
      localStorage.removeItem('warningBlock');
    }
  }, [data.review_state]);  // Runs after data is fetched and review_state is available


  const handleClose = () => {
    setIsClosed(true);
    localStorage.setItem(`warningBlock`, 'true'); // Save the state in localStorage. add id
  };

const blockClassName = isClosed === undefined ? 'warning hidden' : (isClosed ? 'warning closed' : 'warning');

  return data.review_state === 'published' && (
    <Container className={blockClassName} role="banner">
      {!isClosed && (
        <div className="warning-wrapper fatal">
          <div className="warning-image">
            <img src={data.preview_image?.download || ''}></img>
          </div>
          <div className="warning-content">
            {hasBlocksData(data) && (
                <>
                <RenderBlocks
                    content={data}
                    blocksConfig={config.blocks.blocksConfig} />
                </>
            )}
          </div>

          <button
            className="close"
            onClick={handleClose}
          >
            <Icon name={closeIcon} size="24px" />
          </button>
        </div>
      )}

      </Container>
  );
};

export default WarningBlock;

