/**
 * Bookmark reducers
 */

import { GET_GEOSPHEREDATAPLATFORM_DATA } from '../constants/ActionTypes';
import moment from 'moment';
// import { FILTER_ITEMS } from '../constants/ActionTypes';

const initialState = {};
const mergeArrays = (array1, array2) => {
  if (!(array1?.data?.features && array2?.data)) return;
  return array1.data.features.map((item1) => {
    // Find matching item in array2 based on point_id
    const matchingItem = array2.data.find(
      (item2) => item2.point_id === item1.properties.point_id,
    );

    if (matchingItem) {
      return {
        ...item1, // Keep all the existing properties of item1
        properties: {
          ...item1.properties, // Keep all existing properties of array1's properties
          ...matchingItem, // Merge in the matching item from array2
        },
      };
    }

    return item1;
  });
};

export default function myreducer(state = initialState, action = {}) {
  switch (action.type) {
    // list of bookmarks
    case 'SET_LANGUAGE':
      return {
        ...state,
        SET_LANGUAGE: action.payload,
      };
    case 'SET_INITIAL_HASH':
      return { ...state, initialHash: action.payload };
    case 'SET_CLICK_MARKER_TABLE_INTERACTION':
      return {
        ...state,
        SET_CLICK_MARKER_TABLE_INTERACTION: {
          data: action.payload,
          owner: action.payload?.owner,
        },
      };

    // highcharts click in chart
    case 'SET_CLICK_X_AXIS':
      return { ...state, clickXAxis: action.payload };
    case 'RESET':
      return {
        ...state,
        [action.serviceName]: null,
      };
    case 'CHART_COMPONENT':
      const flag = action.flag ? action.flag : false;
      return {
        ...state,
        [action.serviceName]: {
          active: flag,
          latlon: action.latlon,
          title: action.title,
          type: action.serviceName,
          point_id: action.point_id,
        },
      };

    case 'SET_ZOOM_LEVEL':
      return {
        ...state,
        SET_ZOOM_LEVEL: filterGeoJSONByZoomLevel(
          state[action.payload.serviceName],
          action.payload,
        ),
      };

    case `${GET_GEOSPHEREDATAPLATFORM_DATA}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_GEOSPHEREDATAPLATFORM_DATA}_SUCCESS`:
      const newState = {
        ...state,
        [action.serviceName]: {
          error: null,
          loaded: true,
          loading: false,
          data: action.result,
          timereference: moment().toISOString(),
        },
      };

      // Combine if both service1 and service2 have been loaded
      if (
        newState['forecasts/daily'] &&
        newState['forecasts/daily'].data != false &&
        newState['forecasts/points'] &&
        newState['forecasts/points'].data != false
      ) {
        const features = mergeArrays(
          newState['forecasts/daily'],
          newState['forecasts/points'],
        );
        newState['forecasts/daily'].data.features = features;
        newState.combinedData = newState['forecasts/daily'];

        newState.combinedData.data.features =
          newState.combinedData.data.features.sort((a, b) => {
            // First, compare by zoom
            const zoomDifference =
              a.properties.priority - b.properties.priority;

            // If zoom is the same, compare by state_id
            if (zoomDifference === 0) {
              return b.properties.state_id - a.properties.state_id;
            }

            // Otherwise, use the zoom difference
            return zoomDifference;
          });
      }

      // newTimestamps and data manipulation are needed for the chart and widget
      // only when state changes manipulate timestamp and data
      if (newState['forecasts/flexi']?.data) {
        const { rr, sy } =
          newState['forecasts/flexi'].data.features[0].properties.parameters;
        //kann passieren dass state false ist
        const currentState =
          state['forecasts/flexi']?.data == false
            ? null
            : state['forecasts/flexi']?.data?.features[0]?.properties
                ?.parameters; //
        // Compare incoming data with the current state
        const isDataUnchanged =
          JSON.stringify(rr.data) === JSON.stringify(currentState?.rr?.data) &&
          JSON.stringify(sy.data) === JSON.stringify(currentState?.sy?.data);

        if (isDataUnchanged) {
          return newState; // No changes, return the current state
        }

        newState['forecasts/flexi'].data.newtimestamps = newState[
          'forecasts/flexi'
        ].data.timestamps.map(
          (item, id) => new Date(item).getTime(), // Convert timestamp to milliseconds
        );
        // rr wird manipuliert! f.e. [null, 1, 4, 10, 11]; to [1, 4, 10, 11, null]
        rr.data = rr.data.slice(1).concat(null);
        sy.data = sy.data.slice(1).concat(null);
      }
      return newState;

    case `${GET_GEOSPHEREDATAPLATFORM_DATA}_FAIL`:
      return {
        ...state,
        [action.serviceName]: {
          // error: null,
          loaded: true,
          loading: false,
          data: false,
          errorStatus: action.error.statusCode,
          error: action.error,
          // filteredData: filteredData
        },
      };
    default:
      return state;
  }
}
const filterGeoJSONByZoomLevel = (data, payload) => {
  // Implement your filtering logic based on the zoom level

  if (data && typeof payload != 'undefined' && data?.data?.features) {
    const { bounds, zoomlevel } = payload;
    if (Object.keys(bounds).length > 0 || bounds === 'ignoreFlag') {
      let boundsMap = [];
      let filtered;
      if (bounds !== 'ignoreFlag') {
        boundsMap = [
          bounds['_sw']['lat'],
          bounds['_ne']['lat'],
          bounds['_sw']['lng'],
          bounds['_ne']['lng'],
        ];
        filtered = data.data.features.filter((feature) => {
          return (
            feature.geometry.coordinates[0] >= boundsMap[2] &&
            feature.geometry.coordinates[0] <= boundsMap[3] &&
            feature.geometry.coordinates[1] >= boundsMap[0] &&
            feature.geometry.coordinates[1] <= boundsMap[1]
          );
        });
      } else filtered = data.data.features.map((i) => i);

      if (filtered[0].properties.bzNummer) {
        if (zoomlevel < 6.1) {
          filtered = filtered.filter((i) => i.properties.zoom < 1);
        } else if (zoomlevel < 6.24) {
          filtered = filtered.filter((i) => i.properties.zoom <= 1);
        } else if (zoomlevel < 6.4) {
          filtered = filtered.filter((i) => i.properties.zoom <= 2);
        } else if (zoomlevel < 6.7) {
          filtered = filtered.filter((i) => i.properties.zoom <= 3);
        } else if (zoomlevel < 6.9) {
          filtered = filtered.filter((i) => i.properties.zoom <= 4); // new level
        } else if (zoomlevel < 7.15) {
          filtered = filtered.filter((i) => i.properties.zoom <= 5);
        } else if (zoomlevel < 7.3) {
          filtered = filtered.filter((i) => i.properties.zoom <= 6);
        } else if (zoomlevel < 7.7) {
          filtered = filtered.filter((i) => i.properties.zoom <= 7);
        } else if (zoomlevel < 8) {
          filtered = filtered.filter((i) => i.properties.zoom <= 8);
        } else if (zoomlevel < 8.2) {
          filtered = filtered.filter((i) => i.properties.zoom <= 9);
        } else if (zoomlevel < 8.4) {
          filtered = filtered.filter((i) => i.properties.zoom <= 10);
        } else if (zoomlevel < 8.6) {
          filtered = filtered.filter((i) => i.properties.zoom <= 11);
        } else if (zoomlevel < 8.8) {
          filtered = filtered.filter((i) => i.properties.zoom <= 12);
        } else if (zoomlevel < 9) {
          filtered = filtered.filter((i) => i.properties.zoom <= 13);
        } else if (zoomlevel < 9.2) {
          filtered = filtered.filter((i) => i.properties.zoom <= 14);
        }
      }

      return filtered;
    } else if (data?.data.features.length > 100) {
      let filtered = data.data.features.filter((_, index) => index % 5 === 0);

      return filtered;
    } else {
      return data.data.features;
    }
  }

  return data;
};
