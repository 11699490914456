import React from 'react';
// import Button from './Button'; // Adjust the import path as needed
// import Icon from './Icon'; // Adjust the import path as needed
import ExternalLink from '../ui/ExternalLink';
import Button from '../ui/Button';
import { Icon } from '@plone/volto/components';
import autIcon from '../../../icons/austria/aut.svg';

interface ExternalLinkButtonsProps {
  variation: string;

  language: string;
  handleResetFromChart: (mode: string) => void;
}

const ExternalLinkButtons: React.FC<ExternalLinkButtonsProps> = React.memo(
  ({ variation, language, handleResetFromChart }) => {
    return (
      <>
        <div className="externalLinkButton">
          <ExternalLink
            text={
              language === 'de'
                ? 'Weitere Daten sowie Downloadmöglichkeiten finden Sie im Data Hub.'
                : 'Further data and download options can be found in the data hub.'
            }
            url="https://data.hub.geosphere.at/"
          />
          {variation === 'weatherforecast' && (
            <Button
              onClick={() => handleResetFromChart('map')}
              aria-label="Zurück zur Kartendarstellung"
            >
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                <Icon
                  name={autIcon}
                  size="15px"
                  className="svg_icon"
                  title="Zur Karten Ansicht"
                />
                {language === 'de' ? 'Zurück' : 'Go back'}
              </div>
            </Button>
          )}
        </div>
      </>
    );
  },
);

export default ExternalLinkButtons;
