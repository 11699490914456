import { useSelector } from 'react-redux';
import { Icon } from '@plone/volto/components';
import { Marker } from 'react-map-gl/maplibre';
import style from './WeatherforecastMarkers.module.css';
import WeatherForecastHelperSvgDay from '../../../constants/WeatherForecastHelperSvgDay';
import {
  mappingSymbToText,
  mappingSymbToTextEnglisch,
} from '../../../constants/WeatherforecastHelper';
import WeatherforecastMarkersDiv from './WeatherforecastMarkersDiv';

const WeatherforecastMarkers = ({
  data,
  markerdata,
  day,
  onWeatherforecastHandler,
}) => {
  const language = useSelector(
    (state) => state.geospheredataplatformdata['SET_LANGUAGE'],
  );
  const selectStation = (station = false, title = '', point_id) => {
    if (!data.highcharts_grid) return;
    if (station === false) return;

    onWeatherforecastHandler(station, title, point_id);
  };

  return (
    <>
      {markerdata.length > 0 &&
        markerdata.map((el, i) => (
          <Marker
            onClick={() =>
              selectStation(
                el.geometry.coordinates,
                el.properties.name,
                el.properties.point_id,
              )
            }
            longitude={el.geometry.coordinates[0]}
            latitude={el.geometry.coordinates[1]}
            key={i}
            className={style.weather_forecast}
            style={el.properties.markerStyles || null}
            title={`Station: ${el.properties.name}. Warnung: Stufe ${el.properties?.forecast[day].warning}. 
            Temperatur: ${Math.round(el.properties?.forecast[day].high)}-bis-${Math.round(el.properties?.forecast[day].low)} Grad 
            Wetter: ${language === 'de' ? mappingSymbToText[el.properties?.forecast[day].symbol] : mappingSymbToTextEnglisch[el.properties?.forecast[day].symbol]}.`}
          >
            <button
              className={style.aClass}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  selectStation(el.geometry.coordinates, el.properties.name);
                }
              }}
              onClick={() =>
                selectStation(
                  el.geometry.coordinates,
                  el.properties.name,
                  el.properties.point_id,
                )
              }
              aria-label={`Link zur Detailprognose: ${el.properties.name}`}
            >
              <div
                className={style.label_weatherforecast_5days}
                aria-label={`Station: ${el.properties.name}. 
                Warnstufe ${el.properties?.forecast[day].warning}. 
                Temperatur: ${Math.round(el.properties?.forecast[day].high)}-bis-${Math.round(el.properties?.forecast[day].low)} Grad 
                Wetter: ${language === 'de' ? mappingSymbToText[el.properties?.forecast[day].symbol] : mappingSymbToTextEnglisch[el.properties?.forecast[day].symbol]}.`}
              >
                {el.properties?.forecast[day].symbol && (
                  <Icon
                    name={
                      WeatherForecastHelperSvgDay[
                        el.properties?.forecast[day].symbol
                      ]
                    }
                    size="46px"
                    className={style.svg_icon}
                    title={
                      language === 'de'
                        ? mappingSymbToText[el.properties?.forecast[day].symbol]
                        : mappingSymbToTextEnglisch[
                            el.properties?.forecast[day].symbol
                          ]
                    }
                    ariaHidden={
                      language === 'de'
                        ? mappingSymbToText[el.properties?.forecast[day].symbol]
                        : mappingSymbToTextEnglisch[
                            el.properties?.forecast[day].symbol
                          ]
                    }
                  />
                )}
                <div className={style.label_weatherforecast_5days_header}>
                  {' '}
                  <div
                    className={style.label_weatherforecast_5days_headerTitle}
                  >
                    {el.properties?.name}
                  </div>{' '}
                  {day in el.properties?.forecast ? (
                    <WeatherforecastMarkersDiv
                      day={day}
                      forecast={el.properties?.forecast}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </button>
          </Marker>
        ))}
    </>
  );
};

export default WeatherforecastMarkers;
