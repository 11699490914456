// SemanticUI-free pre-@plone/components
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import config from '@plone/volto/registry';
import { UniversalLink } from '@plone/volto/components';
import { toBackendLang } from '@plone/volto/helpers';
import LogoImage from '../../../../../images/Logo.svg';
import geoSphereMessages from "../../../../../messages";

const Logo = () => {
  const { settings } = config;
  const lang = useSelector((state) => state.intl.locale);
  const intl = useIntl();

  return (
    <UniversalLink
      href={settings.isMultilingual ? `/${toBackendLang(lang)}` : '/'}
      title={intl.formatMessage(geoSphereMessages.site)}
    >
      <img
        src={LogoImage}
        alt={intl.formatMessage(geoSphereMessages.plonesite)}
        title={intl.formatMessage(geoSphereMessages.plonesite)}
      />
    </UniversalLink>
  );
};

export default Logo;
