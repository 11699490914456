import { Container } from '@plone/components';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { default as MobileNavigation } from '../MobileNavigation/MobileNavigation.jsx';
import { default as Navigation } from '../Navigation/Navigation.jsx';
import {
  Anontools,
  LanguageSelector,
  Logo,
  SearchWidget,
} from '@plone/volto/components';

import React from 'react';
import WarningBlock from '../../../../../components/Blocks/WarningBlock/WarningBlock.jsx';
import FatalWarningBlock from '../../../../../components/Blocks/WarningBlock/FatalWarningBlock.jsx';

const Header = ({ pathname }) => {
  const token = useSelector((state) => state.userSession.token, shallowEqual);

  return (
    <>
    <div className="header-wrapper" role="banner">
      <Container className="search">
        <SearchWidget />
      </Container>
      <Container className="header-wrap">
        <div className="header">
          <div className="logo-nav-wrapper">
            <div className="logo">
              <Logo />
            </div>
            <Navigation pathname={pathname} />
            <MobileNavigation pathname={pathname} />
            <LanguageSelector />
            {/* Uncomment to show Anontools when not logged in
            {!token && (
              <div className="tools">
                <Anontools />
              </div>
            )} */}
          </div>
        </div>
      </Container>
    </div>
    <FatalWarningBlock />
    <WarningBlock />
    </>

  );
};

export default Header;

Header.propTypes = {
  token: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  content: PropTypes.objectOf(PropTypes.any),
};

Header.defaultProps = {
  token: null,
  content: null,
};
