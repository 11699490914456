import { SimpleMapSchema } from './schema';
import BlockDataForm from '@plone/volto/components/manage/Form/BlockDataForm';

const SimpleMapSidebar = ({ data, block, onChangeBlock }) => {
  return (
    <BlockDataForm
      schema={SimpleMapSchema}
      title={SimpleMapSchema.title}
      onChangeField={(id, value) => {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      onChangeBlock={onChangeBlock}
      formData={data}
      block={block}
    />
  );
};

export default SimpleMapSidebar;
