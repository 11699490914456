import React, { useState, useEffect, useCallback, useMemo } from 'react';

import cx from 'classnames';
import moment from 'moment';
import 'moment/locale/de'; // Import the German locale

import VariationNavButtons from '../Components/Shared/VariationNavButtons';
import ControlPanels from '../Components/ControlPanels';

import Skeleton from '../Components/Shared/Skeleton';

import SimpleMap from '../Components/Shared/SimpleMap';

import TawesLegend from '../Components/Messstation/TawesLegend';
import BebenLegenzSize from '../Components/Earthquake/BebenLegenzSize';
import BebenMarkers from '../Components/Earthquake/BebenMarker';
import BebenTabelle from '../Components/Earthquake/BebenTabelle';
import BebenDetailView from '../Components/Earthquake/BebenDetailView';

moment.locale('de');

// interface ForecastPageProps {
//   data: any;
//   location?: boolean | string;
//   markerdata?: {
//     data: unknown;
//   };
//   chartModeNot?: boolean;
//   chartmode?: {
//     active: boolean;
//   };
//   // selectedDropdownElement?: any; // Adjust type if known
//   mapClick?: string;
//   FsetTableMode?: (mode: string) => void;
//   language: string;
//   stylesHashInfo?: string;
//   stylesHash?: string;

//   memoizedData: any; // Adjust type according to your data structure
//   combinedData: any; // Adjust type according to your data structure
// }

const EarthquakePage = ({
  markerdata,
  chartmode,
  // selectedDropdownElement,
  mapClick,
  FsetTableMode,
  language,
  stylesHashInfo,
  memoizedData,
  combinedData,
  handleClick,
  forecastTime,
  dayClick,
  selectedDropdownElement,
  filteredMarkers,
  clickElement,
  cut,
}) => {
  return (
    <div className="block weatherforecastMain g" id="geosphereMaps">
      {/* Karte */}

      {!(chartmode && chartmode.active) && markerdata && markerdata.data && (
        <div
          className={cx('block', 'geosphere_interactive_map mt-bottom')}
          id="geosphereMapsMap"
          style={{
            display: stylesHashInfo !== 'geosphereTexts' ? 'grid' : 'none',
          }}
        >
          {/* vorhersageButton karte / tabelle */}
          {/* Navbuttons */}
          <VariationNavButtons
            data={memoizedData}
            language={language}
            markerdata={markerdata}
            mapClick={mapClick}
            FsetTableMode={FsetTableMode}
          />
          {/* navbar vorhersage */}

          <ControlPanels
            markerdata={markerdata}
            data={memoizedData}
            onButtonClick={handleClick}
            isToday={forecastTime}
            numberOfDays={memoizedData.forcastdays}
            parameter={dayClick}
            tableActive={mapClick}
            language={language}
          />

          {/* tawesLegend */}
          {memoizedData.variation === 'beben' && mapClick === 'map' ? (
            <div className="flexBebenLegend">
              <TawesLegend
                legendGridRow={4}
                parameterValue={7}
                types="beben"
                language={language}
              />
              <BebenLegenzSize language={language} />
            </div>
          ) : null}

          {mapClick === 'map' &&
            memoizedData &&
            memoizedData.variation != 'weatherforecast' &&
            markerdata?.data && (
              <SimpleMap
                data={memoizedData}
                day={dayClick}
                stateAut={selectedDropdownElement}
                markerdata={combinedData}
              >
                {memoizedData.variation === 'beben' &&
                  memoizedData &&
                  markerdata?.data &&
                  filteredMarkers && (
                    <BebenMarkers
                      filteredMarkers={filteredMarkers}
                      parentData={memoizedData}
                      language={language}
                    />
                  )}
              </SimpleMap>
            )}
          {/* erdbeben tabelle */}
          {memoizedData.variation === 'beben' &&
            markerdata?.data &&
            mapClick === 'table' && (
              <BebenTabelle
                markerdata={markerdata.data}
                filteredElement={parseInt(cut)}
              />
            )}
          {memoizedData.variation === 'beben' &&
            clickElement?.owner == 'beben' && (
              <BebenDetailView
                clickElement={clickElement}
                language={language}
                seismogrammAdress={memoizedData?.seismogramm}
              />
            )}
        </div>
      )}
      {markerdata == null ? <Skeleton /> : null}
    </div>
  );
};
export default EarthquakePage;
