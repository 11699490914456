import React from 'react';
import WeatherForecastTexts from './ForecastTexts/WeatherForecastTexts.tsx';

interface WeatherForecastTextBlockProps {
  data: {
    textsforecast: any;
    variation: string;
  };
  forecasttext: string;
  selectedDropdownElement: any; // Adjust type if known
}

const WeatherForecastTextBlock: React.FC<WeatherForecastTextBlockProps> =
  React.memo(({ forecasttext, selectedDropdownElement }) => {
    return (
      <div
        className="block mt-topSmall geosphere_interactive_map"
        id="geosphereTexts"
      >
        <WeatherForecastTexts
          forecasttext={forecasttext}
          // serviceaddress={serviceaddress}
          className="block"
          selectedDropdownElement={selectedDropdownElement}
        />
      </div>
    );

    // return null;
  });

export default WeatherForecastTextBlock;
