import React, { useState, useEffect, useMemo } from 'react';
import './Accordion.css';
import moment from 'moment';

import AccordionDivText from './AccordionDivText';
moment.locale('de');
const Accordion = ({ items, columns, titleAllg, region }) => {
  const [openIndices, setOpenIndices] = useState([0]);
  const toggleItem = (index) => {
    if (index == 0) return;
    setOpenIndices((prevState) => {
      if (prevState.includes(index)) {
        return prevState.filter((i) => i !== index);
      } else {
        return [...prevState, index];
      }
    });
  };

  const filterItems = useMemo(() => {
    return items.filter((i) => {
      const [startDate, endDate] = i.validity_range;

      const formattedDate = moment(startDate).utc().format('YYYY-MM-DD');
      const formattedDateEndTime = moment(endDate)
        .utc()
        .format('YYYY-MM-DD HH:mm');
      const today = moment().format('YYYY-MM-DD');
      const eighteenOClockToday = moment().utc().format('YYYY-MM-DD 18:00');
      const isBeforeEnddate = moment().isBefore(moment(eighteenOClockToday));

      if (i.textmarken_id == 650) {
        const isSameDay = moment(formattedDate).isSame(today, 'day');

        if (isSameDay && isBeforeEnddate) {
          // damit der richtige text returned wird
          if (
            moment(formattedDateEndTime).isSame(moment(eighteenOClockToday))
          ) {
            return i;
          }
        } else if (isSameDay && !isBeforeEnddate) {
          if (
            !moment(formattedDateEndTime).isSame(moment(eighteenOClockToday))
          ) {
            return i;
          }
        } else if (
          !isSameDay &&
          moment(formattedDateEndTime).format('HH:mm') !== '06:00'
        )
          return i;
      } else {
        //bergtexte
        return i;
      }
    });
  }, [items]);

  const formatDate = (dateString, wname) => {
    dateString = dateString[0];
    return wname === 0 ? (
      <div className="accordion-weatherforecast-title">
        <b>Heute, {moment(dateString).format('dddd')} </b>
        {/* {', '} */}
        {moment(dateString).format('Do MMMM YYYY')}
      </div>
    ) : (
      <div className="accordion-weatherforecast-title">
        <b>{moment(dateString).format('dddd')}, </b>
        {/* {', '} */}
        {moment(dateString).format('Do MMMM YYYY')}
      </div>
    );
  };
  return (
    <div
      className={`accordion-weatherforecast-container ${!columns ? 'accordion-weatherforecast-containerNoGapMobile' : ''}`}
    >
      <div className="accordion-weatherforecast-column">
        <p>{titleAllg[0] + ' ' + region}</p>
        <div className="accordion-weatherforecast-item always-visible">
          <div className="accordion-weatherforecast-header accordion-weatherforecast-header-first">
            {formatDate(filterItems[0].validity_range, 0)}
          </div>
          <div className="accordion-weatherforecast-content active">
            {filterItems[0].text}
          </div>
        </div>
        {columns == true &&
          filterItems
            .slice(1)
            .filter((i) => i.textmarken_id == 650)
            .map((item, index) => {
              const adjustedIndex = index + 1; // Adjust index to match the original array
              const isOpen = openIndices.includes(adjustedIndex);
              return (
                <AccordionDivText
                  adjustedIndex={adjustedIndex}
                  isOpen={isOpen}
                  toggleItem={toggleItem}
                  index={index}
                  formatDate={formatDate}
                  item={item}
                  key={`${adjustedIndex}-${Math.random()}`}
                />
              );
            })}
      </div>
      <div
        className={`accordion-weatherforecast-column  
      ${columns == false ? 'marginTop' : ''}`}
      >
        {columns == true && <p>{titleAllg[1] + ' ' + region}</p>}
        {columns == true && (
          <div className="accordion-weatherforecast-item always-visible">
            <div className="accordion-weatherforecast-header accordion-weatherforecast-header-first">
              {formatDate(
                filterItems.filter((i) => i.textmarken_id == 615)[0]
                  .validity_range,
                0,
              )}
            </div>
            <div className="accordion-weatherforecast-content active">
              {filterItems.filter((i) => i.textmarken_id == 615)[0].text}
            </div>
          </div>
        )}
        {columns == false
          ? // für wien, bgld
            filterItems.slice(1).map((item, index) => {
              // if (index == 0) return;
              const adjustedIndex = index + 1; // Adjust index to match the original array
              const isOpen = openIndices.includes(adjustedIndex);
              return (
                <AccordionDivText
                  adjustedIndex={adjustedIndex}
                  isOpen={isOpen}
                  toggleItem={toggleItem}
                  index={index}
                  formatDate={formatDate}
                  item={item}
                  key={`${adjustedIndex}-${Math.random()}`}
                />
              );
            })
          : filterItems
              .slice(1)
              .filter((i) => i.textmarken_id == 615)
              .map((item, index) => {
                if (index == 0) return;
                const adjustedIndex = index + 10; // Adjust index to match the original array
                const isOpen = openIndices.includes(adjustedIndex);
                return (
                  <AccordionDivText
                    adjustedIndex={adjustedIndex}
                    isOpen={isOpen}
                    toggleItem={toggleItem}
                    index={index}
                    formatDate={formatDate}
                    item={item}
                    key={`${adjustedIndex}-${Math.random()}`}
                  />
                );
              })}
      </div>
    </div>
  );
};
export default Accordion;
