import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';

type ButtonProps = React.HTMLAttributes<HTMLButtonElement> & {
  size?: 'sm' | 'md' | 'lg';
  variant?: 'primary' | 'secondary';
  active?: false;
};

const Button: React.FC<ButtonProps> = ({ active, variant, size, ...props }) => {
  // const colorClass = variant === 'primary' ? '#37585F' : 'lightcoral';
  // const color = variant === 'primary' ? '#fffcf8' : '#252525';
  const sizeClass = size === 'sm' ? '0.5rem' : size === 'md' ? '.7rem' : '1rem';
  const activeState = !active ? '#052e37' : '#bfce40';
  const colorActive = !active ? '#FFFFFF' : '#052E37';
  return (
    <button
      className={`${styles.button} ui   ${active ? 'active' : ''}`}
      style={{
        paddingRight: sizeClass,
        paddingLeft: sizeClass,
        color: colorActive,
      }}
      {...props}
    >
      {props.children}
    </button>
  );
};
Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default Button;
