export const columnsStylingSchemaEnhancer = ({ schema, formData, intl }) => {
    const { fieldsets, properties} = schema;

    return {
      ...schema,
      fieldsets: [
        ...fieldsets,
        { // this is our new configurable attribute
          id: 'columns',
          title: 'Columns',
          fields: ['columns'],
        },
      ],
      required: [],
      properties: {
        ...properties,
        columns: { // this is our new configurable attribute
          title: 'Columns',
          choices: [
                ['one', 'One'],
                ['two', 'Two'],
            ],
          defaultValue: 'one',
        },
      },
    };
};
