export const boundsAutEuWorld = [
  {
    id: 0,
    name: 'aut',
    borders: [
      [7.82319510044934, 45.91189741860944],
      [20.204786897324055, 49.602615543845076],
    ],
  },
  {
    id: 1,
    name: 'europa',
    borders: [
      [-31.2, 34.5], // Southwest coordinates (Lon, Lat)
      [47.8, 71.2], // Northeast coordinates (Lon, Lat)
    ],
  },
  {
    id: 2,
    name: 'world',
    borders: [
      [-170, -60], // Exclude Antarctica and extreme north
      [170, 75],
    ],
  },
];
