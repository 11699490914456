import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { doesNodeContainClick } from 'semantic-ui-react/dist/commonjs/lib';
import { useIntl, defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { getBaseUrl, hasApiExpander } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { Link, useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { getNavigation } from '@plone/volto/actions';
import { Icon } from '@plone/volto/components';
import clearSVG from '@plone/volto/icons/clear.svg';
import NavItem from '@plone/volto/components/theme/Navigation/NavItem';
import arrowRightSVG from '@plone/volto/icons/right-key.svg';
import arrowLeftSVG from '@plone/volto/icons/left-key.svg';

const messages = defineMessages({
  close: {
    id: 'Close',
    defaultMessage: 'Close',
  },
  openFatMenu: {
    id: 'Open menu',
    defaultMessage: 'Open menu',
  },
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
});

const DesktopMenuItem = ({
  subitem,
  level,
  handleLinkClicked,
  resetToRoot,
  closeMenus,
  pathname,
  parentResetToRoot,
}) => {
  const [isSubMenuOpen, setSubMenuOpen] = useState(false);

  const openSubMenu = useCallback((e) => {
    e.stopPropagation();
    setSubMenuOpen(true);
  }, []);

  const closeSubMenu = useCallback((e) => {
    e.stopPropagation();
    setSubMenuOpen(false);
  }, []);

  useEffect(() => {
    if (parentResetToRoot) {
      setSubMenuOpen(false);
    }
  }, [parentResetToRoot]);

  return (
    <li className={subitem.url === pathname ? 'current' : ''}>
      <Link
        to={subitem.url === '' ? '/' : subitem.url}
        onClick={(e) =>
          handleLinkClicked(e, subitem, openSubMenu, closeSubMenu, level)
        }
      >
        {subitem.nav_title || subitem.title}
        {subitem.items.length > 0 && <Icon name={arrowRightSVG} />}
      </Link>
      <CSSTransition
        in={isSubMenuOpen}
        timeout={500}
        classNames="menu-drawer"
        unmountOnExit
      >
        <div className="menu-drawer subsubitem">
          <div className="back">
            <button className="back-button" onClick={closeSubMenu}>
              <Icon name={arrowLeftSVG} size="16px" />
              <span>
                <FormattedMessage {...messages.back} />
              </span>
            </button>
          </div>
          <ul className="subitems">
            <NavLink
              to={subitem.url === '' ? '/' : subitem.url}
              className="submenu-header"
              onClick={(e) => 
                handleLinkClicked(e, subitem, openSubMenu, closeSubMenu, level, false)
              }
            >
              <h2>{subitem.nav_title ?? subitem.title}</h2>
            </NavLink>

            {subitem.items &&
              subitem.items.map((subsubitem) => (
                <DesktopMenuItem
                  key={subsubitem.url}
                  subitem={subsubitem}
                  level={level + 1}
                  handleLinkClicked={handleLinkClicked}
                  resetToRoot={resetToRoot}
                  closeMenus={closeMenus}
                  pathname={pathname}
                  parentResetToRoot={parentResetToRoot}
                />
              ))}
          </ul>
        </div>
      </CSSTransition>
    </li>
  );
};

const Navigation = ({ pathname }) => {
  const [desktopMenuOpen, setDesktopMenuOpen] = useState(null);
  const [currentOpenIndex, setCurrentOpenIndex] = useState(null);
  const [resetToRoot, setResetToRoot] = useState(false);
  const navigation = useRef(null);
  const dispatch = useDispatch();
  const intl = useIntl();
  const enableFatMenu = config.settings.enableFatMenu;
  const history = useHistory();

  const lang = useSelector((state) => state.intl.locale);
  const token = useSelector((state) => state.userSession.token, shallowEqual);
  const items = useSelector((state) => state.navigation.items, shallowEqual);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (navigation.current && doesNodeContainClick(navigation.current, e))
        return;
      closeMenu();
    };

    document.addEventListener('mousedown', handleClickOutside, false);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    if (!hasApiExpander('navigation', getBaseUrl(pathname))) {
      dispatch(getNavigation(getBaseUrl(pathname), config.settings.navDepth));
    }
  }, [pathname, token, dispatch]);

  const resetMenus = useCallback((e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    setResetToRoot(true);
    setTimeout(() => setResetToRoot(false), 0); // Reset the state back to false
  }, []);

  const handleLinkClicked = useCallback(
    (e, subitem, openSubMenu, closeSubMenu, level, preventDefault=true) => {
      if (preventDefault) {
        e.preventDefault();
      }
      if (subitem.items.length > 0 && preventDefault) {
        openSubMenu(e);
      } else {
        history.push(subitem.url);
        resetMenus(e);
        closeMenu();
      }
    },
    [history, resetToRoot, resetMenus]
  );

  const openMenu = (index) => {
    if (index === currentOpenIndex) {
      setDesktopMenuOpen(null);
      setCurrentOpenIndex(null);
    } else {
      setDesktopMenuOpen(index);
      setCurrentOpenIndex(index);
    }
  };

  const closeMenu = (index) => {
    setDesktopMenuOpen(null);
    setCurrentOpenIndex(null);
    resetMenus();
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        closeMenu();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <nav
      id="navigation"
      aria-label="navigation"
      className="navigation"
      ref={navigation}
    >
      <div className={'computer large screen widescreen only'}>
        <ul className="desktop-menu">
          {items.map((item, index) => (
            <li key={item.url}>
              {enableFatMenu ? (
                <>
                  <button
                    onClick={() => openMenu(index)}
                    className={cx('item', {
                      active:
                        desktopMenuOpen === index ||
                        (!desktopMenuOpen && pathname === item.url),
                    })}
                    aria-label={intl.formatMessage(messages.openFatMenu)}
                    aria-expanded={desktopMenuOpen === index ? true : false}
                  >
                    {item.title}
                  </button>

                  <div className="submenu-wrapper">
                    <div
                      className={cx('submenu', {
                        active: desktopMenuOpen === index,
                      })}
                    >
                      <div className="submenu-inner">
                        <button
                          className="close"
                          onClick={closeMenu}
                          aria-label={intl.formatMessage(messages.close)}
                        >
                          <span>
                            <FormattedMessage {...messages.close} />
                          </span>
                          <Icon name={clearSVG} size="16px" />
                        </button>
                        <NavLink
                          to={item.url === '' ? '/' : item.url}
                          onClick={() => {
                            closeMenu();
                            resetMenus();
                          }}
                          className="submenu-header"
                        >
                          <h2>{item.nav_title ?? item.title}</h2>
                        </NavLink>
                        <ul>
                          {item.items &&
                            item.items.length > 0 &&
                            item.items.map((subitem) => (
                              <DesktopMenuItem
                                key={subitem.url}
                                subitem={subitem}
                                level={0}
                                handleLinkClicked={handleLinkClicked}
                                resetToRoot={resetToRoot}
                                closeMenus={closeMenu}
                                pathname={pathname}
                                parentResetToRoot={resetToRoot}
                              />
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <NavItem item={item} lang={lang} key={item.url} />
              )}
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

Navigation.propTypes = {
  pathname: PropTypes.string.isRequired,
};

Navigation.defaultProps = {
  token: null,
};

export default injectIntl(Navigation);
