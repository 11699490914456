import moment from 'moment';
import 'moment/locale/de'; // Import the German locale
import SunCalc from 'suncalc';

export const isNighttime = (timestamp) => {
  const latitude = '48.2082';
  const longitude = '16.3738';
  const date = moment(timestamp).toDate(); // Convert timestamp to Date object
  const times = SunCalc.getTimes(date, latitude, longitude); // Get sun times for that day

  const sunrise = moment(times.sunrise); // Sunrise time for that day
  const sunset = moment(times.sunset); // Sunset time for that day

  // Compare the full timestamp (including the date and time)
  if (
    moment(timestamp).isBefore(sunrise) ||
    moment(timestamp).isAfter(sunset)
  ) {
    return false; // It's nighttime (before sunrise or after sunset)
  }
  return true; // It's daytime
};
