import React, { useState, useEffect, useCallback, useMemo } from 'react';

import moment from 'moment';
import 'moment/locale/de'; // Import the German locale
import WarnWidget from '../Components/Warnings/WarnWidget';

moment.locale('de');

const WarnPage = ({ memoizedData }) => {
  return (
    <div className="block weatherforecastMain g" id="geosphereMaps">
      <div className="block geosphere_interactive_map mt-bottom">
        <WarnWidget
          data_warnstatus={memoizedData.warnstatus}
          warnpage={memoizedData.warnseite}
        />
      </div>
    </div>
  );
};
export default WarnPage;
