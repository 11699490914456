import React from 'react';
import WeatherforcastChart from './Forecast/WeatherforcastChartParent';

interface WeatherForecastChartBlockStyle {
  data: {
    textsforecast: any;
    variation: string;
  };
}

const WeatherForecastChartBlock: React.FC<WeatherForecastChartBlockStyle> =
  React.memo(({ data }) => {
    return (
      <>
        {/* <InfoComponent text="This is some information text." /> */}
        <WeatherforcastChart data={data} />
      </>
    );
  });

export default WeatherForecastChartBlock;
