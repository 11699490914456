// actions.js
import { CHART_COMPONENT } from '../constants/ActionTypes';

export const setComponent = (
  serviceName,
  flag,
  latlon = false,
  title = false,
  point_id = false,
) => ({
  type: CHART_COMPONENT,
  serviceName: serviceName,
  flag: flag, // data.variation + 'CHART'
  latlon,
  title,
  point_id,
});
