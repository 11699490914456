import WeatherForecastHelperSvgDay from '../../constants/WeatherForecastHelperSvgDay';
import WeatherForecastHelperSvgNight from '../../constants/WeatherForecastHelperSvgNight';

function svgToBase64(svgContent) {
  return 'data:image/svg+xml;base64,' + btoa(svgContent);
}
const createUniqueId = () => {
  return 'id-' + Math.random().toString(36).substr(2, 9);
};
const createSVGString = (importedSVG) => {
  const uniqueId = createUniqueId();
  const { attributes, content } = importedSVG;

  // Update class names in the <style> block
  const uniqueContent = content
    .replace(/\.cls-\d+/g, (match) => {
      return `.${uniqueId}-${match.slice(1)}`; // prepend uniqueId to each class
    })
    .replace(/class="cls-\d+"/g, (match) => {
      return match.replace(
        /cls-\d+/g,
        (className) => `${uniqueId}-${className}`,
      );
    });
  const attributeString = Object.keys(attributes)
    .map((attr) => `${attr}="${attributes[attr]}"`)
    .join(' ');
  return `<svg ${attributeString}>${uniqueContent}</svg>`;
};

export const createSvgElement = (value, yesOrno) => {
  //yewOrno = true - early than 18Uhr
  if (value === null) return;
  if (yesOrno) {
    let yValue = value;
    if (yValue > 0 && yValue < 10) {
      yValue = '0' + yValue;
    }
    if (yValue == null) return;
    // console.log(yValue);
    return `url(${svgToBase64(
      createSVGString(WeatherForecastHelperSvgDay[yValue]),
    )})`;
  } else {
    let yValue = value;
    if (yValue > 0 && yValue < 10) {
      yValue = '0' + yValue;
    }
    if (yValue == null) return;
    // console.log(yValue);
    return `url(${svgToBase64(
      createSVGString(WeatherForecastHelperSvgNight[yValue]),
    )})`;
  }
};

// wind pfeil
export function generateSVGString(rotationAngle) {
  const backgroundColor = '#F3F5F5';
  rotationAngle = rotationAngle - 90;
  return `
   <svg xmlns="http://www.w3.org/2000/svg" width="16.084px" height="12.548px" viewBox="0 -4 16.084 20" shape-rendering="geometricPrecision" preserveAspectRatio="xMidYMid meet">
  <rect width="100%" height="100%" fill="${backgroundColor}" />
  <path 
    d="M16.007,6.649a1,1,0,0,0,0-.773c0-.008-.011-.013-.015-.021a1,1,0,0,0-.2-.3L10.521.293A1,1,0,0,0,9.109,1.708L12.67,5.265H1a1,1,0,1,0,0,2H12.675L9.107,10.841a1,1,0,0,0,1.416,1.413l5.27-5.284a.8.8,0,0,0,.068-.1,1.02,1.02,0,0,0,.146-.221"
    fill="#052e37" 
    transform="rotate(${rotationAngle}, 8, 8)" />
</svg>
  `;
}
