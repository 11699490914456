import React from 'react';
import { withBlockExtensions } from '@plone/volto/helpers';
import cx from 'classnames';
import config from '@plone/volto/registry';

const HeadingView = (props) => {
  const { className, data } = props;
  const Element = data.tag || 'h2';
  const show_alignment = config.blocks?.blocksConfig?.heading?.show_alignment;
  let additional_cssclass;
  if (data.variation === 'page_subtitle') {
    additional_cssclass = 'page_subtitle';
  } else if (data.variation === 'full_layout_width') {
    additional_cssclass = 'heading_wide';
  }
  let extendedClassName = className;
  extendedClassName += ` ${additional_cssclass}`;

  return (
    <>
      {data && (
        <div className={cx('block heading', extendedClassName)}>
          <div className="heading-wrapper">
            <Element
              style={show_alignment ? { textAlign: data.alignment } : {}}
              className="heading"
            >
              {data?.heading || ''}
            </Element>
          </div>
        </div>
      )}
    </>
  );
};

export default withBlockExtensions(HeadingView);
