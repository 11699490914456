import moment from 'moment';
// moment.locale('de');
import 'moment/locale/de'; // Import the German locale

export function formatDate(
  isoTimestamp,
  typ,
  language = 'de',
  ecmwfstart,
  pointIndex,
) {
  const isEcmwf = pointIndex >= ecmwfstart;
  // set langugage format
  language === 'de' ? moment.locale('de') : moment.locale('en');

  const fDate = moment(isoTimestamp).local().format('dddd, DD.MM.YYYY');
  const fTime = moment(isoTimestamp).local().format('HH:mm');
  const f1Time = moment(isoTimestamp).local().add(1, 'Hour').format('HH:mm');
  const f6Time = moment(isoTimestamp).local().add(6, 'Hour').format('HH:mm');
  // 'Donnerstag, 11.7.2024 | 16:00 - 17:00'
  if ((typ == 'Regen' || typ == 'Wetter') && isEcmwf) {
    return fDate + ' | ' + fTime + ' – ' + f6Time;
  } else if ((typ == 'Regen' || typ == 'Wetter') && !isEcmwf) {
    return fDate + ' | ' + fTime + ' – ' + f1Time;
  } else {
    return fDate + ' | ' + fTime + ' Uhr';
  }
  return fDate + ' | ' + fTime;
  //
}
