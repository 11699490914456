export const listingLinkSchemaEnhancer = ({ schema, formData, intl }) => {
    const { fieldsets, properties} = schema;

    return {
      ...schema,
      fieldsets: [
        ...fieldsets,
        {
          id: 'href',
          title: 'Link',
          fields: ['link_href', 'link_title'],
        },
      ],
      required: [],
      properties: {
        ...properties,
        link_href: {
            title: 'Link',
            widget: 'object_browser',
            mode: 'link',
            selectedItemAttrs: ['Title', 'Description', 'path', 'portal_type'],
            allowExternals: true,
        },
        link_title: {
            title: 'Link Title',
        },
      },
    };
};
