
const IconListingColumnSchema = () => ({
    title: 'Column',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['column', 'title', 'description', 'logo', 'link_title', 'link'],
      },
    ],
    properties: {
      title: {
        title: 'Header',
      },
      description: {
        title: 'Description',
        description: 'Add suffix/prefix to text. Use {} for value placeholder',
      },
      logo: {
        title: 'Logo',
        widget: 'object_browser',
        mode: 'image'
      },
      link_title: {
        title: 'Link Title',
      },
      link: {
        title: 'Link',
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description', 'path', 'portal_type'],
        allowExternals: true,
      },
      column: {
        title: 'Data column',
        choices: [],
      },
    },
    required: ['column', 'title'],
});

export default IconListingColumnSchema;