// BebenTabelle.js
// zwei arte, einmal meldungen, einmal beben abhängig von dem gewählten button above? oder immer nur aktuelle?

import React, { useEffect, useState } from 'react';

import styles from '../Forecast/WeatherforecastTable.module.css';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import cx from 'classnames';
import { pointOrComma } from '../../utils/pointOrComma';

const tableHeaderEarthquake = [
  // 'Datum',
  'Weltzeit (UTC)',
  'Lokalzeit',
  // 'Zeitzone',
  'Magnitude',
  'Breite (°)',
  'Länge (°)',
  'Tiefe (km)',
  'Region',
];
const tableHeaderEarthquakeEn = [
  // 'Date',
  'UTC',
  'Local time',
  // 'timezone',
  'Magnitude',
  'Lat (°)',
  'Long (°)',
  'Depth (km)',
  'Region',
];

const BebenTabelle = ({ markerdata, filteredElement }) => {
  const dispatch = useDispatch();

  const [selecteStatioen, setSelecteStatioen] = useState();

  const language = useSelector(
    (state) => state.geospheredataplatformdata['SET_LANGUAGE'],
  );

  useEffect(() => {
    dispatch({
      type: 'SET_CLICK_MARKER_TABLE_INTERACTION',
      payload: null,
      owner: null,
    });
  }, []);

  const handleRowClick = (station) => {
    setSelecteStatioen(station.event_id);
    station.owner = 'beben';
    dispatch({
      type: 'SET_CLICK_MARKER_TABLE_INTERACTION',
      payload: station,
    });
  };
  const HeaderRow =
    language == 'de' ? tableHeaderEarthquake : tableHeaderEarthquakeEn;
  const filterel =
    filteredElement >= 0 && filteredElement < 3 ? filteredElement : 0;
  const locationid = filterel === 0 ? 1 : filterel === 1 ? 2 : 3;
  return (
    <div className={styles.weatherprognoseTable}>
      <div
        className={
          (styles.weatherprognoseTable, styles.weatherprognoseTableOffset)
        }
      >
        <table
          className={styles.weatherprognoseTable_table}
          aria-label="Tabellarische Beschreibung der Erdbeben der letzten 14Tage "
        >
          <thead>
            <tr>
              {/* Header row with days */}
              {markerdata.filter((i) => i.location_id <= locationid).length >
                0 &&
                HeaderRow.map((day, index) => (
                  <th
                    style={{ position: 'sticky', zIndex: 1, top: 0 }}
                    key={index}
                  >
                    {day}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {/* Rows for each station */}
            {markerdata.filter((i) => i.location_id <= locationid).length > 0 &&
              markerdata.filter((i) => i.location_id <= locationid)[0].lat &&
              markerdata
                .filter((i) => i.location_id <= locationid)
                .map((station, stationIndex) => (
                  <tr
                    key={stationIndex}
                    className={cx({
                      [styles.fontBold]: station.event_type === 'fe',
                      [styles.active]: station.event_id === selecteStatioen,
                    })}
                    onClick={() => handleRowClick(station)}
                    role="button"
                    aria-label={`Link zur Detailansicht des Bebens in ${station.region}`}
                  >
                    {/* <td>{station.datetime_utc && station.datetime_utc}</td> */}
                    <td>
                      <div
                        aria-label={`Weltzeit in UTC: ${moment(station.datetime_utc).format('DD-MM-YYYY HH:mm:s')}`}
                        style={{ whiteSpace: 'nowrap', display: 'flex' }}
                      >
                        {station.datetime_utc &&
                          moment(station.datetime_utc).format('DD-MM-YYYY')}
                        <div
                          style={{
                            color: '#bfce40',
                            marginLeft: '3px',
                            marginRight: '3px',
                          }}
                        >
                          |
                        </div>
                        {station.datetime_utc &&
                          moment(station.datetime_utc).format('HH:mm:s')}
                      </div>
                    </td>
                    <td>
                      <div
                        aria-label={`Lokalzeit:${moment(station.datetime_local).format('DD-MM-YYYY HH:mm:s')}`}
                        style={{ whiteSpace: 'nowrap', display: 'flex' }}
                      >
                        {station.datetime_local &&
                          moment(station.datetime_local).format('DD-MM-YYYY')}
                        <div
                          style={{
                            color: '#bfce40',
                            marginLeft: '3px',
                            marginRight: '3px',
                          }}
                        >
                          |
                        </div>
                        {station.datetime_local &&
                          moment(station.datetime_local).format('HH:mm')}

                        {' ' + station.timezone}
                      </div>
                    </td>
                    {/* <td>{station.timezone}</td> */}
                    <td aria-label="Magnitude">
                      {typeof station.reference_magnitude[0] == 'number' &&
                        pointOrComma(
                          station.reference_magnitude[0],
                          language,
                          true,
                        ) +
                          ' ' +
                          station.reference_magnitude[1]}
                    </td>
                    <td aria-label="Geographische Breite:">
                      {station.lat &&
                        (language == 'de'
                          ? station.lat.toFixed(2).replace('.', ',')
                          : station.lat.toFixed(2))}
                    </td>
                    <td aria-label="Geographische Länge:">
                      {station.lon &&
                        (language == 'de'
                          ? station.lon.toFixed(2).replace('.', ',')
                          : station.lon.toFixed(2))}
                    </td>

                    <td aria-label="Tiefe des Erdbebens:">
                      {station.depth && Math.round(station.depth)}
                    </td>
                    <td aria-label="Region des Erdbebens:">
                      {station.region &&
                        station.region.replace(/\s*\/\s*/g, '/')}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BebenTabelle;
