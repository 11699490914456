export const States = [
  {
    id: 0,
    name: 'Österreich',
    borders: [
      [7.82319510044934, 45.91189741860944],
      [20.204786897324055, 49.602615543845076],
    ],
    key: '0',
  },
  {
    id: 1,
    name: 'Wien',
    borders: [
      [15.687132951144918, 47.98498242413061],
      [17.10807336122201, 48.40520699114961],
    ],
    key: '9',
  },
  {
    id: 2,
    name: 'Burgenland',
    borders: [
      [15.412403019694437, 46.75190963354322],
      [20.148810015068193, 48.172449860723475],
    ],
    key: '1',
  },
  {
    id: 3,
    name: 'Niederösterreich',
    borders: [
      [13.61509907979783, 47.23906200997416],
      [19.797067734586136, 49.0685146738405],
    ],
    key: '3',
  },
  {
    id: 4,
    name: 'Oberösterreich',
    borders: [
      [11.804826319027313, 47.32390193038168],
      [16.62444810763185, 48.753470247063575],
    ],
    key: '4',
  },
  {
    id: 5,
    name: 'Steiermark',
    borders: [
      [13.213992384634253, 46.489347478708936],
      [17.950399380008065, 47.916873851356456],
    ],
    key: '6',
  },
  {
    id: 6,
    name: 'Salzburg',
    borders: [
      [11.380393791944385, 46.77297859219786],
      [15.635947140292615, 48.050546363021795],
    ],
    key: '5',
  },
  {
    id: 7,
    name: 'Kärnten',
    borders: [
      [12.49941621065843, 46.28397027458806],
      [15.817155071139666, 47.29176308743632],
    ],
    key: '2',
  },
  {
    id: 8,
    name: 'Tirol',
    borders: [
      [9.849930201811674, 46.60848129565835],
      [13.607431328446921, 47.741606200574864],
    ],
    key: '7',
  },
  {
    id: 9,
    name: 'Vorarlberg',
    borders: [
      [8.44100183884774, 46.77412551386689],
      [11.75874069932891, 47.772790401549656],
    ],
    key: '8',
  },
];
// [
//   [7.82319510044934, 45.91189741860944], // Southwest corner (longitude, latitude)
//   [20.204786897324055, 49.602615543845076], // Northeast corner (longitude, latitude)
// ];
