/*
Icon Listing Content Variation View for CardSection
*/

import View from '../../Link/View.jsx';
import config from '@plone/volto/registry';

const LinkListingContent = (props) => {
  const columns = props.props.data.columns;

  return (
    <div className="link-listing" key="link-listing">
        {columns && columns.length && (columns.map((column, index) => (
            <div className="listing-link" key={column['@id'] + index}>
              {(column.link && column.link_title) && (
                  <View data={{
                      href: column.link,
                      title: column.link_title
                    }} isEditMode={false} />
              )}
            </div>
        )))}
    </div>
  );
};

export default LinkListingContent;