import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGeospheredataplatformdata } from '../../../actions';
import styles from '../Forecast/WeatherforecastTable.module.css';
import stylesHitliste from './Hitliste.modules.css';
import moment from 'moment';
import ButtonNoHtmlatt from '../ui/ButtonNoHtmlatt';
import { Icon } from '@plone/volto/components';
import searchUpDown from '../../../icons/austria/ListChange_Midnight_12px.svg';

const Hitliste: React.FC = ({ data }) => {
  const dispatch = useDispatch();
  const [activeRegion, setActiveRegion] = useState(1);
  const [activeParameter, setActiveParameter] = useState(0);
  const [sortColumn, setSortColumn] = useState({ column: '0 wert' });
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortColumnHP, setSortColumnHP] = useState({ column: '0 name' });
  const [sortOrderHP, setSortOrderHP] = useState<'asc' | 'desc'>('asc');

  useEffect(() => {
    dispatch(
      getGeospheredataplatformdata(
        data.serviceaddress,
        'stations/tawes/hitlist',
      ),
    );
  }, [dispatch]);

  const hitlistjson = useSelector(
    (state) => state.geospheredataplatformdata['stations/tawes/hitlist'],
  );

  if (!hitlistjson) return <p>Loading...</p>;

  const tableNames = [
    'Landeshauptstädte',
    'Österreich',
    'ÖsterreichBerg',
    'Wien',
    'Niederösterreich',
    'Burgenland',
    'Steiermark',
    'Oberösterreich',
    'Salzburg',
    'Kärnten',
    'Tirol',
    'Vorarlberg',
  ];
  const nicetableNames = [
    'Landeshauptstädte',
    'Österreich',
    'Bergland',
    'Wien',
    'Niederösterreich',
    'Burgenland',
    'Steiermark',
    'Oberösterreich',
    'Salzburg',
    'Kärnten',
    'Tirol',
    'Vorarlberg',
  ];

  const headerTabeleNames = [
    { name: 'Station', value: 'name' },
    { name: 'Höhe', value: 'hoehe' },
    { name: 'Aktueller Wert', value: 'wert' },
    { name: 'Extremwert*', value: 'extremwert' },
    { name: 'Datum des Extremwertes', value: 'datum_extremwert' },
  ];

  const parameterReihenfolge = ['tmin', 'tmax', 'rr', 'boen', 'so'];
  const parameterLabels = [
    'Temperatur Minimum [°C]',
    'Temperatur Maximum [°C]',
    'Niederschlagsmenge [mm]',
    'Wind [km/h]',
    'Sonnenscheindauer [h]',
  ];

  const parameters = Object.keys(hitlistjson.data.Landeshauptstädte);
  const stations = Object.keys(
    hitlistjson.data.Landeshauptstädte[parameters[0]] || {},
  );

  const getStationNames = (tableName, paramIndex) => {
    if (!tableName) return [];
    return Object.keys(
      hitlistjson.data[tableName][parameterReihenfolge[paramIndex]] || {},
    );
  };

  const sortStations = (stations, column, order, tableName) => {
    return [...stations].sort((a, b) => {
      const [param, key] = column.split(' ');
      let aValue =
        hitlistjson.data[tableName][parameterReihenfolge[param]][a]?.[key] ?? 0;
      let bValue =
        hitlistjson.data[tableName][parameterReihenfolge[param]][b]?.[key] ?? 0;

      if (key === 'extremwert') {
        aValue = aValue.wert;
        bValue = bValue.wert;
      }
      if (key === 'datum_extremwert') {
        aValue = moment(aValue.datum).unix();
        bValue = moment(bValue.datum).unix();
      }
      if (key === 'name') {
        return order === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
      return order === 'asc' ? aValue - bValue : bValue - aValue;
    });
  };

  const sortedStations = sortStations(
    getStationNames(tableNames[activeRegion], activeParameter),
    sortColumn.column,
    sortOrder,
    tableNames[activeRegion],
  );

  const handleSort = (key) => {
    const column = `${activeParameter} ${key}`;
    if (sortColumn?.column === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn({ column });
      setSortOrder('asc');
    }
  };

  const sortedStationsHP = sortStations(
    stations,
    sortColumnHP.column,
    sortOrderHP,
    'Landeshauptstädte',
  );

  const handleSortHp = (param, key) => {
    const column = `${key} ${param} `;
    if (sortColumnHP?.column === column) {
      setSortOrderHP(sortOrderHP === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumnHP({ column });
      setSortOrderHP('asc');
    }
  };

  const Navigation = ({ items, activeIndex, setActiveIndex }) => (
    <div className={stylesHitliste.tableNavigation}>
      {items.map((item, index) => (
        <ButtonNoHtmlatt
          key={index}
          onClick={() => setActiveIndex(index)}
          active={activeIndex === index}
          label={item}
        />
      ))}
    </div>
  );

  const DataTable = ({ tableName, paramIndex }) => {
    return (
      <table border="1" className={styles.weatherprognoseTable_table}>
        <thead>
          <tr>
            {headerTabeleNames.map((hName) => (
              <th
                style={{ cursor: 'pointer' }}
                key={hName.value}
                onClick={() => handleSort(hName.value)}
              >
                <div className={stylesHitliste.hdDiv}>
                  {hName.name}
                  <Icon
                    name={searchUpDown}
                    size="12px"
                    title="Sortieren nach Parameter"
                  />
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedStations.map((station) => {
            const stationData =
              hitlistjson.data[tableName][parameterReihenfolge[paramIndex]][
                station
              ] || {};

            const getValue = (path) => {
              return (
                path
                  .split('.')
                  .reduce((acc, part) => acc && acc[part], stationData) ?? 'N/A'
              );
            };

            return (
              <tr key={station}>
                <td>{getValue('name')}</td>
                <td>{getValue('hoehe')}</td>
                <td>{getValue('wert')}</td>
                <td>{getValue('extremwert.wert')}</td>
                <td>{getValue('extremwert.datum')}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const givenTime = moment(hitlistjson.data.aktualisiert * 1000).format(
    'dddd DD.MM.YYYY HH:mm',
  );

  return (
    <div className={stylesHitliste.hitliste}>
      <div
        className={styles.weatherprognoseTable_div}
        style={{ maxHeight: 'unset', marginBottom: '5rem' }}
      >
        <div className={stylesHitliste.headerDivMargin}>
          <p className={stylesHitliste.pMargin}>
            Messwerte bis:{' '}
            <b style={{ fontWeight: 700 }}>{givenTime + ' MEZ '}</b> wurden
            berücksichtigt.
          </p>
          <p className={stylesHitliste.pMargin}>
            *Extremwert für den aktuellen Monat auf Basis der Datenreihe seit
            Messbeginn der jeweiligen Station.
          </p>
        </div>
        <div>
          <h3 className={stylesHitliste.header}>Landeshauptstädte</h3>
        </div>

        <table border="1" className={styles.weatherprognoseTable_table}>
          <thead>
            <tr>
              <th
                style={{ cursor: 'pointer' }}
                onClick={() => handleSortHp('name', 0)}
              >
                <div className={stylesHitliste.hdDiv}>
                  Station
                  <Icon
                    name={searchUpDown}
                    size="12px"
                    title="Sortieren nach Parameter"
                  />
                </div>
              </th>

              {parameterLabels.map((param, index) => (
                <th
                  key={param}
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSortHp('wert', index)}
                >
                  <div className={stylesHitliste.hdDiv}>
                    {param}
                    <Icon
                      name={searchUpDown}
                      size="12px"
                      title="Sortieren nach Parameter"
                    />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedStationsHP.map((station) => (
              <tr key={station}>
                <td>
                  {hitlistjson.data.Landeshauptstädte[parameters[0]][station]
                    ?.name ?? 'N/A'}
                </td>
                {parameterReihenfolge.map((param) => (
                  <td key={param} style={{ padding: 'unset' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        padding: '8px',
                        position: 'relative',
                      }}
                    >
                      <div style={{}}>
                        {hitlistjson.data.Landeshauptstädte[param][station]
                          ?.wert ?? 'N/A'}
                      </div>
                      <div
                        style={{
                          borderRight: 'grey 1px solid',
                          position: 'absolute',
                          height: '60px',
                          margin: '-1rem 47%',
                        }}
                      ></div>
                      <div>
                        {hitlistjson.data.Landeshauptstädte[param][station]
                          ?.extremwert?.wert ?? 'N/A'}{' '}
                        *
                      </div>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Navigation
        items={parameterLabels}
        activeIndex={activeParameter}
        setActiveIndex={setActiveParameter}
      />
      <Navigation
        items={nicetableNames}
        activeIndex={activeRegion}
        setActiveIndex={setActiveRegion}
      />
      <h3 className={stylesHitliste.header}>
        {nicetableNames[activeRegion]} - {parameterLabels[activeParameter]}
      </h3>
      <div
        className={styles.weatherprognoseTable_div}
        style={{ overflowX: 'auto', overflowY: 'hidden', maxHeight: 'unset' }}
      >
        <DataTable
          tableName={tableNames[activeRegion]}
          paramIndex={activeParameter}
        />
      </div>
    </div>
  );
};

export default Hitliste;
