import React from 'react';

const CircleMarker = ({
  size,
  color = 'red',
  border = '1px',
  borderColor = 'black',
  borderRadius = '50%',
  x = '',
  title = '',
}) => {
  const markerStyle = {
    width: size,
    borderColor: borderColor,
    borderStyle: 'solid',
    borderWidth: border,
    height: size,
    backgroundColor: color,
    borderRadius: borderRadius,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
  };

  return (
    <div style={markerStyle} title={title}>
      {x}
    </div>
  );
};

export default CircleMarker;
