import React from 'react';
import styles from '../Messstation/TawesMarker.module.css';
import moment from 'moment';
import { pointOrComma } from '../../utils/pointOrComma';
moment.locale('de');

interface PopupRef {
  maptitle: string;
  author: string;
  local_datetime: string;
  magnitude: number;
  depth: number;
}

interface BebenMarkerDivPops {
  handleKeyDown: (e: Event, showPopup: boolean) => void;
  setTooltipF: (showPopup: boolean) => void;
  showPopup: object;
  language: string;
}

const BebenMarkersDiv = (props: BebenMarkerDivPops) => {
  const { showPopup, language } = props;
  return (
    <>
      <div className={styles.backgroundTooltop}>
        {' '}
        <p className={styles.pPopupFirstChild}>
          {showPopup.maptitle.includes('/')
            ? showPopup.maptitle.split('/')[0]
            : showPopup.maptitle}
        </p>
        <p className={styles.pPopupOtherChild}>
          {language == 'de' ? 'Zeit' : 'Time'}:{' '}
          {moment(showPopup.datetime_utc)
            // .utc()
            .format('DD-MM-YYYY HH:mm:ss,S')}{' '}
          (UTC)
        </p>
        <p className={styles.pPopupOtherChild}>
          Magnitude:{' '}
          {pointOrComma(
            Math.round(showPopup.reference_magnitude[0] * 10) / 10,
            language,
            true,
          )}{' '}
          {showPopup.reference_magnitude[1]}{' '}
        </p>
        {showPopup.depth ? (
          <p className={styles.pPopupOtherChild}>
            {language == 'de' ? 'Tiefe ' : 'Depth '}:{' '}
            {Math.round(showPopup.depth)} km
          </p>
        ) : (
          ''
        )}
        {/* <a
          href="#/"
          role="button"
          tabIndex={0}
          className={styles.aClass}
          rel="stylesheet"
          // onClick={() => setTooltipF(showPopup)}
          // onKeyDown={(e) => handleKeyDown(e, showPopup)}
        >
          {' '}
          Mehr Information
        </a> */}
      </div>
    </>
  );
};
export default BebenMarkersDiv;
