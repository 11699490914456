import React from 'react';
import cx from 'classnames';
import { Icon } from '@plone/volto/components';
import svgBurgermenu from '../../../../../icons/Burger_white_32px.svg';

export const MobileNavigationToggler = ({ isMobileMenuOpen }) => {
  return (
    <Icon
      name={svgBurgermenu}
      size="16px"
    />
  );
};
