import { defineMessages } from 'react-intl';
const geoSphereMessages = defineMessages({
  site: {
    id: 'GeoSphere Site',
    defaultMessage: 'Site',
  },
  plonesite: {
    id: 'GeoSphere Austria',
    defaultMessage: 'GeoSphere Austria',
  },
  closeMobileMenu: {
    id: 'Close GeoSphere Mobile menu',
    defaultMessage: 'Close menu',
  },
  back: {
    id: 'GeoSphere Mobile Back',
    defaultMessage: 'Back',
  },
});
export default geoSphereMessages;
