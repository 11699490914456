import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import styles from './WeatherforcastChart.module.css';
import './WeatherforcastChartHighcharts.css'; // so that highcharts knows the stylings..
import { formatDate } from '../../../utilities/dateUtils';

import {
  mappingSymbToText,
  mappingSymbToTextEnglisch,
} from '../../../constants/WeatherforecastHelper';

import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
import moment from 'moment';
import {
  highchartsOptionForecast,
  findIndices,
  calculateMaxAxisValue,
  setWindSymbol,
} from '../HelperFunctions/highchartsOptionForecast';
// moment.locale('de');

const useChartTolltipPosition = (chartContainerRef) => {
  const x = useRef();
  const y = useRef();

  const getTooltipPostion = (width, height, point) => {
    const chart = chartContainerRef.current?.chart
      ? chartContainerRef.current?.chart
      : chartContainerRef.current?.getChart();

    if (chart) {
      const { plotLeft, plotTop, chartWidth, container } = chart;
      const { plotX, plotY } = point;
      x.current = plotX + plotLeft + 10;
      y.current = plotY + plotTop - height - 10;
      // console.log(y.current);
      if (
        x.current + width >
        chartWidth + container.parentElement.parentElement.scrollLeft
      ) {
        return {
          chartx: x.current - 300 - 20,
          charty: y.current,
        };
      }

      if (x.current < plotLeft) {
        return { chartx: plotLeft + 10, charty: y.current };
      }

      if (y.current < plotTop) {
        return { chartx: x.current + 20, charty: plotY + plotTop + 10 };
      }
    }
    return {
      chartx: x.current + 20,
      charty: y.current,
    };
  };
  return { getTooltipPostion };
};

const WeatherforcastChartHC = ({ forecastdata, language }) => {
  const dispatch = useDispatch();

  const [config, setConfig] = useState(false);
  const chartContainerRef = useRef(null);
  React.useEffect(() => {
    if (!forecastdata?.data) return;
    // set better timestamps:
    // forecastdata.data.newtimestamps = forecastdata.data.newtimestamps
    //   ? forecastdata.data.newtimestamps
    //   : forecastdata.data.timestamps.map(
    //       (item, id) => new Date(item).getTime(), // Convert timestamp to milliseconds
    //     );

    setConfig(makeConfig(forecastdata.data));
  }, [forecastdata]);

  // outsourciung functions
  const { getTooltipPostion } = useChartTolltipPosition(chartContainerRef);
  const customPositioner = (width, height, point) => {
    const { chartx, charty } = getTooltipPostion(width, height, point);
    return { x: chartx, y: charty };
  };

  const makeConfig = (dataloaded) => {
    // ist immer so  - nach 48h - 6h modell
    const forecast6hStarts = dataloaded.reference_time;
    const newDate = moment(forecast6hStarts)
      .add(3, 'days')
      .format('YYYY-MM-DDT00:00:00[Z]');
    const findIndexOf6hStart = dataloaded.timestamps.findIndex(
      (i) => i === newDate,
    );
    // utc zeit
    const midnightTimestamps = dataloaded.newtimestamps.filter(
      (timestamp, index) => {
        const localTime = moment(timestamp);
        return (
          localTime.hours() === 0 ||
          (index > findIndexOf6hStart && // damit nicht mitternach und wenn ecmwf beginnt und 1uhr/2uhr strich dargestellt wrird
            (localTime.hours() === 2 || localTime.hours() === 1)) // für winter und sommerzeit
        );
      },
    );

    //Runtime add
    const refTime = moment(dataloaded.reference_time).unix() * 1000;
    midnightTimestamps.push(refTime);

    const midnightTimestampIndex = findIndices(
      dataloaded.newtimestamps,
      midnightTimestamps,
    );

    // Calculate the maximum value dynamically
    const { rr, t2m, sy, ff, dd } =
      dataloaded.features[0].properties.parameters;

    // rr wird manipuliert! f.e. [null, 1, 4, 10, 11]; to [1, 4, 10, 11, null]
    // rr.data = rr.data.slice(1).concat(null);
    // sy.data = sy.data.slice(1).concat(null);

    // beginn error handling when missing data
    const plotBands = [];
    const newLittleArray = [rr.data, t2m.data, sy.data, dd.data];
    const nullIndices = [];
    newLittleArray.forEach((array, arrayIndex) => {
      array.forEach((value, valueIndex) => {
        if (value === null && valueIndex != rr.data.length - 1) {
          nullIndices.push(valueIndex);
        }
      });
    });
    const removedDoublicatesnullIndices = [...new Set(nullIndices)];
    // Iterate over the data to find null values and create plotBands for them
    // letzter wert von rr nicht dargestellt wird
    removedDoublicatesnullIndices.forEach((value, index) => {
      plotBands.push({
        from: value - 0.5, // Start of the plot band
        to: value + 0.5, // End of the plot band
        color: 'rgba(255, 0, 0, 0.2)', // Light red color to indicate missing data
        label: {
          text: 'Missing <br> Data',
          align: 'center',
          style: {
            color: '#FF0000',
            fontWeight: 'bold',
          },
        },
      });
    });
    // end  error handling when missing data

    // const windData = calculateWindFromArray(u10m.data, v10m.data);
    const windData = {
      windSpeed: ff.data,
      windDirection: dd.data,
    };
    const maxDataValue = Math.max(...rr.data);
    const maxAxisValue = calculateMaxAxisValue(maxDataValue);

    // in here i can get forecastdata because its scoped!
    function createTooltipFormatter() {
      return function () {
        const unit = this.series.type === 'column' ? ' mm/h' : ' °C';
        let nameOf =
          this.series.type === 'column' ? 'Niederschlag' : 'Temperatur';

        if (this.series.name === 'wetter') nameOf = 'Wetter';

        const returnValue = formatDate(
          this.x,
          nameOf,
          language,
          findIndexOf6hStart,
          this.point.index,
        );

        // language
        if (nameOf === 'Niederschlag' && language === 'en')
          nameOf = 'Precipitation';
        if (nameOf === 'Temperatur' && language === 'en')
          nameOf = 'Temperature';
        if (this.series.type === 'scatter') {
          if (this.point.newAttribute >= 0) {
            const direction = setWindSymbol(this.point.z);
            const windRichtung =
              language === 'de' ? `Wind aus ` : 'Direction: ';
            const windSpitze = language === 'de' ? 'Windspitze: ' : 'Gust: ';
            return (
              returnValue +
              '<br>' +
              windRichtung +
              direction.nicename[language] +
              '<br>Wind: ' +
              Math.round(this.point.newAttribute) +
              'km/h' +
              '<br>' +
              windSpitze +
              dataloaded.features[0].properties.parameters['fx'].data[
                this.point.x
              ] +
              'km/h'
            );
          } else {
            return (
              returnValue +
              '<br>' +
              (language == 'de'
                ? mappingSymbToText[
                    dataloaded.features[0].properties.parameters['sy'].data[
                      this.point.x
                    ]
                  ]
                : mappingSymbToTextEnglisch[
                    dataloaded.features[0].properties.parameters['sy'].data[
                      this.point.x
                    ]
                  ]) +
              '<br>'
            );
          }
        }

        return returnValue + '<br>' + nameOf + ': ' + this.y + unit + '<br>';
      };
    }
    const tooltipFormatter = createTooltipFormatter(forecastdata);

    const yAxisTitleTemp =
      language === 'de' ? 'Temperatur °C' : 'Temperature °C';
    const yAxisTitleReain =
      language === 'de' ? 'Niederschlag mm/h' : 'Precipitation mm/h';

    const forecastLang = language === 'de' ? 'Vorhersage' : 'Forecast';

    const setClickInChartTimeFunction = (x) => {
      //used to figure out in widget which timestep is selected
      // using useState  resulted in rerender!
      dispatch({ type: 'SET_CLICK_X_AXIS', payload: x });
    };
    const chartOptions = highchartsOptionForecast(
      dataloaded,
      yAxisTitleReain,
      yAxisTitleTemp,
      forecastLang,
      sy,
      windData,
      t2m,
      rr,
      midnightTimestamps,
      midnightTimestampIndex,
      plotBands,
      tooltipFormatter,
      maxAxisValue,
      customPositioner,
      setClickInChartTimeFunction,
    );

    // setNconfig(config);
    return chartOptions;
  };

  return (
    <>
      {config ? (
        <HighchartsReact
          ref={chartContainerRef}
          highcharts={Highcharts}
          options={config}
        />
      ) : (
        <div className={styles.placeholderChart}>Loading</div>
      )}{' '}
    </>
  );
};
export default WeatherforcastChartHC;
