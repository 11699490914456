import { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import isMobileDevice from '../components/Components/HelperFunctions/isMobile';
const calculateExpandedBounds = (bounds, expansionFactor = 0.1) => {
  const [[swLng, swLat], [neLng, neLat]] = bounds;

  // Expand the bounds by the given factor
  const lngPadding = (neLng - swLng) * expansionFactor;
  const latPadding = (neLat - swLat) * expansionFactor;

  return [
    [swLng - lngPadding, swLat - latPadding], // Expanded Southwest corner
    [neLng + lngPadding, neLat + latPadding], // Expanded Northeast corner
  ];
};
const useMapState = (data, variation, mapRef) => {
  const [mapState, setMapState] = useState({});

  const handleResize = useCallback(
    debounce(() => {
      if (!mapRef.current) return;
      const map = mapRef.current.getMap();
      const maxBounds = [
        [8.942370142347272, 46.19860158210966],
        [18.510722110803385, 49.01986718688349],
      ];
      // map.fitBounds(maxBounds, {
      //   padding: { top: 50, bottom: 50, left: 50, right: 50 },
      //   linear: true,
      //   duration: 0,
      // });
      map.fitBounds(maxBounds, {
        // padding: { top: 50, bottom: 50, left: 50, right: 50 },
        linear: true,
        duration: 0,
      });

      const currentBounds = map.getBounds();
      const expansionFactor = 0.5; // Adjust this as needed

      const expandedBounds = calculateExpandedBounds(
        [
          [currentBounds.getWest(), currentBounds.getSouth()], // Southwest corner
          [currentBounds.getEast(), currentBounds.getNorth()], // Northeast corner
        ],
        expansionFactor,
      );
      map.setMaxBounds(expandedBounds);

      // calculateMinZoom(map, maxBounds)
    }, 1000),
    [mapRef],
  );

  const handleOrientationChange = useCallback(() => {
    setTimeout(() => {
      handleResize();
    }, 500); // Adjust the delay time as needed
  }, [handleResize]);

  useEffect(() => {
    setMapState({
      latitude: data?.latitude,
      longitude: data?.longitude,
      zoom: parseInt(data?.zoom),
      maxZoom: parseInt(data?.maxzoom),
      minZoom: parseInt(data?.minzoom),
      cooperativeGestures: true,
      pitchWithRotate: false,
      dragRotate: false,
      bearingSnap: 0,
    });

    if (variation === 'weatherforecast' || variation === 'tawes') {
      window.addEventListener('resize', () => {
        if (!isMobileDevice()) {
          handleResize();
        }
      });

      window.addEventListener('orientationchange', handleOrientationChange);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, [data, variation, handleResize, handleOrientationChange]);

  return { mapState, handleResize };
};

export default useMapState;
