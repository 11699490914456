import { BlockDataForm } from '@plone/volto/components';
import { Schema } from './schema';

const Data = (props) => {
  const { data, block, onChangeBlock, blocksConfig } = props;

  // Pass the schema, which is dynamically created
  const schema = Schema({ ...props });

  return (
    <BlockDataForm
      schema={schema} // Pass the schema
      title={schema.title || 'Card Section Block'} // Use schema title or fallback
      onChangeField={(id, value) => {
        onChangeBlock(block, {
          ...data,
          [id]: value, // Update specific field value
        });
      }}
      onChangeBlock={onChangeBlock} // Pass change handler
      formData={data} // Data for the form
      block={block} // Block ID
      blocksConfig={blocksConfig} // Block configuration
    />
  );
};

export default Data;
