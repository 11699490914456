/*
Icon Listing Content Variation View for CardSection
*/

import View from '../../Link/View.jsx';
import config from '@plone/volto/registry';

const IconListingContent = (props) => {
  const Image = config.getComponent('Image').component;

  const columns = props.props.data.columns;

  return (
    <div className="link-listing">
      <div className="listings">
        {columns && columns.length && (columns.map((column, index) => (
            <div className="listing-block" key={column['@id'] + index}>
              <div className="image-icon">
                {(column.logo && column.logo[0] && column.logo[0]['@id']) && (
                  <Image
                    item={column.logo[0]}
                    imageField='image'
                    alt=""
                  />
                )}
              
              </div>
              <h5 className="title">{column.title ?? ''}</h5>
              <span className="description">{column.description}</span>
              {(column.link && column.link_title) && (
                <div className="links-listing">
                  <View data={{
                      href: column.link,
                      title: column.link_title
                    }} isEditMode={false} />
                </div>
              )}
            </div>
        )))}
        </div> 
    </div>
  );
};

export default IconListingContent;