import React, { useState, useEffect, useCallback, useMemo } from 'react';

import cx from 'classnames';
import moment from 'moment';
import 'moment/locale/de'; // Import the German locale
import WeatherForecastDropdown from '../Components/Forecast/WeatherForecastDropdown';
import VariationNavButtons from '../Components/Shared/VariationNavButtons';
import ControlPanels from '../Components/ControlPanels';
import WeatherForecastChartBlock from '../Components/WeatherForecastChartBlock';
import Skeleton from '../Components/Shared/Skeleton';
import TableForecast from '../Components/Forecast/TableForecast';
import SimpleMap from '../Components/Shared/SimpleMap';
import styles from '../Components/Forecast/WeatherforecastTable.module.css';
import WeatherTable from '../Components/Forecast/WeatherforecastTable';
import WeatherForecastTextBlock from '../Components/WeatherForecastTextBlock';
import ExternalLinkButtons from '../Components/Shared/ExternalLinkButtons';

moment.locale('de');

const ForecastPage = ({
  data,
  markerdata,
  chartModeNot,
  chartmode,
  mapClick,
  FsetTableMode,
  language,
  stylesHashInfo,
  memoizedData,
  combinedData,
  handleClick,
  forecastTime,
  dayClick,
  selectedDropdownElement,
  styleHash,
  handleResetFromChart,
  autIcon,
}) => {
  return (
    <div className="block weatherforecastMain g" id="geosphereMaps">
      <div
        className="block geosphere_interactive_map"
        id="geosphereMapsSub"
        style={{
          display: styleHash,
        }}
      >
        {markerdata && markerdata.data && chartModeNot && (
          <WeatherForecastDropdown
            data={memoizedData}
            chartmode={chartModeNot}
            language={language}
          />
        )}
      </div>

      <div
        className={cx('block', 'geosphere_interactive_map mt-bottom')}
        id="geosphereMapsMap"
        style={{
          display: stylesHashInfo !== 'geosphereTexts' ? 'grid' : 'none',
        }}
      >
        {!(chartmode && chartmode.active) &&
          memoizedData &&
          memoizedData.variation == 'weatherforecast' &&
          combinedData?.data && (
            <>
              {/* vorhersageButton karte / tabelle */}
              {/* Navbuttons */}
              <VariationNavButtons
                data={memoizedData}
                language={language}
                markerdata={markerdata}
                mapClick={mapClick}
                FsetTableMode={FsetTableMode}
              />
              {/* navbar vorhersage */}

              <SimpleMap
                data={memoizedData}
                day={dayClick}
                stateAut={selectedDropdownElement}
                markerdata={combinedData} //added combinedData here
                // tawesSubmenu={tawesSubmenuClick}
              ></SimpleMap>
              <ControlPanels
                markerdata={markerdata}
                data={memoizedData}
                onButtonClick={handleClick}
                isToday={forecastTime}
                numberOfDays={memoizedData.forcastdays}
                parameter={dayClick}
                tableActive={mapClick}
                language={language}
              />
            </>
          )}
        {chartmode?.active === memoizedData.variation + 'CHART' &&
          markerdata &&
          markerdata?.data && (
            <>
              <WeatherForecastChartBlock data={data} chartmode={chartmode} />
              {/* external link information */}
            </>
          )}
        {markerdata == null ? <Skeleton /> : null}
        {chartmode?.active === memoizedData.variation + 'TABLE' &&
          combinedData?.data?.features && (
            <TableForecast
              chartmode={chartmode}
              data={memoizedData}
              combinedData={combinedData}
              selectedDropdownElement={selectedDropdownElement}
              language={language}
            >
              <div className={styles.table_modeParent}>
                <VariationNavButtons
                  data={memoizedData}
                  language={language}
                  markerdata={markerdata}
                  mapClick="table"
                  FsetTableMode={FsetTableMode}
                />
              </div>
              {/* WeatherTable component */}

              <WeatherTable
                markerdata={combinedData}
                isToday={
                  combinedData.data.features[0].properties.forecast[0].time
                }
                data={data}
                selectedDropdownElement={selectedDropdownElement}
                language={language}
              />
            </TableForecast>
          )}
      </div>
      {chartmode?.active === memoizedData.variation + 'CHART' && language && (
        <ExternalLinkButtons
          // chartmode={chartModeNot ? true : false}
          variation={memoizedData.variation}
          language={language}
          handleResetFromChart={handleResetFromChart}
          autIcon={autIcon}
        />
      )}
      {memoizedData.textsforecast &&
        chartmode?.active !== memoizedData.variation + 'CHART' && (
          <WeatherForecastTextBlock
            forecasttext={memoizedData.textsforecast}
            serviceaddress={memoizedData.serviceaddress}
            className="block"
            selectedDropdownElement={selectedDropdownElement}
          />
        )}
    </div>
  );
};
export default ForecastPage;
