import Accordion_open from '../../../icons/austria/Accordion_open_16px.svg';
import Accordion_closed from '../../../icons/austria/Icon_Close_Midnight_16px.svg';
import React, { FC } from 'react';
import { Icon } from '@plone/volto/components';

interface AccordionArrowProps {
  isOpen: () => boolean;
  index: string;
}

const AccordionArrow = ({ isOpen, index }: FC<AccordionArrowProps>) => {
  return (
    <div className="accordion-weatherforecast-arrow" key={index}>
      {isOpen ? (
        <Icon name={Accordion_closed} size="16px" title="Text ausblenden" />
      ) : (
        <Icon name={Accordion_open} size="16px" title="Text einblenden" />
      )}
    </div>
  );
};

export default AccordionArrow;
