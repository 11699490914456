import React, { FC, useState } from 'react';
import styles from './InfoComponent.module.css';
import infobutton from '../../../icons/austria/Information_Midnight_32px.svg';
// import dropdown from '../../../icons/austria/Arrow_Down_Midnight_16px.svg';
import { Icon } from '@plone/volto/components';
// /home/dweisgram/Downloads/
interface InfoComponentProps {
  informationLink: string;
  language: string;
}

const InfoComponent: FC<InfoComponentProps> = React.memo(
  ({ informationLink, informationText, language }) => {
    const [isButtonVisible, setIsButtonVisible] = useState(false);

    const handleButtonClick = () => {
      setIsButtonVisible(!isButtonVisible);
      //   onInfoClick(); // could be added later
    };
    const createLinkText = (href: string, language: string): JSX.Element => {
      if (language === 'de') {
        return (
          <>
            Mehr Information finden Sie{' '}
            <a
              tabIndex={0}
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={'Link zu Informationsseite:' + href}
            >
              hier
            </a>
          </>
        );
      } else if (language != 'de') {
        return (
          <>
            More information can be found{' '}
            <a
              tabIndex={0}
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={'Link to more information:' + href}
            >
              here
            </a>
          </>
        );
      }
    };
    const last_char = informationText?.slice(-1) == '.' ? ' ' : '. ';
    return (
      <div className={styles.infoContainer}>
        <button
          onClick={handleButtonClick}
          aria-label="Öffnet eine kurze Beschreibung und zeigt dann den passenden Link zu mehr Informationen an"
        >
          {' '}
          <Icon
            className={styles.infoButton}
            // onClick={handleButtonClick}
            name={infobutton}
            size="32px"
            title={informationText}
          />{' '}
        </button>

        {isButtonVisible && (
          <span className={styles.infoText}>
            {informationText + last_char}
            {createLinkText(informationLink, language)}
          </span>
        )}
      </div>
    );
  },
);

export default InfoComponent;
