import React, { useState, memo, useEffect } from 'react';

import ButtonNoHtmlatt from '../../Components/ui/ButtonNoHtmlatt';
import { getHash, setHash } from '../../utils/hashingHelpers';

const MENU = [
  { name: 'Österreich', nameEn: 'Austria' },
  { name: 'Europa', nameEn: 'Europe' },
  { name: 'Welt', nameEn: 'World' },
];

const ControlPanelEarthquake = memo(({ language }) => {
  // console.log('nav erdbeben');
  const [selectedDay, setSelectedDay] = useState(0);

  // const changeSelectedDay = (e, s) => {
  //   e.stopPropagation();
  //   e.preventDefault();

  //   setSelectedDay(Number(s));
  // };
  // useEffect(() => {
  //   const cut = getHash('cut');
  //   if (cut) {
  //     const numbCut = parseInt(cut);
  //     // setSelectedDay(numbCut);
  //     onButtonClick(numbCut);
  //   }
  // }, []);

  useEffect(() => {
    const cut = getHash('cut') ? getHash('cut') : 0;
    const newHashCut =
      parseInt(cut) >= 0 && parseInt(cut) < 3 ? parseInt(cut) : 0;
    setSelectedDay(newHashCut);
    window.addEventListener('hashchange', (e) => {
      const cut = getHash('cut');
      const newHashCut =
        parseInt(cut) >= 0 && parseInt(cut) < 3 ? parseInt(cut) : 0;

      setSelectedDay(newHashCut);
      return {
        remove: () => window.removeEventListener('hashchange', e),
      };
    });
  }, []);

  // const nextDays = getNextNDays(numberOfDays);
  return (
    <div className="control-panel weatherforecast_navi weatherforecast_naviGrid4">
      <div>
        {MENU.map((selectableDay, index) => {
          return (
            <ButtonNoHtmlatt
              variant="primary"
              onClick={(e) => {
                // changeSelectedDay(e, index);
                // onButtonClick(index);
                setHash('cut', index);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  // changeSelectedDay(e, index);
                  // onButtonClick(index);
                  setHash('cut', index);
                }
              }}
              aria-label={
                language == 'de' ? selectableDay.name : selectableDay.nameEn
              }
              tabIndex={0}
              key={index}
              active={index === selectedDay}
              label={
                language == 'de' ? selectableDay.name : selectableDay.nameEn
              }
            ></ButtonNoHtmlatt>
          );
        })}
      </div>
    </div>
  );
});

export default ControlPanelEarthquake;
