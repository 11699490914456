import { GET_GEOSPHEREDATAPLATFORM_DATA } from '../constants/ActionTypes';

export function getGeospheredataplatformdata(
  baseUrl,
  serviceName,
  params = {},
  pathParam = [],
  serviceNameCustum,
) {
  let path = `${baseUrl}/${serviceName}`;

  var searchParams = new URLSearchParams();

  if (pathParam.length > 0) {
    pathParam.forEach((param) => {
      if (params[param] !== undefined) {
        path += `/${params[param]}`;
        delete params[param]; // Remove from params to avoid duplication in query
      }
    });
  } else if (params) {
    Object.keys(params).forEach((el) => {
      searchParams.append(el, params[el]);
    });
  }
  const searchParamsToString = searchParams.toString();
  if (searchParamsToString) {
    path += `?${searchParamsToString}`;
  }

  // console.log({
  //   type: GET_GEOSPHEREDATAPLATFORM_DATA,
  //   serviceName: serviceName,
  //   request: {
  //     op: 'get',
  //     path: path,
  //   },
  // });

  return {
    type: GET_GEOSPHEREDATAPLATFORM_DATA,
    serviceName: serviceNameCustum ? serviceNameCustum : serviceName,
    request: {
      op: 'get',
      path: path,
    },
  };
}
