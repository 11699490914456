import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import svgRight from '@plone/volto/icons/right-key.svg';
import { Icon } from '@plone/volto/components';
import { FormattedDate } from '@plone/volto/components';
import cx from 'classnames';
const shave = require('shave').default;
import { useIntl } from 'react-intl';
import { newsItemViewMessages } from '../../../i18nl10n';

import { isInternalURL } from '@plone/volto/helpers/Url/Url';

const ProjectItem = ({ item, isEditMode }) => {
  const intl = useIntl();
  const dateOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  const descriptionRef = useRef(null);

  const truncateContent = useCallback(() => {
    if (descriptionRef.current && !isEditMode) {
      requestAnimationFrame(() => {
        // avoid error due to content while switching to and from edit mode
        if (descriptionRef && descriptionRef.current) {
          shave(descriptionRef.current, 80, { character: " (...)" });
        }
      });
    }
  }, []);

  const translated_topics = ![null, undefined].includes(item.project_topics) ? item.project_topics.map(cat => {
            cat = cat.split(' ').join('');
            return intl.formatMessage(newsItemViewMessages[cat])
          }).join(', ') : '';

  useEffect(() => {
    truncateContent();
    window.addEventListener('resize', truncateContent);

    return () => {
      window.removeEventListener('resize', truncateContent);
    };
  }, [truncateContent]);

  return (
    <div className="projects-listing">
      <ConditionalLink item={item} condition={!isEditMode}>
        <div className={cx('project-item', 'default')}>
          <div className="content">
            <h2>{item.title ? item.title : item.id}</h2>
            <p className="truncate" ref={descriptionRef}>{item.description}</p>
            <div className="project-listing-info">
              <span className="date">
                {
                  <>
                    <FormattedDate date={item.start} format={dateOptions} />
                    <span> - </span>
                    <FormattedDate date={item.end} format={dateOptions} />
                  </>
                }
              </span>
	      {translated_topics && (
                 <span className="project-type">
                   {translated_topics}
                 </span>
              )}
            </div>
          </div>
          <div className="arrow-link">
            <Icon name={svgRight} size="16px" />
          </div>
        </div>
      </ConditionalLink>
    </div>
  );
};

ProjectItem.propTypes = {
  item: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
};

const ProjectListingTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }

  return (
    <>
      <div className="items">
        {items.map((item) => (
          <ProjectItem
            key={item['@id']}
            item={item}
            isEditMode={isEditMode}
          />
        ))}
      </div>

      {link && <div className="footer">{link}</div>}
    </>
  );
};

ProjectListingTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkTitle: PropTypes.string,
  linkHref: PropTypes.arrayOf(PropTypes.object),
  isEditMode: PropTypes.bool,
};

export default ProjectListingTemplate;
