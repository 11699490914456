import React from 'react';
import './BebenLegenzSize.css';

const BebenLegenzSize = ({ language }) => {
  const items = [
    { label: 'M 1,5', size: 16 },
    { label: 'M 2,0', size: 18 },
    { label: 'M 3,0', size: 22 },
    { label: 'M 4,0', size: 26 },
    { label: 'M 5,0', size: 32 },
    { label: 'M 6,0', size: 38 },
  ];

  return (
    <div className="legend">
      <div className="legendChild magnitude">
        <span style={{ fontWeight: 'bold', fontSize: '20px' }}>Magnitude:</span>
        <div style={{ display: 'flex', gap: '5px' }}>
          {items.map((item, index) => (
            <div key={index} className="legend-item">
              <div
                className="circle"
                style={{
                  width: item.size,
                  height: item.size,
                }}
              ></div>
              <span>
                {language == 'de' ? item.label : item.label.replace(',', '.')}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="legendChild">
        <span className="textLegendLabel">
          {language == 'de' ? 'Verspürt: ' : 'Felt: '}
        </span>
        <div
          className="circle"
          style={{
            width: '35px',
            height: '35px',
            border: '3px solid #bfce40',
          }}
        ></div>
      </div>
    </div>
  );
};

export default BebenLegenzSize;
