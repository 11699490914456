import { de } from '@plone/volto/constants/Languages.cjs';
import { defineMessages } from 'react-intl';

export const footerMessages = defineMessages({
  reportWeather: {
    id: 'report weather',
    defaultMessage: 'report weather',
  },
  reportWeatherLink: {
    id: 'report weather link',
    defaultMessage: '/en/report-weather',
  },
  reportEarthquake: {
    id: 'report earthquake',
    defaultMessage: 'report earthquake',
  },
  reportEarthquakeLink: {
    id: 'report earthquake link',
    defaultMessage: '/en/report-earthquake',
  },
  impress: {
    id: 'impress',
    defaultMessage: 'impress',
  },
  impressLink: {
    id: 'impresslink',
    defaultMessage: '/en/impress',
  },
  dataProtection: {
    id: 'data protection',
    defaultMessage: 'data protection',
  },
  dataProtectionLink: {
    id: 'data protection link',
    defaultMessage: '/en/data-protection',
  },
  legal: {
    id: 'legal',
    defaultMessage: 'legal',
  },
  legalLink: {
    id: 'legal link',
    defaultMessage: '/en/legal',
  },
  accessibility: {
    id: 'accessibility',
    defaultMessage: 'accessibility',
  },
  accessibilityLink: {
    id: 'accessibility link',
    defaultMessage: '/en/accessibility',
  },
  detailledContactPage: {
    id: 'detailled contact page',
    defaultMessage: 'detailled contact page',
  },
  detailledContactPageLink: {
    id: 'detailled contact page link',
    defaultMessage: '/en/detailled-contact-page',
  },
  contactPageLink: {
    id: 'detailled contact page link',
    defaultMessage: '/en/detailled-contact-page',
  },
  pressContacts: {
    id: 'press contacts',
    defaultMessage: 'press contacts',
  },
  pressContactsLink: {
    id: 'press contacts link',
    defaultMessage: '/en/press-contacts',
  },
  participate: {
    id: 'participate',
    defaultMessage: 'participate',
  },
  contact: {
    id: 'contact',
    defaultMessage: 'contact',
  },
  socialmedia: {
    id: 'social media',
    defaultMessage: 'social media',
  },
});

export const eventViewMessages = defineMessages({
  eventOverview: {
    id: 'event overview',
    defaultMessage: 'Event Overview',
  },
});

export const newsItemViewMessages = defineMessages({
  newsOverview: {
    id: 'news overview',
    defaultMessage: 'News Overview',
  },
  climate: {
    id: 'climate',
    defaultMessage: 'climate',
  },
  geophysics: {
    id: 'geophysics',
    defaultMessage: 'geophysics',
  },
  geology: {
    id: 'geology',
    defaultMessage: 'geology',
  },
  meteorology: {
    id: 'meteorology',
    defaultMessage: 'meteorology',
  },
  disastermanagement: {
    id: 'disaster management',
    defaultMessage: 'disaster management',
  },
  climatechange: {
    id: 'climate change',
    defaultMessage: 'climate change',
  },
  naturalresources: {
    id: 'natural resources',
    defaultMessage: 'natural resources',
  },
  data: {
    id: 'data',
    defaultMessage: 'data',
  },
  citizenscience: {
    id: 'citizen science',
    defaultMessage: 'citizen science',
  },
  environment: {
    id: 'environment',
    defaultMessage: 'environment',
  },
  models: {
    id: 'models',
    defaultMessage: 'models',
  },
  services: {
    id: 'services',
    defaultMessage: 'services',
  },
  other: {
    id: 'other',
    defaultMessage: 'other',
  }
});

export const projectViewMessages = defineMessages({
  projectOverview: {
    id: 'project overview',
    defaultMessage: 'Project Overview',
  },
  projectTableAcronym: {
    id: 'project table Acronym',
    defaultMessage: 'Acronym',
  },
  projectTableTitle: {
    id: 'project table Title',
    defaultMessage: 'Title',
  },
  projectTableDescription: {
    id: 'project table Description',
    defaultMessage: 'Description',
  },
  projectTableLead: {
    id: 'project table Lead',
    defaultMessage: 'Lead',
  },
  projectTableStart: {
    id: 'project table Start',
    defaultMessage: 'Start',
  },
  projectTableEnd: {
    id: 'project table End',
    defaultMessage: 'End',
  },
  projectTableTags: {
    id: 'project table Tags',
    defaultMessage: 'Tags',
  },
  projectTableFundingAgency: {
    id: 'project table FundingAgency',
    defaultMessage: 'Funding Agency',
  },
  projectTableFundingProgramme: {
    id: 'project table FundingProgramme',
    defaultMessage: 'Funding Programme',
  },
});
