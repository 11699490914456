import { useCallback, useEffect } from 'react';
const shave = require('shave').default;

export const useShaveEffect = (contentRef, maxHeight) => {
  const updateShave = useCallback(() => {
    if (contentRef.current) {
      const descriptionElement = contentRef.current.querySelector('.truncate');
      if (descriptionElement) {
        // use requestAnimationFrame to ensure all styles are applied
        requestAnimationFrame(() => {
          shave(descriptionElement, maxHeight, { character: ' (...)' });
        });
      }
    }
  }, [maxHeight, contentRef]);

  useEffect(() => {
    const updateShaveWithDelay = () => {
      // ensure shave is called in the next frame
      requestAnimationFrame(updateShave);
    };

    updateShaveWithDelay(); // initial shave
    window.addEventListener('resize', updateShave);

    return () => {
      window.removeEventListener('resize', updateShave);
    };
  }, [updateShave]);

  return updateShave;
};

export const doesTextWrap = (element) => {
  if (!element) return false;
  const style = window.getComputedStyle(element);
  return element.scrollHeight > parseFloat(style.lineHeight);
};
