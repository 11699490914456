import React, { useState, useEffect,useMemo } from 'react';
import styles from './WeatherforecastTable.module.css';
import moment from 'moment';
import WeatherTableRow from './WeatherTableRow';
import { States } from '../../../constants/AutStates';
import { setHash } from '../../utils/hashingHelpers';
// moment.locale('de');

const isToday = (dateString) => {
  // const dateString = "2024-07-31";
  const inputDate = moment(dateString, 'YYYY-MM-DD');
  const todayDate = moment().startOf('day'); // Get today's date at midnight

  const isSameDate = inputDate.isSame(todayDate, 'day');
  return isSameDate;
};
// Function to get the current day and the next few days
const getDays = (today=true,language="de") => {

  if(!isToday(today)){
    const calcDate = new Date(today); // Get today's date
    const dates = [];
    for (let i = 0; i < 8; i++) {
      const date = new Date(calcDate); // Create a new date object for each day
      date.setDate(calcDate.getDate() + i); 
      dates.push(date.toLocaleDateString('de-DE'));
    }
    return dates
  }
  else{
    const days = ['Heute', 'Morgen'];
    const dayNameE = ['Today', 'Tomorrow'];
    if(language=="de"){
      for (let i = 2; i < 8; i++) {
        const nextDay = new Date();
        nextDay.setDate(nextDay.getDate() + i);
        days.push(
          nextDay.toLocaleDateString('de-DE', {
            weekday: 'short',
            locales: 'de-DE',
          }),
        ); // en-US
      }
      return days;
    }
    else if(language=="en"){
      for (let i = 2; i < 8; i++) {
        const nextDay = new Date();
        nextDay.setDate(nextDay.getDate() + i);
        dayNameE.push(
          nextDay.toLocaleDateString('en-EN', {
            weekday: 'short',
            locales: 'de-DE',
          }),
        ); // en-US
      }
      return dayNameE;
    }
  }
  
};

const WeatherTable = ({ markerdata, data, selectedDropdownElement, isToday,language }) => {
  // const dispatch = useDispatch();
  // const language = useSelector(
  //   (state) => state.geospheredataplatformdata['SET_LANGUAGE'],
  // );
  const days =   useMemo(() => getDays(isToday,language), []);

  const handleRowClick = (station) => {

    // window.location.hash = `tab=chart${station.properties.point_id}`;
    setHash('tab', 'chart');
    setHash('point_id', station.properties.point_id);
  };
  const [selNavItem, setSelNavItem] = useState([]);

  useEffect(() => {
    if(!markerdata?.data) return
    const key = States[selectedDropdownElement].key;

    if (key === '0') {
      setSelNavItem(markerdata?.data?.features.filter((i) => i.properties.is_capital === true).sort((a, b) => {
        if (a.properties.title < b.properties.title) {
          return -1;
        }
        if (a.properties.title > b.properties.title) {
          return 1;
        }
        return 0;
      }));
    }else if(Number(key)===9 && (markerdata?.data?.features.filter((i) => i.properties.name.includes("Wien ")).length > 0)){
      setSelNavItem(markerdata?.data?.features.filter((i) => i.properties.name.includes("Wien ")).sort((a, b) => {
        if (a.properties.title < b.properties.title) {
          return -1;
        }
        if (a.properties.title > b.properties.title) {
          return 1;
        }
        return 0;
      }));

    }
     else {
      setSelNavItem(markerdata?.data?.features.filter((i) => String(i.properties.state_id) == key).sort((a, b) => {
        if (a.properties.title < b.properties.title) {
          return -1;
        }
        if (a.properties.title > b.properties.title) {
          return 1;
        }
        return 0;
      }));

    }
  }, [markerdata,selectedDropdownElement]);

  const handelKeyDown = (station): void => {
    if (event.key === 'Enter' || event.key === 'Backspace') {
      event.preventDefault();
      handleRowClick(station);
    }
    
  };
  return (
    // <div className={styles.weatherforecasttableMain}>
      <div className={styles.weatherprognoseTable}>
        <table className={styles.weatherprognoseTable_table} 
          aria-label="Tabellarische Beschreibung der Wetterprognose der nächsten 8Tage "
        
        >
          {/* <caption>
               Darstellung meteorologischer Vorhersagedaten{' '}
          </caption> */}
          <thead>
            <tr>
              <th></th>
              {/* Header row with days */}
              {days.map((day, index) => (
                <th
                  scope="col"
                  key={index}
                  className={styles.vorhersageCenter}
                  aria-label={`Vorhersagetag:${day}`}
                >
                  {day}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* Rows for each station */}
            {selNavItem.length > 0 &&
              selNavItem.map((station, stationIndex) => (
                <WeatherTableRow
                  key={stationIndex}
                  station={station}
                  handleRowClick={handleRowClick}
                  handelKeyDown={handelKeyDown}
                  language={language}
                  // days={days}
              />
            ))}
          </tbody>
        </table>
      </div>
    // </div>
  );
};

export default WeatherTable;
