// Button.tsx
import React from 'react';
import styles from './ButtonWithSVG.module.scss';

interface ButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  label: string;
  width?: string;
  height?: string;
  backgroundColor?: string;
  color?: string;
}

const ButtonWithSVG: React.FC<ButtonProps> = ({
  onClick,
  children,
  label,
  width,
  height,
  backgroundColor,
  color,
}) => {
  return (
    <button
      className={styles.customButton}
      onClick={onClick}
      style={{ width, height, backgroundColor, color }}
    >
      {children}
      {label ?? <span className={styles.label}>{label}</span>}
    </button>
  );
};

export default ButtonWithSVG;
