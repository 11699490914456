import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FormattedDate, Icon } from '@plone/volto/components';
import { expandToBackendURL } from '@plone/volto/helpers';
import { Container } from '@plone/components';
import downloadLink from '../../../icons/Link_Download_White_16px.svg';
import externalLink from '../../../icons/Link_Extern_White_16px.svg';

const EventMetadataView = (props) => {
  const content = props.properties;
  const dateOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  };

  return (
    <div className="block eventMetadata ">
      <Container className="details-container">
        <div className="content-container">
          <div className="event-details">
            <div className="event-title">
              <span className="event-heading">
                <FormattedMessage id="Event Start" defaultMessage="Event Start" />
              </span>
              <div className="event-detail">
                {' '}
                {content?.start && (
                  <FormattedDate date={content?.start} format={dateOptions} />
                )}{' '}
                {props.intl.locale === 'de' ? ' Uhr' : ''}
              </div>
            </div>
            <div className="event-title">
              <span className="event-heading">
                {' '}
                <FormattedMessage id="Event End" defaultMessage="Event End" />
              </span>
              <div className="event-detail">
                {' '}
                {content?.end && (
                  <FormattedDate date={content?.end} format={dateOptions} />
                )}{' '}
                {props.intl.locale === 'de' ? ' Uhr' : ''}
              </div>
            </div>
            <div className="event-title">
              <span className="event-heading">
                {' '}
                <FormattedMessage id="Event Type" defaultMessage="Event Type" />
              </span>
              <div className="event-detail">
                {' '}
                {content?.event_type && content?.event_type?.length && (
                  <span>{content?.event_type.map(t => t.title).join(', ')}</span>
                )}
              </div>
            </div>
          </div>

          <div className="event-details">
            {content?.location && (
              <div className="event-title">
                <span className="event-heading">
                  <FormattedMessage id="Location" defaultMessage="Event Location" />
                </span>
                <div className="event-detail">{content?.location}</div>
              </div>
            )}
          </div>
          <div className="event-details">
            {(content?.event_organizers && content?.event_organizers.length) && (
              <div className="event-title">
                <span className="event-heading">
                  <FormattedMessage id="Event Organizer" defaultMessage="Event Organizer" />
                </span>
                <div className="event-detail">
                    <span>{content.event_organizers.join(', ')}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="event-button">
          <div className="link container download">
            <a
              className="event-link ics-download"
              target="_blank"
              rel="noreferrer"
              href={
                content && content['@id']
                  ? `${expandToBackendURL(content['@id'])}/ics_view `
                  : ''
              }
            >
              <button className="ui button link event-btn">
                <span>
                  <FormattedMessage
                    id="ICS-Download"
                    defaultMessage="ICS Download"
                  />
                </span>
                <div>
                    <Icon name={downloadLink} size="16px" />
                </div>
              </button>
            </a>
          </div>
          {content.event_url && (
            <div className="link container external">
              <a
                className="event-link eventWebsite"
                target="_blank"
                rel="noreferrer"
                href={
                  content && content.event_url
                    ? content.event_url
                    : ''
                }
              >
                <button className="ui button link event-btn">
                  <span>
                    <FormattedMessage
                      id="eventUrl"
                      defaultMessage="Link to the Event Website"
                    />
                  </span>
                  <div>
                    <Icon name={externalLink} size="16px" />
                  </div>
                </button>
              </a>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default injectIntl(EventMetadataView);
