import { useIntl } from "react-intl";
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  Theme: {
    id: 'Theme',
    defaultMessage: 'Theme',
  },
  Description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  Image: {
    id: 'Image',
    defaultMessage: 'Image',
  },
  AltText: {
    id: 'Alt text',
    defaultMessage: 'Alt text',
  },
  Subtitle: {
    id: 'Subtitle',
    defaultMessage: 'Subtitle',
  },
  LinkTitle: {
    id: 'LinkTitle',
    defaultMessage: 'Link Title',
  },
  LinkTo: {
    id: 'Link to',
    defaultMessage: 'Link to',
  },
  ShowLineOnMobile: {
    id: 'ShowLineOnMobile',
    defaultMessage: 'Show Line on Mobile devices',
  },
  MarginOnMobile: {
    id: 'MarginOnMobile',
    defaultMessage: 'Show Margin on Mobile devices',
  },
  ObjectFit: {
    id: 'ObjectFit',
    defaultMessage: 'css object-fit property for image',
  },
  Columns: {
    id: 'Columns',
    defaultMessage: 'Columns',
  },
});

export const Schema = (props) => {
    const ColumnSchema = props.variation.columnSchema;
    const intl = useIntl();
    return {
      required: ['theme'],
      fieldsets: [
        {
          id: 'default',
          title: 'Default',
          fields: ['title', 'theme', 'description', 'subtitle', 'link',
            'link_title', 'image', 'imageAlt', 'showLineOnMobile',
            'marginOnMobile', 'objectFit', 'columns'],
        },
      ],
      properties: {
        // Main block fields
        title: {
            title: intl.formatMessage(messages.Title),
            widget: 'text'
        },
        theme: {
            title: intl.formatMessage(messages.Theme),
            type: 'string',
            widget: 'select',
            default: 'lime',
            choices: [
                ['lime', 'Lime'],
                ['gray', 'Gray'],
                ['midnightGreen', 'Midnight Green'],
            ],
        },
        description: {
          title: intl.formatMessage(messages.Description),
          widget: 'textarea',
        },
        image: {
          title: intl.formatMessage(messages.Image),
          widget: 'object_browser',
          mode: 'image',
        },
        imageAlt: {
          title: intl.formatMessage(messages.AltText),
          widget: 'text',
        },
        subtitle: {
            title: intl.formatMessage(messages.Subtitle),
            widget: 'text',
          },
        link_title: {
            title: intl.formatMessage(messages.LinkTitle),
            widget: 'text'
        },
        link: {
            title: intl.formatMessage(messages.LinkTo),
            widget: 'object_browser',
            mode: 'link',
            selectedItemAttrs: ['Title', 'Description', 'path', 'portal_type'],
            allowExternals: true,
        },
        showLineOnMobile: {
            title: intl.formatMessage(messages.ShowLineOnMobile),
            type: 'boolean',
            default: true
        },
        marginOnMobile: {
            title: intl.formatMessage(messages.MarginOnMobile),
            type: 'boolean',
            default: true
        },
        objectFit: {
            title: intl.formatMessage(messages.ObjectFit),
            choices: [
                ['cover', 'Cover'],
                ['contain', 'Contain'],
            ],
          defaultValue: 'cover',
        },
        // Sub-blocks (array of blocks)
        columns: {
            title: intl.formatMessage(messages.Columns),
            widget: 'object_list',
            schema: ColumnSchema({ intl }),
        },
    }
  };
};
