import { useState, useEffect, useRef } from 'react';
import { Icon } from '@plone/volto/components';
import { Marker, Popup } from 'react-map-gl/maplibre';
import CircleMarker from '../Earthquake/CircleMarker';
import { useDispatch, useSelector } from 'react-redux';

import Icon_Windrichtung from '../../../icons/austria/Icon_Windrichtung_0_rotate.svg';

import styles from './TawesMarker.module.css';
import './TawesMarkerMaplibre.css';
import NODATA_SVG from '../../../icons/austria/Weather_NoData_Midnight_16px.svg';

import { navItemTawes, mapping } from '../../../constants/TawesHelper';
import moment from 'moment';
import TawesMarkerTooltipDiv from './TawesMarkerTooltipDiv';
import { pointOrComma } from '../../utils/pointOrComma';

moment.locale('de');

const colorTemp = (temp) => {
  let color = '#ffffff';
  switch (true) {
    case temp < -20:
      color = '#2E009F'; // Slightly lighter blue
      break;
    case temp >= -20 && temp < -20:
      color = '#5833FE'; // Even lighter blue
      break;
    case temp >= -20 && temp < -15:
      color = '5833FE'; // Light blue
      break;
    case temp >= -15 && temp < -10:
      color = '#7366FE'; // Very light blue
      break;
    case temp >= -10 && temp < -5:
      color = '#8C99FD'; // Pale blue
      break;
    case temp >= -5 && temp < 0:
      color = '#A6CCFD'; // Light pale blue
      break;
    case temp >= 0 && temp < 5:
      color = '#C0FFFC'; // Lighter pale blue
      break;
    case temp >= -5 && temp < 10:
      color = '#8DF3D8'; // Very light pale blue
      break;
    case temp >= 10 && temp < 15:
      color = '#5AE291'; // Very light pale red
      break;
    case temp >= 15 && temp < 20:
      color = '#75CC41'; // Light pale red
      break;
    case temp >= 20 && temp < 25:
      color = '#BFCE40'; // Pale red
      break;
    case temp >= 25 && temp < 30:
      color = '#F9F200'; // Light red
      break;
    case temp >= 30 && temp < 35:
      color = '#F29707'; // Red
      break;
    case temp >= 35 && temp < 40:
      color = '#E75C13'; // Light red
      break;
    case temp >= 40:
      color = '#CC2C01'; // Orange
      break;
    default:
      color = '#000000'; // Default color
      break;
  }
  return color;
};

function getColorForRain(rain) {
  // Define an array of hex colors
  const colors = [
    '#F3F9FF', // light green
    '#D2E9FF', // light green
    '#A6CCFD', // light green
    '#8C99FD', // light green
    '#7366FE', // light green
    '#5833FE', // green
    '#3500B7', // blue-green
    '#701F80', // teal
    '#8C11AA', // dark teal
    // '#AC3E4A', // dark cyan
    // '#000000', // black
  ];

  // Map rain values to colors
  if (rain < 0.1) {
    return colors[0];
  } else if (rain < 1) {
    return colors[1];
  } else if (rain < 3) {
    return colors[2];
  } else if (rain < 5) {
    return colors[3];
  } else if (rain < 10) {
    return colors[4];
  } else if (rain < 15) {
    return colors[5];
  } else if (rain < 30) {
    return colors[6];
  } else if (rain < 60) {
    return colors[7];
  } else if (rain >= 60) {
    return colors[8];
  } else {
    return '#000000'; // dark blue for rain >= 30mm
  }
}

function windSpeedToColor(speed) {
  speed = speed * 3.6; //
  if (speed < 10) {
    return '#F3F9FF'; // White for wind still
  } else if (speed >= 10 && speed < 20) {
    return '#C0FFFC'; // Light red for 5 bis 11 km/h
  } else if (speed >= 20 && speed < 30) {
    return '#8DF3D8'; // Red for 11 bis 19 km/h
  } else if (speed >= 30 && speed < 40) {
    return '#75CC41'; // Dark red for 19 bis 28 km/h
  } else if (speed >= 40 && speed < 50) {
    return '#BFCE40'; // Violet for 28 bis 38 km/h
  } else if (speed >= 50 && speed < 60) {
    return '#F9F200'; // Darker violet for 38 bis 49 km/h
  } else if (speed >= 60 && speed < 70) {
    return '#F29707'; // Even darker violet for 49 bis 61 km/h
  } else if (speed >= 70 && speed < 80) {
    return '#E75C13'; // Dark blue for 61 bis 74 km/h
  } else if (speed >= 80 && speed < 90) {
    return '#CC2C01'; // Blue for 74 bis 88 km/h
  } else if (speed >= 90 && speed < 100) {
    return '#B23C13'; // Light blue for 88 bis 102 km/h
  } else if (speed >= 100 && speed < 110) {
    return '#912E48'; // Lighter blue for 102 bis 117 km/h
  } else if (speed >= 110 && speed < 120) {
    return '#801F66'; // Lighter blue for 102 bis 117 km
  } else if (speed >= 120) {
    return '#8C11AA'; // Lighter blue for 102 bis 117 km/h
  }
}

function mapSunshine(value) {
  value = value / 60; // seconds to minutes
  // Define the color ranges based on numerical values
  // if (value === null || value === undefined) {
  //   return '#000000'; // Black for no data
  // }
  if (value >= 0 && value < 1) {
    return '#5833FE'; // White
  } else if (value >= 1 && value < 2) {
    return '#7366FE'; // Light Yellow
  } else if (value >= 2 && value < 3) {
    return '#A6CCFD'; // Yellow
  } else if (value >= 3 && value < 4) {
    return '#D2E9FF'; // Gold
  } else if (value >= 4 && value < 5) {
    return '#C0FFFC'; // Orange
  } else if (value >= 5 && value < 6) {
    return '#8CF4D8'; // Dark Orange
  } else if (value >= 6 && value < 7) {
    return '#5AE291'; // Tomato
  } else if (value >= 7 && value < 8) {
    return '#75CC41'; // Orange Red
  } else if (value >= 8 && value < 9) {
    return '#F9F200'; // Red
  } else if (value >= 9 && value <= 10) {
    return '#F29707'; // Red
  } else {
    return '#FFFFFF'; // White (for values beyond 9)
  }
}
function mapFeuchte(percentage) {
  // Define the color ranges based on percentages
  if (percentage === null || percentage === undefined) {
    return '#000000'; // Black for no data
  }
  if (percentage < 10) {
    return '#F29707'; // Dark Orange
  } else if (percentage >= 10 && percentage < 20) {
    return '#F9F200'; // Orange
  } else if (percentage >= 20 && percentage < 30) {
    return '#75CC41'; // Light Orange
  } else if (percentage >= 30 && percentage < 40) {
    return '#5AE291'; // Peach
  } else if (percentage >= 40 && percentage < 50) {
    return '#8CF4D8'; // Light Peach
  } else if (percentage >= 50 && percentage < 60) {
    return '#C0FFFC'; // Light Green
  } else if (percentage >= 60 && percentage < 70) {
    return '#D2E9FF'; // Green
  } else if (percentage >= 70 && percentage < 80) {
    return '#A6CCFD'; // Bright Green
  } else if (percentage >= 80 && percentage < 90) {
    return '#7366FE'; // Light Green
  } else if (percentage >= 90) {
    return '#5833FE'; // Dark Green
  }
}

// function mapSnowDepthToColor(depth) {
//   // Define the color ranges based on snow depth
//   if (depth === 'kein Schnee') {
//     return '#FFFFFF'; // White (no snow)
//   } else if (depth === 'keine Daten') {
//     return '#FFFFFF'; // White (no data)
//   } else {
//     depth = parseInt(depth); // Convert to integer
//     if (depth == 0) return '#FFFFFF';
//     else if (depth > 0 && depth < 20) {
//       return '#CCFFCC'; // Light Green
//     } else if (depth >= 20 && depth < 50) {
//       return '#66FF66'; // Mid Green
//     } else if (depth >= 50 && depth < 80) {
//       return '#66CCFF'; // Light Blue
//     } else if (depth >= 80 && depth < 120) {
//       return '#3366FF'; // Dark Blue
//     } else if (depth >= 120 && depth < 200) {
//       return '#FF6666'; // Dark Red
//     } else if (depth >= 200 && depth < 300) {
//       return '#CC3333'; // More Dark Red
//     } else if (depth >= 300 && depth < 400) {
//       return '#800000'; // Even Darker Red
//     } else if (depth >= 400) {
//       return '#330000'; // Almost Black
//     }
//   }
// }

function mapPresDepthToColor(qnh) {
  // Handle invalid input (optional)
  if (qnh <= 0 || qnh == null) {
    return '#000000';
  }

  // Use if statements with interpretations and colors
  if (qnh < 995) {
    return '#5833FE'; // Low pressure (mid green), caution advised
  } else if (qnh < 1000) {
    return '#7366FE'; // Low-mid pressure (light green), possible turbulence
  } else if (qnh < 1005) {
    return '#A6CCFD'; // Low-mid pressure (light green), possible turbulence
  } else if (qnh <= 1010) {
    return '#C0FFFC'; // Mid pressure (light brown), normal conditions
  } else if (qnh <= 1015) {
    return '#8CF4D8'; // Mid-high pressure (very light green), stable conditions
  } else if (qnh < 1020) {
    return '#5AE291'; // High-mid pressure (light brown), good conditions
  } else if (qnh <= 1025) {
    return '#75CC41'; // Very high pressure (mid brown), clear skies
  } else if (qnh <= 1030) {
    return '#F9F200'; // Very high pressure (mid brown), clear skies
  } else if (qnh > 1030) {
    return '#F29707'; // Very high pressure (mid brown), clear skies
  } else {
    return '#000000'; // Exceptionally high pressure (dark brown), uncommon
  }
}

const iconColor = (parameterNumber, tawesSubmenu, el, clickel) => {
  // let selPar;
  let color = '#f88e86';
  if (el.properties.parameters[tawesSubmenu].data == null) return color;
  if (
    // tawesSubmenu === 'TL' ||
    // tawesSubmenu === 'TLMAX' ||
    // tawesSubmenu === 'TLMIN'
    parameterNumber === 0
  ) {
    // selPar = 'TL';
    // const value = el.properties.forecast[0][selPar].length >0 ? Math.max(...el.properties.forecast[0][selPar]) :
    color = colorTemp(el.properties.parameters[tawesSubmenu].data); // immer den größten wert nehmen [null, 10] => nimmt 10
  } //tawesSubmenu === 'RR'
  else if (parameterNumber === 1) {
    // selPar = 'RR';
    color = getColorForRain(el.properties.parameters[tawesSubmenu].data);
  } else if (parameterNumber === 2) {
    // selPar = 'wind';
    color = windSpeedToColor(el.properties.parameters[tawesSubmenu].data);
  } else if (parameterNumber === 3) {
    // selPar = 'sunshine';
    color = mapSunshine(el.properties.parameters[tawesSubmenu].data);
  } else if (parameterNumber === 4) {
    // selPar = 'feuchte';
    color = mapFeuchte(el.properties.parameters[tawesSubmenu].data);
  } else if (parameterNumber === 5) {
    // selPar = 'RR';
    color = mapPresDepthToColor(el.properties.parameters[tawesSubmenu].data);
  }
  // if (
  //   clickel?.data &&
  //   el.properties.station == clickel.data.properties.station
  // ) {
  //   color = 'white';
  // }
  // console.log(colorIcon);
  return color;
  // el.properties.mag*20+'px'
};
function winddirection(param, forecast, language) {
  const variableMap = {
    FFAM: 'DD',
    FFX: 'DDX',
  };
  const opositeVar = variableMap[param];
  const angle = forecast[opositeVar].data;
  const correctedAngle = angle % 360;
  const directions = {
    arrowN: { nicename: 'Nord', svgname: 'arrowN', nicenameE: 'North' }, // Swap North with South
    arrowNE: {
      nicename: 'Nordost',
      svgname: 'arrowNE',
      nicenameE: 'Northeast',
    }, // Swap Northeast with Southwest
    arrowE: { nicename: 'Ost', svgname: 'arrowE', nicenameE: 'East' }, // Swap East with West
    arrowSE: {
      nicename: 'Südost',
      svgname: 'arrowSE',
      nicenameE: 'Southeast',
    }, // Swap Southeast with Northwest
    arrowS: { nicename: 'Süd', svgname: 'arrowS', nicenameE: 'South' }, // Swap South with North
    arrowSW: {
      nicename: 'Südwest',
      svgname: 'arrowSW',
      nicenameE: 'Southwest',
    }, // Swap Southwest with Northeast
    arrowW: { nicename: 'West', svgname: 'arrowW', nicenameE: 'West' }, // Swap West with East
    arrowNW: {
      nicename: 'Nordwest',
      svgname: 'arrowNW',
      nicenameE: 'Northeast',
    }, // Swap Northwest with Southeast
  };
  // const directions = ['North', 'Northeast', 'East', 'Southeast', 'South', 'Southwest', 'West', 'Northwest'];

  // Determine the wind direction category based on the angle
  const index = Math.round(correctedAngle / 45) % 8; // Divide the circle into 8 sectors (45 degrees each)
  return {
    img: directions[Object.keys(directions)[index]].svgname,
    angleIndex: index,
    niceName:
      language == 'de'
        ? directions[Object.keys(directions)[index]].nicename
        : directions[Object.keys(directions)[index]].nicenameE,
    angle: angle,
  };
}

const TawesMarker = ({ data, parameterNumber, language, pointsState }) => {
  const dispatch = useDispatch();
  const navItem = navItemTawes[Number(parameterNumber)];
  const tawesSubmenu = navItem.par[0].par;
  const unit = navItem.unitS ? navItem.unitS : navItem.unit;

  const reducerClickElement = useSelector(
    // (state) => state.geospheredataplatformdata?[data.servicename] ? state.geospheredataplatformdata?[data.servicename].data : {}
    (state) =>
      state.geospheredataplatformdata
        ? state.geospheredataplatformdata['SET_CLICK_MARKER_TABLE_INTERACTION']
        : {},
  );

  const pointInformation = useSelector(
    // (state) => state.geospheredataplatformdata?[data.servicename] ? state.geospheredataplatformdata?[data.servicename].data : {}
    (state) =>
      state.geospheredataplatformdata
        ? state.geospheredataplatformdata[pointsState]
        : {},
  );

  const [showPopup, setShowPopup] = useState(false);
  // const [filterData, setFilterData] = useState(false);

  const popupRef = useRef();

  const setTooltip = (e) => {
    // if (pointInformation.data == false) return;
    e.owner = 'tawes';
    dispatch({
      type: 'SET_CLICK_MARKER_TABLE_INTERACTION',
      payload: e,
    });
  };

  useEffect(() => {
    dispatch({
      type: 'SET_CLICK_MARKER_TABLE_INTERACTION',
      payload: null,
      owner: null,
    });
    return () => {
      tooltopReducer();
    };
  }, []);

  useEffect(() => {
    if (!reducerClickElement.data) return;

    const popstation = data.find(
      (i) =>
        i.properties.station == reducerClickElement.data.properties.station,
    );

    if (popstation) {
      popupRef.current = popstation;
      setShowPopup(popstation);
    }
  }, [reducerClickElement]);

  const tooltopReducer = () => {
    setShowPopup(false);
    dispatch({
      type: 'SET_CLICK_MARKER_TABLE_INTERACTION',
      payload: null,
    });
  };

  return (
    <>
      {data.length > 0 &&
        data[0].properties.parameters &&
        data[0].properties.parameters[tawesSubmenu] &&
        data.map((el, i) => (
          <Marker
            longitude={el.geometry.coordinates[0]}
            latitude={el.geometry.coordinates[1]}
            key={i}
            onClick={() => setTooltip(el)}
          >
            {el.properties.parameters[tawesSubmenu].data == null ? (
              <Icon
                name={NODATA_SVG}
                size="16px"
                className="svg_icon"
                title={
                  language == 'de'
                    ? 'Keine Daten vorhanden'
                    : 'No data available'
                }
              />
            ) : parameterNumber == 2 &&
              el.properties.parameters[tawesSubmenu].data > 0 ? (
              <Icon
                name={Icon_Windrichtung}
                size="18px" // el.properties.forecast
                className={
                  styles[
                    `wind-${
                      winddirection(
                        tawesSubmenu,
                        el.properties.parameters,
                        language,
                      ).angleIndex
                    }`
                  ]
                }
                title={
                  winddirection(
                    tawesSubmenu,
                    el.properties.parameters,
                    language,
                  ).niceName +
                  ' Wind ' +
                  pointOrComma(
                    Number(
                      el.properties.parameters[tawesSubmenu].data * 3.6,
                    ).toFixed(2),
                    language,
                  ) +
                  ' ' +
                  unit
                  // +
                  // winddirection(
                  //   tawesSubmenu,
                  //   el.properties.parameters,
                  //   language,
                  // ).angle +
                  // '°'
                }
                ariaHidden="winddirection"
                color={iconColor(
                  parameterNumber,
                  tawesSubmenu,
                  el,
                  reducerClickElement,
                )}
              />
            ) : (
              <CircleMarker
                size="18px"
                title={
                  (language === 'de'
                    ? mapping.find((i) => i.par === tawesSubmenu).nameDe
                    : mapping.find((i) => i.par === tawesSubmenu).nameEn) +
                  ' ' +
                  pointOrComma(
                    el.properties.parameters[tawesSubmenu].data,
                    language,
                  ) +
                  ' ' +
                  unit
                }
                color={iconColor(
                  parameterNumber,
                  tawesSubmenu,
                  el,
                  reducerClickElement,
                )}
              />
            )}
          </Marker>
        ))}
      {showPopup && (
        <Popup
          longitude={showPopup.geometry.coordinates[0]}
          latitude={showPopup.geometry.coordinates[1]}
          anchor="bottom"
          closeOnClick={false}
          onClose={() => {
            tooltopReducer();
          }}
        >
          <TawesMarkerTooltipDiv
            language={language}
            tawesSubmenu={tawesSubmenu}
            parameterNumber={parameterNumber}
            pointInformation={pointInformation}
            popupRef={popupRef}
          />
        </Popup>
      )}
    </>
  );
};

export default TawesMarker;
