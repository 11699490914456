import React from 'react';
import { SlateEditor } from '@plone/volto-slate/editor';
import { Field } from '@plone/volto/components';
import { Form, Header } from 'semantic-ui-react';
import cx from 'classnames';
import { SidebarPortal } from '@plone/volto/components';
import { withBlockExtensions } from '@plone/volto/helpers';
import Data from './Data';

const InfoBox = (props) => {
  const { data, block, onChangeBlock, selected } = props;
  const isEditMode = selected;

  return (
    <div className={cx('block infoBox', data.boxColor ?? 'transparent', { 'edit-mode': isEditMode })}>
      {isEditMode ? (
        <>
          <Form>
            <Field
              key={`field-title-${block}`}
              id={`field-title-${block}`}
              title="Title"
              type="text"
              value={data.title || ''}
              onChange={(id, value) => onChangeBlock(block, { ...data, title: value })}
            />
            <Field
              key={`field-content-${block}`}
              id={`field-content-${block}`}
              title="Content"
              widget="slate"
              value={data.content || ''}
              onChange={(id, value) => onChangeBlock(block, { ...data, content: value })}
            >
              <SlateEditor
                key={`editor-${block}`}
                value={data.content || ''}
                onChange={(id, value) => onChangeBlock(block, { ...data, content: value })}
              />
            </Field>
          </Form>
          <SidebarPortal key={`sidebar-${block}`} selected={selected}>
            <Data
              key={block}
              {...props}
              data={data}
              block={block}
              onChangeBlock={onChangeBlock}
            />
          </SidebarPortal>
        </>
      ) : (
        <div>
          <h4>{data.title}</h4>
          <div>
            <SlateEditor value={data.content || ''} readOnly />
          </div>
        </div>
      )}
    </div>
  );
};

export default withBlockExtensions(InfoBox);
